import { FC } from 'react';
import { Modal } from 'antd';
import Entity from '../Entity';
import { EntityProvider } from '../../providers/EntityProvider';

interface EntityRecordModalProps {
  entityType: string;
  recordId?: string;
  onClose: () => void;
}

export const EntityRecordModal: FC<EntityRecordModalProps> = ({
  entityType,
  recordId,
  onClose
}) => {
  return (
    <Modal
      title={`${entityType} Details`}
      open={!!recordId}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      {recordId && (
        <EntityProvider 
          initialEntityId={entityType}
          initialRecordId={recordId}
        >
          <Entity disableRecordSelect={true} />
        </EntityProvider>
      )}
    </Modal>
  );
}; 