import { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import { ValidationStatus } from '../components/rendering/FieldUI';
import { EntityRecord } from '../types/System.types';

interface EntityFieldStateOptions<T> {
  initialValue: T;
  onSave: (updates: Partial<EntityRecord>) => void;
  fieldName: keyof EntityRecord;
  disabled?: boolean;
}

export const useEntityRecordField = <T>({
  initialValue,
  onSave,
  fieldName,
  disabled
}: EntityFieldStateOptions<T>) => {
  const [value, setValue] = useState<T>(initialValue);
  const [isSaving, setIsSaving] = useState(false);
  const [validationStatus, setValidationStatus] = useState<ValidationStatus>(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((newValue: T) => {
      try {
        onSave({ [fieldName]: newValue });
        setTimeout(() => setIsSaving(false), 350);
      } catch (error) {
        console.error('Save failed:', error);
        setValidationStatus('error');
        setIsSaving(false);
      }
    }, 350),
    [onSave, fieldName]
  );

  const onChange = useCallback((newValue: T) => {
    if (disabled) return;
    setValue(newValue);
    setIsSaving(true);
    debouncedSave(newValue);
  }, [disabled, debouncedSave]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return {
    value,
    onChange,
    isSaving,
    validationStatus
  };
}; 