import { FC, CSSProperties } from 'react';
import { AutoComplete, Input, Button } from 'antd';
import { SearchOutlined, CloseCircleOutlined, EnterOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useSearch } from '../../use/useSearch';
import { normalizeSearchResult } from '../../services/searchConfig';
import { SearchResultCard } from './SearchResultCard';

/**
 * Props for search components
 */
interface SearchProps {
  placeholder?: string;
  autoCompleteStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  onSelect?: (value: string) => void;
  value?: string;
  onChange?: (value: string) => void;
  autoFocus?: boolean;
}

/**
 * Renders a search component with autocomplete functionality.
 * @param {SearchProps} props - The props for the Search component.
 * @returns {JSX.Element} An AutoComplete component with search functionality.
 */
const Search: FC<SearchProps> = ({
  placeholder = 'Search...',
  autoCompleteStyle,
  inputStyle,
  onSelect,
}) => {
  const navigate = useNavigate();
  const { searchQuery, setSearchQuery, searchResults } = useSearch();

  const options = searchResults.map(rawItem => {
    const normalizedItem = normalizeSearchResult(rawItem);
    return {
      value: normalizedItem.id,
      label: <SearchResultCard {...normalizedItem} />
    };
  });

  const handleEnter = () => {
    navigate(`/search?q=${encodeURIComponent(searchQuery.trim())}`);
    setSearchQuery('');
  };

  return (
    <AutoComplete
      options={options}
      onSelect={onSelect}
      popupMatchSelectWidth={false}
      style={{
        minWidth: 120,
        maxWidth: 500,
        width: '100%',
        ...autoCompleteStyle
      }}
      dropdownStyle={{
        maxWidth: 200
      }}
      value={searchQuery}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <div style={{ float: 'right', position: 'relative' }}>
            <Button
              style={{
                position: 'absolute',
                bottom: -35,
                right: -4,
              }}
              type='dashed'
              size='small'
              onClick={handleEnter}
            >
              View full results<EnterOutlined />
            </Button>
          </div>
        </div>
      )}
    >
      <Input
        autoFocus={false}
        variant='filled'
        style={{
          width: '100%',
          ...inputStyle
        }}
        placeholder={placeholder}
        value={searchQuery}
        prefix={<SearchOutlined />}
        onChange={(e) => setSearchQuery(e.target.value)}
        onPressEnter={handleEnter}
        allowClear={{ clearIcon: <CloseCircleOutlined /> }}
      />
    </AutoComplete>
  );
};

export default Search;
