import { FieldType } from './System.types';
import { RecordStatus } from './System.Parameters.types';
import { fieldDataType } from './System.FieldTypes.types';

// Current types that will remain active
export type BuiltInFieldTypeId = 
    | 'projectStartDate';

/**
 * Represents the built-in field types in the system.
 * These are used to build the built-in data types, and the org-level-config field types.
 */
export const builtInFieldType: Record<BuiltInFieldTypeId, FieldType> = {
    projectStartDate: {
        fieldTypeId: 'projectStartDate',
        fieldDataTypeId: 'date',
        description: {
            shortLabel: 'Project Start Date',
            longDescription: 'Start date of the project',
        },
        status: RecordStatus.Active,
        defaultValue: '',
        validation: fieldDataType.date.validation,
        errorMessage: fieldDataType.date.errorMessage,
    }
};
