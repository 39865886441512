import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import { DataProvider } from './providers/DataProvider';
import { UserProvider } from './providers/UserProvider';
import { ThemeProvider } from './providers/ThemeProvider';
import { AIProvider } from './providers/AIProvider'
import { SearchWrapper } from './components/SearchWrapper';

const rootElement = document.getElementById('root');

if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement as HTMLElement);

root.render(
  <React.StrictMode>
    <DataProvider>
      <UserProvider>
        <ThemeProvider>
          <SearchWrapper>
            <AIProvider>
              <App />
            </AIProvider>
          </SearchWrapper>
        </ThemeProvider>
      </UserProvider>
    </DataProvider>
  </React.StrictMode>
);
