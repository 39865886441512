import { FC, useContext, useState } from 'react';
import { Modal, Select, AutoComplete, Table, Space, Typography } from 'antd';
import { UserDBRole } from '../../types/System.types';
import { useFormAccess, UserAccessItem } from '../../use/useFormAccess';
import { UserContext } from '../../providers/UserProvider';

const { Text } = Typography;

interface FormAccessModalProps {
    formId: string;
    open: boolean;
    onClose: () => void;
}

export const FormAccessModal: FC<FormAccessModalProps> = ({
    formId,
    open,
    onClose,
}) => {
    const { user } = useContext(UserContext);
    const [modal, contextHolder] = Modal.useModal();
    const {
        form,
        groups,
        users,
        searchResults,
        isOwner,
        updateAccess,
        searchUsers,
    } = useFormAccess({
        formId,
        enabled: open
    });

    const [searchValue, setSearchValue] = useState('');

    const columns = [
        {
            title: 'Name',
            dataIndex: 'displayName',
            key: 'displayName',
            render: (name: string, record: UserAccessItem) => {
                const hasRole = form?.userRoles?.[record.docId];
                return (
                    <Space>
                        <Text>{name}</Text>
                        {!hasRole && (
                            <Text type="secondary" italic>
                                (Not added)
                            </Text>
                        )}
                    </Space>
                );
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (role: UserDBRole, record: UserAccessItem) => {
                const currentRole = form?.userRoles?.[record.docId || ''];
                const isCurrentUser = record.docId === user?.uid;
                const cannotRemove = isCurrentUser && currentRole === UserDBRole.owner;

                return (
                    <Select
                        size='small'
                        variant='borderless'
                        value={currentRole || role}
                        onChange={(newRole) => handleRoleChange(record.docId || '', newRole)}
                        disabled={!isOwner || (isCurrentUser && currentRole === UserDBRole.owner)}
                        style={{ width: '100%' }}
                    >
                        {Object.values(UserDBRole).map((role) => (
                            <Select.Option key={role} value={role}>
                                {role}
                            </Select.Option>
                        ))}
                        {!cannotRemove && (
                            <Select.Option key="remove" value="remove">
                                <Text type="danger">remove</Text>
                            </Select.Option>
                        )}
                    </Select>
                );
            },
        },
    ];

    const handleSearch = (value: string) => {
        searchUsers(value);
    };

    const handleSelect = async (value: string) => {
        const selectedUser = searchResults.find(user => user.docId === value);
        if (!selectedUser) return;

        const updatedRoles = {
            ...form?.userRoles,
            [selectedUser.docId]: UserDBRole.viewer,
        };
        await updateAccess({ userRoles: updatedRoles });
        setSearchValue('');
        searchUsers('');
    };

    const handleRoleChange = async (userId: string, role: UserDBRole | 'remove') => {
        if (role === 'remove') {
            const { [userId]: removedRole, ...updatedRoles } = form?.userRoles || {};
            await updateAccess({ userRoles: updatedRoles });
            return;
        }

        const updatedRoles = {
            ...form?.userRoles,
            [userId]: role,
        };
        await updateAccess({ userRoles: updatedRoles });
    };

    const handleGroupChange = async (groupId: string) => {
        const currentGroup = groups.find(g => g.docId === form?.userGroup);
        const newGroup = groups.find(g => g.docId === groupId);

        modal.confirm({
            title: 'Change Group',
            content: (
                <Space direction="vertical">
                    <Typography.Text>
                        Are you sure you want to change the form's group from{' '}
                        <Typography.Text strong>{currentGroup?.name || 'none'}</Typography.Text>{' '}
                        to{' '}
                        <Typography.Text strong>{newGroup?.name}</Typography.Text>?
                    </Typography.Text>
                </Space>
            ),
            onOk: async () => {
                const currentUserRole = form?.userRoles?.[user?.uid || ''];
                await updateAccess({
                    userGroup: groupId,
                    userRoles: {
                        ...form?.userRoles,
                        [user?.uid || '']: currentUserRole || UserDBRole.owner
                    }
                });
            }
        });
    };

    return (
        <Modal
            title="Manage Form Access"
            open={open}
            onCancel={onClose}
            footer={null}
            width={800}
        >
            <Space direction="vertical" style={{ width: '100%' }} size="large">
                {contextHolder}
                <div>
                    <Text>Form Group</Text>
                    <Select
                        style={{ width: '100%' }}
                        value={form?.userGroup}
                        onChange={handleGroupChange}
                        placeholder="Select a group"
                        allowClear
                    >
                        {groups.map((group) => (
                            <Select.Option key={group.docId} value={group.docId || ''}>
                                {group.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div>
                    <Text>Add User Access</Text>
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <AutoComplete
                            style={{ width: '100%' }}
                            placeholder="Search users by name or email"
                            onSearch={handleSearch}
                            onSelect={handleSelect}
                            value={searchValue}
                            onChange={setSearchValue}
                            options={searchResults.map(user => ({
                                label: (
                                    <Space>
                                        <span>{user.displayName}<Text type="secondary"> {user.email}</Text></span>
                                    </Space>
                                ),
                                value: user.docId,
                            }))}
                            notFoundContent="No users found"
                        />
                        <Table<UserAccessItem>
                            size="small"
                            columns={columns}
                            dataSource={users}
                            rowKey="docId"
                            pagination={false}
                            locale={{
                                emptyText: 'No users have access'
                            }}
                        />
                    </Space>
                </div>
            </Space>
        </Modal>
    );
};

export default FormAccessModal; 