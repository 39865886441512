import {
  LazyExoticComponent,
  ComponentType,
  Suspense,
  lazy,
  createElement
} from 'react';
import { IconProps as FeatherIconProps } from 'react-feather';

interface ExtendedIconProps extends Omit<FeatherIconProps, 'size'> {
  shift?: number;
  size?: string | number;
}

function pascalToKebabCase(str: string): string {
  const s = str.charAt(0).toLowerCase() + str.slice(1);
  return s.replace(/([A-Z])/g, '-$1').toLowerCase();
}

const iconCache: Record<string, LazyExoticComponent<ComponentType<any>>> = {};

/**
 * Dynamic icon component that lazy loads Feather icons on demand
 * @example
 * ```tsx
 * <Icon.Activity size={24} color="red" />
 * <Icon.ArrowRight shift={2} />
 * ```
 */
const Icon = new Proxy(
  {},
  {
    get: (_target, prop: string) => {
      if (!iconCache[prop]) {
        iconCache[prop] = lazy(() =>
          import(`react-feather/dist/icons/${pascalToKebabCase(prop)}`).then(
            (module: any) => {
              if (!module?.default) {
                return Promise.reject(
                  new Error(`Icon ${prop} does not exist in 'react-feather/dist/icons'`)
                );
              }
              const FeatherIcon = module.default;
              return {
                default: ({
                  shift = 1,
                  size = 16,
                  ...iconProps
                }: ExtendedIconProps) => (
                  <FeatherIcon
                    strokeWidth={1.75}
                    size={size}
                    style={{
                      marginTop: shift ? -shift : 0,
                      marginBottom: shift ? -shift : 0
                    }}
                    {...iconProps}
                  />
                )
              };
            }
          )
        );
      }

      return (props: ExtendedIconProps) => (
        <Suspense fallback={<div />}>
          {createElement(iconCache[prop], props)}
        </Suspense>
      );
    }
  }
);
export default Icon as any;
