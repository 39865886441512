import { FC, useContext, PropsWithChildren } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Spin } from 'antd';

import LoginPage from './pages/LoginPage';
import ErrorPage from './pages/ErrorPage';
import PasswordResetPage from './pages/PasswordResetPage';
import AgentPage from './pages/AgentPage';
import FormsPage from './pages/FormsPage';
import SearchPage from './pages/SearchPage';
import EntitiesPage from './pages/EntitiesPage';
import FormPage from './pages/FormPage'; 
import AnalyticsPage from './pages/AnalyticsPage';
import SettingsPage from './pages/SettingsPage';
import NotificationsPage from './pages/NotificationsPage';
import HomePage from './pages/HomePage';

import { UserContext } from './providers/UserProvider';

const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const userContext = useContext(UserContext);
  const isLoggedIn = userContext.isLoggedIn;
  const isLoggingIn = userContext.loading;
  let location = useLocation();

  if (isLoggingIn) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
        <Spin size="large" />
      </div>
    );
  }

  if (isLoggedIn) {
    return children;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={<LoginPage />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/password-reset"
          element={<PasswordResetPage />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/forms"
          element={
            <ProtectedRoute>
              <FormsPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/forms/:formId"
          element={
            <ProtectedRoute>
              <FormPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/entities"
          element={
            <ProtectedRoute>
              <EntitiesPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/entities/:entityId"
          element={
            <ProtectedRoute>
              <EntitiesPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/entities/:entityId/records/:recordId"
          element={
            <ProtectedRoute>
              <EntitiesPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/agent"
          element={
            <ProtectedRoute>
              <AgentPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/search"
          element={
            <ProtectedRoute>
              <SearchPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/analytics"
          element={
            <ProtectedRoute>
              <AnalyticsPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/notifications"
          element={
            <ProtectedRoute>
              <NotificationsPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/settings/*"
          element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="*"
          element={
            <Navigate to="/" />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;