// WARNING: If you add imports here these will also impact sub repositories (cloudRun, functions, etc), you might need to add imports there as well.
import { FieldType } from './System.types';
import { RecordStatus } from './System.Parameters.types';
import { fieldDataType } from './System.FieldTypes.types';

// Current types that will remain active
export type ConfigFieldTypeId = 
  | 'didCalculation'
  | 'estimatedBudget'
  | 'expectedDeliveryDate'
  | 'customerNotes'
  | 'hasControls'
  | 'internalNotes'
  | 'isOutOfTown'
  | 'logoAttachment'
  | 'logoURL'
  | 'needsCloseList'
  | 'qos'
  | 'spc'
  | 'verticalMarket'
  | 'billOfMaterials'
    | 'bidPrice'
    | 'buyPrice'
    | 'contractorBidPrice'
    | 'contractorExtBidPrice'
    | 'contractorMarkup'
    | 'cutComm'
    | 'cutCommAmt'
    | 'cutPrice'
    | 'cutTiers'
    | 'description'
    | 'distMarkdown'
    | 'distMarkup'
    | 'effectiveCommissionPercent'
    | 'effectiveEnd'
    | 'effectiveStart'
    | 'extBidPrice'
    | 'extBuyPrice'
    | 'manufacturer'
    | 'oBaseComm'
    | 'oBaseCommission'
    | 'oBasePrice'
    | 'overageAmt'
    | 'overageComm'
    | 'overageCommission'
    | 'partNumber'
    | 'quantity'
    | 'totalCommissionAmt'
    | 'upc'
  ;


/**
 * Select options for Select fields
 */
export const selectOptions = {
  /**
   * Select options for the vertical market field
   */
  verticalMarket: [
    {
      value: "tenantImprovement",
      label: "Tenant Improvement"
    },
    {
      value: "hospitality",
      label: "Hospitality"
    },
    {
      value: "esco",
      label: "ESCO"
    },
    {
      value: "healthcare",
      label: "Healthcare"
    },
    {
      value: "dataCenter",
      label: "Data Center"
    },
    {
      value: "higherEducation",
      label: "Higher Education"
    },
    {
      value: "k12",
      label: "K-12"
    },
    {
      value: "transportation",
      label: "Transportation"
    },
    {
      value: "municipality",
      label: "Municipality"
    },
    {
      value: "multiFamily",
      label: "Multi-Family"
    },
    {
      value: "warehouse",
      label: "Warehouse"
    },
    {
      value: "manufacturing",
      label: "Manufacturing"
    },
    {
      value: "architainment",
      label: "Architainment"
    }
  ]
};

/**
 * Field types for the CLW system
 */
export const configFieldType: Record<ConfigFieldTypeId, FieldType> = {
  didCalculation: {
    fieldTypeId: 'didCalculation',
    fieldDataTypeId: 'yesNo',
    description: {
      shortLabel: 'Calculation Done',
      longDescription: 'Did we spend the time and money to do a calculation?',
    },
  },
  estimatedBudget: {
    fieldTypeId: 'estimatedBudget',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Estimated Budget',
      longDescription: 'Estimated budget for the lighting project',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  expectedDeliveryDate: {
    fieldTypeId: 'expectedDeliveryDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Expected Delivery Date',
      longDescription: 'Expected date of delivery for the form',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  customerNotes: {
    fieldTypeId: 'customerNotes',
    fieldDataTypeId: 'longText',
    description: {
      shortLabel: 'Customer Notes',
      longDescription: 'Notes that will be shared with the customer',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.longText.validation,
    errorMessage: fieldDataType.longText.errorMessage,
  },
  spc: {
    fieldTypeId: 'spc',
    fieldDataTypeId: 'yesNo',
    description: {
      shortLabel: 'SPC',
      longDescription: 'Strategic Project Coordinator',
    },
  },
  hasControls: {
    fieldTypeId: 'hasControls',
    fieldDataTypeId: 'yesNo',
    description: {
      shortLabel: 'Controls',
      longDescription: 'Does the order have controls?',
    },
  },
  internalNotes: {
    fieldTypeId: 'internalNotes',
    fieldDataTypeId: 'longText',
    description: {
      shortLabel: 'Internal Notes',
      longDescription: 'Notes that will NOT be shared with the customer',
    },
  },
  isOutOfTown: {
    fieldTypeId: 'isOutOfTown',
    fieldDataTypeId: 'yesNo',
    description: {
      shortLabel: 'Out of Town',
      longDescription: 'Is the order out of town?',
    },
  },
  logoURL: {
    fieldTypeId: 'logoURL',
    fieldDataTypeId: 'url',
    description: {
      shortLabel: 'Logo URL',
      longDescription: 'URL of the logo',
    },
  },
  logoAttachment: {
    fieldTypeId: 'logoAttachment',
    fieldDataTypeId: 'attachments',
    description: {
      shortLabel: 'Logo Attachment',
      longDescription: 'Attachment of the logo',
    },
  },
  needsCloseList: {
    fieldTypeId: 'needsCloseList',
    fieldDataTypeId: 'yesNo',
    description: {
      shortLabel: 'Close List',
      longDescription: 'Does the order need a close list?',
    },
  },
  qos: {
    fieldTypeId: 'qos',
    fieldDataTypeId: 'custom',
    description: {
      shortLabel: 'Quality of Specification',
      longDescription: 'Quality of Specification',
    },
    status: RecordStatus.Active,
    validation: fieldDataType.custom.validation,
    errorMessage: fieldDataType.custom.errorMessage,
  },
  verticalMarket: {
    fieldTypeId: 'verticalMarket',
    fieldDataTypeId: 'singleSelect',
    props: {
      options: selectOptions.verticalMarket,
    },
    description: {
      shortLabel: 'Vertical Market',
      longDescription: 'Vertical market segment for the project',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    errorMessage: 'Must be a valid vertical market segment (e.g., "Tenant Improvement", "Healthcare", "Data Center")',
  },
  billOfMaterials: {
    fieldTypeId: 'billOfMaterials',
    fieldDataTypeId: 'table',
    description: {
      shortLabel: 'BOM Table',
      longDescription: 'Bill of Materials (BOM) Table',
    },
    status: RecordStatus.Active,
    defaultValue: [],
    validation: (value: any) => Array.isArray(value),
  },
  bidPrice: {
    fieldTypeId: 'bidPrice',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Bid Price',
      longDescription: 'Price being bid for the item',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  buyPrice: {
    fieldTypeId: 'buyPrice',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Buy Price',
      longDescription: 'Purchase price of the item',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  contractorBidPrice: {
    fieldTypeId: 'contractorBidPrice',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Contractor Bid Price',
      longDescription: 'Bid price from the contractor',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  contractorExtBidPrice: {
    fieldTypeId: 'contractorExtBidPrice',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Extended Contractor Bid Price',
      longDescription: 'Extended bid price from the contractor (typically price × quantity)',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  contractorMarkup: {
    fieldTypeId: 'contractorMarkup',
    fieldDataTypeId: 'percentage',
    description: {
      shortLabel: 'Contractor Markup',
      longDescription: 'Markup percentage applied by the contractor',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  cutComm: {
    fieldTypeId: 'cutComm',
    fieldDataTypeId: 'percentage',
    description: {
      shortLabel: 'Cut Commission',
      longDescription: 'Commission percentage after cuts/adjustments',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  cutCommAmt: {
    fieldTypeId: 'cutCommAmt',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Cut Commission Amount',
      longDescription: 'Commission amount after cuts/adjustments',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  cutPrice: {
    fieldTypeId: 'cutPrice',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Cut Price',
      longDescription: 'Price after cuts/adjustments',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  cutTiers: {
    fieldTypeId: 'cutTiers',
    fieldDataTypeId: 'table',
    description: {
      shortLabel: 'Additional Cut Pricing',
      longDescription: 'Additional cut pricing and commission structure',
    },
    status: RecordStatus.Active,
    defaultValue: [],
    validation: fieldDataType.table.validation,
    errorMessage: fieldDataType.table.errorMessage,
  },
  description: {
    fieldTypeId: 'description',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Description',
      longDescription: 'Detailed description of the submittal',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  distMarkdown: {
    fieldTypeId: 'distMarkdown',
    fieldDataTypeId: 'percentage',
    description: {
      shortLabel: 'Distributor Markdown',
      longDescription: 'Markdown percentage applied by the distributor',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  distMarkup: {
    fieldTypeId: 'distMarkup',
    fieldDataTypeId: 'percentage',
    description: {
      shortLabel: 'Distributor Markup',
      longDescription: 'Markup percentage applied by the distributor',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  effectiveCommissionPercent: {
    fieldTypeId: 'effectiveCommissionPercent',
    fieldDataTypeId: 'percentage',
    description: {
      shortLabel: 'Effective Commission Percentage',
      longDescription: 'Final effective commission percentage after all adjustments',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  effectiveEnd: {
    fieldTypeId: 'effectiveEnd',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Effective End Date',
      longDescription: 'Date when pricing expires',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  effectiveStart: {
    fieldTypeId: 'effectiveStart',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Effective Start Date',
      longDescription: 'Date when pricing becomes effective',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  extBidPrice: {
    fieldTypeId: 'extBidPrice',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Extended Bid Price',
      longDescription: 'Extended bid price (typically price × quantity)',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  extBuyPrice: {
    fieldTypeId: 'extBuyPrice',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Extended Buy Price',
      longDescription: 'Extended buy price (typically price × quantity)',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  manufacturer: {
    fieldTypeId: 'manufacturer',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Manufacturer',
      longDescription: 'Name of the manufacturer',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  oBaseComm: {
    fieldTypeId: 'oBaseComm',
    fieldDataTypeId: 'percentage',
    description: {
      shortLabel: 'oBase Commission',
      longDescription: 'Commission percentage for the oBase price',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  oBaseCommission: {
    fieldTypeId: 'oBaseCommission',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Base Commission Amount',
      longDescription: 'Base commission amount calculated from oBase price and commission rate',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  oBasePrice: {
    fieldTypeId: 'oBasePrice',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'oBase Price',
      longDescription: 'Base price threshold - when sell price equals or exceeds this amount, both base commission and overage commission apply',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  overageAmt: {
    fieldTypeId: 'overageAmt',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Overage Amount',
      longDescription: 'Amount exceeding the base price threshold',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  overageComm: {
    fieldTypeId: 'overageComm',
    fieldDataTypeId: 'percentage',
    description: {
      shortLabel: 'Overage Commission',
      longDescription: 'Commission percentage for overage amounts',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  overageCommission: {
    fieldTypeId: 'overageCommission',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Overage Commission Amount',
      longDescription: 'Commission amount calculated on the overage amount',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  partNumber: {
    fieldTypeId: 'partNumber',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Part Number',
      longDescription: 'Manufacturer part number',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  quantity: {
    fieldTypeId: 'quantity',
    fieldDataTypeId: 'number',
    description: {
      shortLabel: 'Quantity',
      longDescription: 'Number of units',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.number.validation,
    errorMessage: fieldDataType.number.errorMessage,
  },
  totalCommissionAmt: {
    fieldTypeId: 'totalCommissionAmt',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Total Commission Amount',
      longDescription: 'Total commission amount including base and overage commission',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  upc: {
    fieldTypeId: 'upc',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'UPC',
      longDescription: 'Universal Product Code',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  }
};

