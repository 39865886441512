import { FC, useContext, useMemo } from 'react';
import { Divider, Typography } from 'antd';

import FlexBox from '../atoms/FlexBox';
import { UserContext } from '../../providers/UserProvider';
import { UserDBRole } from '../../types/System.types';
import { LayoutEditorProvider, useLayoutEditorContext } from '../../providers/editor/LayoutEditorProvider';
import { LayoutEditor } from '../editor/LayoutEditor';
import { LayoutEditorToolbar } from '../editor/LayoutEditorToolbar';
import { LayoutEditorDescription } from '../editor/LayoutEditorDescription';
import LayoutEditorFormDetails from '../editor/LayoutEditorFormDetails';

const { Text } = Typography;

type EditorType = 'form' | 'entity';

interface LayoutEditorTabProps {
    type: EditorType;
}

/**
 * Content component that renders either the layout editor or the appropriate details component
 * based on the editor mode.
 */
const InContextContent: FC<{ type: EditorType }> = ({ type }) => {
    const editor = useLayoutEditorContext();
    const DetailsComponent = type === 'form' ? LayoutEditorFormDetails : LayoutEditorDescription;
    
    return editor.mode === 'Layout' ? 
        <LayoutEditor /> : 
        <DetailsComponent />;
};

/**
 * Unified tab component for both form and entity layout editing.
 * Handles access control and provides the appropriate editor context.
 * 
 * @param props.type - The type of editor to render ('form' | 'entity')
 */
const LayoutEditorTab: FC<LayoutEditorTabProps> = ({ type }) => {
    const { userGroups } = useContext(UserContext);

    const hasLayoutAccess = useMemo(() => {
        const adminGroup = type === 'form' ? 'formAdmins' : 'entityAdmins';
        const editorGroup = type === 'form' ? 'formEditors' : 'entityEditors';
        
        return (userGroups[adminGroup] === UserDBRole.editor || userGroups[adminGroup] === UserDBRole.owner) ||
               (userGroups[editorGroup] === UserDBRole.editor || userGroups[editorGroup] === UserDBRole.owner);
    }, [userGroups, type]);

    if (!hasLayoutAccess) {
        return (
            <FlexBox column alignStart justifyStart stretch>
                <Text type="secondary">
                    You don't have access to the {type === 'form' ? 'form' : 'reference'} editor.
                </Text>
            </FlexBox>
        );
    }

    return (
        <FlexBox column alignStart justifyStart stretch>
            <LayoutEditorProvider type={type}>
                <LayoutEditorToolbar />
                <Divider style={{ margin: 0 }} />
                <InContextContent type={type} />
            </LayoutEditorProvider>
        </FlexBox>
    );
};

export default LayoutEditorTab; 