import { FC, useEffect, useState } from 'react';
import { Select, Button, Tooltip } from 'antd';
import { useSearch } from '../../use/useSearch';
import { useGet } from '../../use/data/useGet';
import { SearchResultCard } from './SearchResultCard';
import { normalizeSearchResult } from '../../services/searchConfig';
import { DocId, EntityRecord } from '../../types/System.types';
import { PlusOutlined } from '@ant-design/icons';

interface EntityRecordSelectProps {
  /** ID of the entity to select records from */
  entityId?: DocId;
  /** Currently selected record ID */
  recordId?: DocId;
  /** Callback function when a record is selected or cleared */
  onChange?: (value: string | undefined, entityRecord?: EntityRecord | null) => void;
  /** Callback function when add record button is clicked */
  onAddRecord?: () => void;
  /** Disables user interaction with the selector */
  disabled?: boolean;
  /** Disables the add record button */
  disableAddButton?: boolean;
  /** Custom styles for the select control */
  style?: React.CSSProperties;
  /** Custom styles for the dropdown menu */
  dropdownStyle?: React.CSSProperties;
  /** Additional props passed to Ant Design Select component */
  [key: string]: any;
}

/**
 * A searchable dropdown component for selecting entity records.
 * Fetches and reads the selected entity record directly from the database using the useGet hook.
 * When a record is selected, it returns both the record ID and the complete entity record object.
 * 
 * @component
 * @example
 * ```tsx
 * <EntityRecordSelect
 *   entityId="entity123"
 *   recordId="record456"
 *   onChange={(value, entityRecord) => {
 *     console.log('Selected ID:', value);
 *     console.log('Full Record:', entityRecord);
 *   }}
 *   disabled={false}
 * />
 * ```
 */
const EntityRecordSelect: FC<EntityRecordSelectProps> = ({ 
  entityId,
  recordId: initialRecordId,
  onChange,
  onAddRecord,
  disabled,
  disableAddButton,
  style,
  dropdownStyle,
  ...props
}) => {
  const [recordId, setRecordId] = useState<string | null>(initialRecordId || null);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const { get } = useGet();
  const {
    searchResults,
    setSearchQuery,
    isLoading: searchLoading,
    error
  } = useSearch({
    filter: entityId ? `docIdParent:${entityId}` : undefined
  });
  
  const handleChange = async (newRecordId: string | null) => {
    setRecordId(newRecordId);
    
    if (newRecordId && entityId) {
      const record = await get<EntityRecord>(`entities/${entityId}/records`, newRecordId);
      onChange?.(newRecordId, record);
    } else {
      onChange?.(newRecordId ?? undefined);
    }
  };

  useEffect(() => {
    setRecordId(initialRecordId || null);
  }, [initialRecordId]);

  return (
    <Select
      id={`entity-record-select-${entityId}`}
      open={isSelectOpen}
      onDropdownVisibleChange={setIsSelectOpen}
      style={{
        width: '100%',
        maxWidth: 400,
        ...style
      }}
      dropdownStyle={{
        width: '100%',
        maxWidth: 600,
        ...dropdownStyle
      }}
      variant='filled'
      showSearch
      allowClear
      loading={searchLoading}
      placeholder='Search Records'
      optionFilterProp='label'
      onChange={handleChange}
      onSearch={setSearchQuery}
      value={recordId}
      disabled={disabled}
      notFoundContent={
        error ? `Error: ${error}` :
        !entityId ? 'Please select an entity first' :
        'No results found'
      }
      optionRender={(option) => (
        <SearchResultCard 
          {...normalizeSearchResult(option.data)} 
          url=''
        />
      )}
      dropdownRender={menu => (
        <div>
          {menu}
          <Tooltip 
            title={disableAddButton ? "You don't have edit access" : ""}
            open={disableAddButton ? undefined : false}
          >
            <Button
              type="link"
              size="small"
              style={{ 
                marginTop: 5,
                width: '100%'
              }}
              onClick={() => {
                setIsSelectOpen(false);
                setRecordId(null);
                onAddRecord?.();
              }}
              disabled={disableAddButton || !onAddRecord}
              icon={<PlusOutlined />}
            >
              Add Record
            </Button>
          </Tooltip>
        </div>
      )}
      options={searchResults.map(option => ({
        key: option.objectID,
        value: option.objectID,
        label: option.name || '',
        ...option
      }))}
      {...props}
    />
  );
};

export default EntityRecordSelect; 