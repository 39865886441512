import { FC, useState, useCallback } from 'react';
import { Table, Space, AutoComplete, Select, Typography } from 'antd';
import { User } from 'firebase/auth';
import { UserDBRole } from '../../types/System.types';
import { useGroupAccess, GroupMemberItem } from '../../use/useGroupAccess';

const { Text } = Typography;

interface GroupMembersTableProps {
    groupId: string;
    isOrganizationAdmin: boolean;
    user: User | null;
    groupName?: string;
    onMemberRemove: (
        member: GroupMemberItem,
        groupName: string,
        removeMember: (memberId: string) => Promise<void>
    ) => void;
    onMessage: {
        success: (message: string) => void;
        error: (message: string) => void;
    };
}

const GroupMembersTable: FC<GroupMembersTableProps> = ({
    groupId,
    isOrganizationAdmin,
    user,
    groupName,
    onMemberRemove,
    onMessage
}) => {
    const [searchValue, setSearchValue] = useState('');

    const {
        members,
        searchResults,
        updateMember,
        removeMember,
        searchUsers,
        loading: groupLoading
    } = useGroupAccess({
        groupId,
        enabled: true
    });

    const handleRemoveMember = useCallback(async (memberId: string): Promise<void> => {
        await removeMember(memberId);
    }, [removeMember]);

    const handleSearch = (value: string) => {
        searchUsers(value);
    };

    const handleSelect = async (userId: string) => {
        const selectedUser = searchResults.find(user => user.docId === userId);
        if (!selectedUser) return;

        try {
            await updateMember(userId, UserDBRole.editor);
            setSearchValue('');
            searchUsers('');
            onMessage.success('Member added successfully');
        } catch (error) {
            console.error('Error adding member:', error);
            onMessage.error('Failed to add member');
        }
    };

    const handleRoleChange = async (userId: string, role: UserDBRole | 'remove') => {
        if (role === 'remove') {
            const member = members.find(m => m.docId === userId);
            if (member && groupName) {
                onMemberRemove(member, groupName, handleRemoveMember);
            }
            return;
        }

        try {
            await updateMember(userId, role);
            onMessage.success('Role updated successfully');
        } catch (error) {
            console.error('Error updating role:', error);
            onMessage.error('Failed to update role');
        }
    };

    const memberColumns = [
        {
            title: 'Name',
            key: 'name',
            render: (_: any, record: GroupMemberItem) => (
                <Space>
                    <Text>{record?.displayName || record?.email || record?.docId}</Text>
                    {record?.displayName && <Text type="secondary">{record?.email}</Text>}
                </Space>
            ),
        },
        {
            title: 'Role',
            key: 'role',
            render: (_: any, record: GroupMemberItem) => (
                <Select
                    size='small'
                    variant='borderless'
                    value={record.role}
                    onChange={(newRole) => handleRoleChange(record.docId, newRole)}
                    disabled={!isOrganizationAdmin || record.docId === user?.uid}
                    style={{ width: '100%' }}
                >
                    {Object.values(UserDBRole).map((role) => (
                        <Select.Option key={role} value={role}>
                            {role}
                        </Select.Option>
                    ))}
                    {record.docId !== user?.uid && (
                        <Select.Option key="remove" value="remove">
                            <Text type="danger">remove</Text>
                        </Select.Option>
                    )}
                </Select>
            ),
        },
    ];

    return (
        <Table<GroupMemberItem>
            size="small"
            columns={memberColumns}
            dataSource={members}
            rowKey="docId"
            pagination={false}
            loading={groupLoading}
            bordered={true}
            locale={{
                emptyText: 'No members'
            }}
            footer={isOrganizationAdmin ? () => (
                <div>
                    <Text>Add User</Text>
                    <AutoComplete
                        style={{ width: '100%' }}
                        placeholder="Search users by name, email, or ID"
                        onSearch={handleSearch}
                        onSelect={handleSelect}
                        value={searchValue}
                        onChange={setSearchValue}
                        options={searchResults.map(user => ({
                            label: (
                                <Space>
                                    <Text>{user?.displayName || user?.email || user?.docId}</Text>
                                    {user?.displayName && <Text type="secondary">{user?.email}</Text>}
                                </Space>
                            ),
                            value: user.docId,
                        }))}
                        notFoundContent={groupLoading ? "Loading..." : "No users found"}
                    />
                </div>
            ) : undefined}
        />
    );
};

export default GroupMembersTable; 