import { FC } from 'react';
import { Button, Dropdown, Modal } from 'antd';
import { MoreOutlined, DeleteOutlined, ClearOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';

/**
 * Props for the RowActions component
 * @interface RowActionsProps
 */
interface RowActionsProps {
    /** Callback function to handle row deletion */
    onRemoveRow?: () => void;
    /** Callback function to clear row contents */
    onClearRow?: () => void;
    /** Flag to disable all actions */
    disabled?: boolean;
    /** Additional props to pass to the dropdown trigger button */
    buttonProps?: any;
}

/**
 * Dropdown menu component for row-level actions like delete, clear, and undo
 * @component
 * @param {RowActionsProps} props - Component props
 * @returns {JSX.Element} Dropdown menu with row actions
 */
const RowActions: FC<RowActionsProps> = ({
    onRemoveRow,
    onClearRow,
    disabled,
    buttonProps = {}
}) => {
    const handleDelete = () => {
        Modal.confirm({
            title: 'Delete Row',
            content: 'Are you sure you want to delete this row?',
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: onRemoveRow
        });
    };

    const items = [
        onClearRow && ({
            key: 'clear',
            label: 'Clear Row',
            icon: <ClearOutlined />,
            onClick: onClearRow,
            disabled: disabled
        }),
        onRemoveRow && ({
            key: 'delete',
            label: 'Delete Row',
            icon: <DeleteOutlined />,
            onClick: handleDelete,
            disabled: disabled,
            danger: true
        })
    ].filter(Boolean) as MenuProps['items'];

    return (
        <Dropdown 
            menu={{ items }} 
            trigger={['click']} 
            disabled={disabled}
        >
            <Button
                type="text"
                icon={<MoreOutlined />}
                {...buttonProps}
            />
        </Dropdown>
    );
};

export default RowActions;