import { FC, useContext, useState } from 'react';
import {
    DndContext,
    DragEndEvent,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
    DragStartEvent
} from '@dnd-kit/core';
import { 
    SortableContext, 
    rectSortingStrategy 
} from '@dnd-kit/sortable';
import { 
    Empty, 
    Splitter, 
    message 
} from 'antd';
import { 
    Button, 
    Modal, 
    Collapse, 
    Typography, 
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import FlexCol from '../atoms/FlexCol';
import FlexBox from '../atoms/FlexBox';
import { LayoutEditorContext } from '../../providers/editor/LayoutEditorProvider';
import { LayoutEditorElement } from './LayoutEditorElement';
import { PreviewEntityProvider } from '../../providers/editor/PreviewEntityProvider';
import { PreviewFormProvider } from '../../providers/editor/PreviewFormProvider';
import Entity from '../Entity';
import FormUI from '../rendering/FormUI';
import { EditorUIElement } from '../../types/System.types';
import MetaInfo from '../atoms/MetaInfo';

const { Text } = Typography;

/**
 * Main layout editor component that provides a drag-and-drop interface for building form layouts.
 * Supports nested containers, fields, and entity references with real-time preview.
 * Includes state management for selection, deletion, and layout structure.
 * @component
 */
export const LayoutEditor: FC = () => {
    const [draggedElement, setDraggedElement] = useState<EditorUIElement | null>(null);
    const editor = useContext(LayoutEditorContext);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    if (!editor) throw new Error('Must be used within LayoutEditorProvider');
    const {
        layout,
        dropElement,
        previewMode,
        deleteStructure,
        selectedStructureId,
        type,

    } = editor;

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            }
        })
    );

    const handleDragStart = (event: DragStartEvent) => {
        const element = event.active.data.current?.element as EditorUIElement;
        setDraggedElement(element);
    };

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (!over) return;
        dropElement(active, over);
        setDraggedElement(null);
    };

    const handleDelete = async () => {
        setIsDeleting(true);
        setIsDeleteModalOpen(false);
        try {
            const success = await deleteStructure(selectedStructureId || '');
            if (success) {
                messageApi.success(`${type === 'form' ? 'Form' : 'Record'} deleted successfully`);
            } else {
                messageApi.error(`Failed to delete ${type === 'form' ? 'form' : 'record'}`);
            }
        } catch (error) {
            console.error('Error deleting:', error);
            messageApi.error(`Failed to delete ${type === 'form' ? 'form' : 'record'}`);
        } finally {
            setIsDeleting(false);
        }
    };

    const rootChildIds = ((layout.structure).children || []).map(child => child.dndId);
    
    if (!selectedStructureId) {
        return (
            <FlexBox stretch justifyCenter>
                <Empty description={`Select or create a ${type === 'form' ? 'Form' : 'Record'} to begin editing.`} />
            </FlexBox>
        );
    }

    return (
        <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
        >
            {contextHolder}
            <Splitter
                layout='horizontal'
                style={{
                    display: 'flex',
                    flexGrow: 1,
                }}
            >
                <Splitter.Panel
                    style={{ paddingRight: 10 }}
                    min='30%'
                >
                    <FlexCol
                        noGrow
                        style={{
                            flex: 1,
                            minWidth: '33vh',
                            maxWidth: '100vh'
                        }}
                    >
                        <SortableContext
                            items={rootChildIds}
                            strategy={rectSortingStrategy}
                        >
                            <LayoutEditorElement
                                element={layout.structure}
                                parentElement={null}
                            />
                        </SortableContext>
                        <DragOverlay>
                            {draggedElement ? (
                                <LayoutEditorElement
                                    element={draggedElement}
                                    parentElement={null}
                                    style={{
                                        cursor: 'grabbing'
                                    }}
                                />
                            ) : null}
                        </DragOverlay>
                        <MetaInfo />
                        <Collapse
                            size="small"
                            style={{ width: '100%' }}
                            bordered={false}
                            items={[
                                {
                                    key: 'danger',
                                    label: 'Danger Zone',
                                    children: (
                                        <Button
                                            danger
                                            type="primary"
                                            icon={<DeleteOutlined />}
                                            onClick={() => setIsDeleteModalOpen(true)}
                                            loading={isDeleting}
                                            disabled={!selectedStructureId}
                                        >
                                            Delete {type === 'form' ? 'Form' : 'Record'} Structure
                                        </Button>
                                    ),
                                }
                            ]}
                        />
                        <Modal
                            title={`Delete ${type === 'form' ? 'Form' : 'Record'} Type`}
                            open={isDeleteModalOpen}
                            onOk={handleDelete}
                            onCancel={() => setIsDeleteModalOpen(false)}
                            okText="Delete"
                            cancelText="Cancel"
                            okButtonProps={{
                                danger: true,
                                loading: isDeleting
                            }}
                        >
                            <Text>Are you sure you want to delete this {type === 'form' ? 'form' : 'record'} type?</Text>
                            <br />
                            <Text type='danger'>This action cannot be undone.</Text>
                        </Modal>
                    </FlexCol>
                </Splitter.Panel>
                {previewMode !== 'off' && (
                    <Splitter.Panel
                        style={{ paddingLeft: 10 }}
                        min='30%'
                    >
                        {type === 'form' ? (
                            <PreviewFormProvider>
                                <FormUI contentRef={{ current: null }} />
                            </PreviewFormProvider>
                        ) : (
                            <PreviewEntityProvider>
                                <Entity />
                            </PreviewEntityProvider>
                        )}
                    </Splitter.Panel>
                )}
            </Splitter>
        </DndContext>
    );
}; 