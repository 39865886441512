import { FC, useContext } from 'react';
import { Button, Space, Tooltip, theme } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { UIElementType } from '../../types/System.types';
import { LayoutEditorContext } from '../../providers/editor/LayoutEditorProvider';
import { EditorUIElement, EditorUIContainer } from '../../types/System.types';
import { AddElementMenu } from './AddElementMenu';

const { useToken } = theme;

interface LayoutEditorControlsProps {
    element: EditorUIElement;
    parentElement: EditorUIElement | null;
}

export const LayoutEditorControls: FC<LayoutEditorControlsProps> = ({
    element,
    parentElement,
}) => {
    const { token } = useToken();
    const editor = useContext(LayoutEditorContext);
    if (!editor) throw new Error('Editor must be used within LayoutEditorProvider');

    const { removeElement } = editor;
    const isContainer = [UIElementType.Grid, UIElementType.Row, UIElementType.Column].includes(element.uiElementType);
    const isField = element.uiElementType === UIElementType.Field;

    return (
        <Space
            style={{
                position: 'absolute',
                bottom: '50%',
                left: '50%',
                transform: 'translate(-50%, 50%)',
                padding: '2px 5px',
                background: token.colorBgContainer,
                borderRadius: token.borderRadiusLG,
                boxShadow: token.boxShadowTertiary,
                zIndex: 1
            }}
        >
            {isContainer && (
                <AddElementMenu
                    size="small"
                    variant='borderless'
                    style={{ width: 120 }}
                />
            )}
            <Tooltip title={`Remove ${isContainer ? 'container' : (isField ? 'field' : 'reference')}`}>
                <Button
                    type="text"
                    size="small"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (parentElement) {
                            removeElement(parentElement as EditorUIContainer, element.dndId);
                        }
                    }}
                />
            </Tooltip>
        </Space>
    );
};

export default LayoutEditorControls; 