import { FC, useContext, useEffect } from 'react';
import { Typography, theme } from 'antd';
import { HolderOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';

import {
    UIElementType,
    UIField,
    UIEntityReference,
    fieldType,
    UIMetaField,
    EditorUIElement,
    EditorUIContainer
} from '../../types/System.types';
import { LayoutEditorContext } from '../../providers/editor/LayoutEditorProvider';
import { LayoutEditorControls } from './LayoutEditorControls';

const { Text } = Typography;
const { useToken } = theme;

/**
 * Props for the LayoutEditorElement component
 */
interface LayoutEditorElementProps {
    /** The UI element to render */
    element: EditorUIElement;
    /** The parent element containing this element, null if root */
    parentElement: EditorUIElement | null;
    /** Whether this element is currently selected */
    isSelected?: boolean;
    /** Callback when this element is selected */
    onSelect?: () => void;
    /** Additional CSS styles to apply */
    style?: React.CSSProperties;
}

/**
 * A draggable and configurable UI element in the layout editor.
 * Supports containers (Grid, Row, Column) and leaf elements (Field, MetaField, EntityReference).
 * Handles drag and drop, selection, and keyboard interactions.
 * @component
 */
export const LayoutEditorElement: FC<LayoutEditorElementProps> = ({
    element,
    parentElement,
    isSelected,
    onSelect,
    style,
}) => {
    const { token } = useToken();
    const editor = useContext(LayoutEditorContext);
    if (!editor) throw new Error('Editor must be used within LayoutEditorProvider');

    const { removeElement, selectElement } = editor;
    const isContainer = [UIElementType.Grid, UIElementType.Row, UIElementType.Column].includes(element.uiElementType);
    const isColumn = element.uiElementType === UIElementType.Column;
    const isField = element.uiElementType === UIElementType.Field;
    const isMeta = element.uiElementType === UIElementType.MetaField;

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (!isSelected) return;

            if (['Delete', 'Backspace', 'Enter', ' '].includes(e.key)) {
                e.preventDefault();
            }

            switch (e.key) {
                case 'Escape':
                    selectElement(null);
                    break;
                case 'Delete':
                case 'Backspace':
                    if (parentElement) {
                        removeElement(parentElement as EditorUIContainer, element.dndId);
                    }
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [editor, isSelected, parentElement, element.dndId, removeElement, selectElement]);

    const currentIndex = parentElement ? parentElement.children?.indexOf(element) : -1;

    const opacity = '25';
    const editorColors = {
        container: {
            border: token.green,
            background: token.green + opacity,
            selectedBorder: token.magenta,
            selectedBackground: token.magenta + opacity,
        },
        field: {
            border: token.blue,
            background: token.blue + opacity,
            selectedBorder: token.magenta,
            selectedBackground: token.magenta + opacity,
        },
        entity: {
            border: token.gold,
            background: token.gold + opacity,
            selectedBorder: token.magenta,
            selectedBackground: token.magenta + opacity,
        },
        meta: {
            border: token.purple,
            background: token.purple + opacity,
            selectedBorder: token.magenta,
            selectedBackground: token.magenta + opacity,
        }
    };

    const {
        attributes,
        listeners,
        setNodeRef,
        transition,
        isDragging,
        isOver,
    } = useSortable({
        id: element.dndId,
        data: {
            type: element.uiElementType,
            element,
            sourceIndex: currentIndex,
            container: parentElement,
            isContainer
        },
        disabled: parentElement === null
    });

    const label = isField
        ? fieldType[(element as UIField).fieldTypeId]?.description?.shortLabel
        : (element as UIEntityReference).entityId;
    const type = isField 
        ? fieldType[(element as UIField).fieldTypeId]?.fieldDataTypeId 
        : isMeta
            ? 'meta'
            : 'entity';

    const elementStyle = {
        display: 'flex',
        flexDirection: isContainer ? (isColumn ? 'column' : 'row') : 'row',
        justifyContent: isContainer ? undefined : 'flex-start',
        alignItems: isContainer ? 'flex-start' : 'center',
        flexGrow: isContainer ? 0 : 1,
        alignSelf: 'stretch',
        gap: 10,
        padding: isContainer ? '20px 20px 10px 10px' : '5px 5px 5px 10px',
        borderRadius: token.borderRadiusLG,
        minHeight: isContainer ? 50 : 30,
        minWidth: 120,
        transition,
        position: 'relative',
        cursor: isDragging ? 'grabbing' : 'grab',
        border: `1px ${isContainer ? 'dotted' : 'dashed'} ${
            isSelected
                ? editorColors[isContainer ? 'container' : (isMeta ? 'meta' : (isField ? 'field' : 'entity'))].selectedBorder
                : editorColors[isContainer ? 'container' : (isMeta ? 'meta' : (isField ? 'field' : 'entity'))].border
        }`,
        backgroundColor: isSelected
            ? editorColors[isContainer ? 'container' : (isMeta ? 'meta' : (isField ? 'field' : 'entity'))].selectedBackground
            : isOver
                ? token.colorSuccess + '33'
                : editorColors[isContainer ? 'container' : (isMeta ? 'meta' : (isField ? 'field' : 'entity'))].background,
        outline: isOver ? `2px dashed ${token.colorSuccess}` : 'none',
        ...style,
        ...(element.props?.style || {})
    };

    const renderChildren = () => {
        if (isContainer) {
            const container = element as EditorUIElement;
            const childIds = container.children?.map((child: EditorUIElement) => child.dndId) || [];

            return (
                <SortableContext items={childIds} strategy={rectSortingStrategy}>
                    {container.children?.map((child) => (
                        <LayoutEditorElement
                            key={child.dndId}
                            element={child}
                            parentElement={container}
                            isSelected={editor.selectedElement === child}
                            onSelect={() => selectElement(child)}
                        />
                    ))}
                </SortableContext>
            );
        }
    };

    return (
        <div
            ref={setNodeRef}
            id={element.dndId}
            style={elementStyle}
            onClick={(e) => {
                e.stopPropagation();
                onSelect?.();
            }}
            {...attributes}
            {...listeners}
        >
            {isContainer && (
                <Text type="secondary" style={{
                    fontSize: token.fontSizeSM,
                    position: 'absolute',
                    left: 10,
                    top: -2
                }}>
                    <small>{element.uiElementType.toUpperCase()}</small>
                </Text>
            )}
            {isSelected && <LayoutEditorControls element={element} parentElement={parentElement} />}
            {!isContainer && (
                <Text>
                    {isMeta ? (
                        <>
                            {(element as UIMetaField).metaFieldId}
                            <InfoCircleOutlined style={{ 
                                marginLeft: 8,
                                color: token.purple 
                            }} />
                        </>
                    ) : (
                        <>
                            {label}&nbsp;
                            <Text type="secondary" style={{ fontSize: token.fontSizeSM }}>
                                {type}
                            </Text>
                        </>
                    )}
                </Text>
            )}
            {renderChildren()}
            <HolderOutlined
                style={{
                    color: editorColors[isContainer ? 'container' : (isMeta ? 'meta' : (isField ? 'field' : 'entity'))].border,
                    position: 'absolute',
                    right: 2,
                    top: '50%',
                    transform: 'translateY(-50%)',
                }}
            />
        </div>
    );
}; 