import {
    useState,
    useEffect,
    useContext,
    useMemo
} from 'react';
import {
    doc,
    onSnapshot,
} from 'firebase/firestore';

import { DataContext } from '../../providers/DataProvider';
import { DocDataWithId } from '../../types/System.types';

export interface UseBoundDocsOptions {
    paths: string[];
    enabled?: boolean;
}

export interface UseBoundDocs<T extends DocDataWithId = DocDataWithId> {
    /** The bound documents data */
    data: T[];
    /** Any error that occurred during binding */
    error: Error | null;
    /** Indicates if any documents are currently loading */
    loading: boolean;
}

/**
 * Hook for binding to multiple Firestore documents with real-time updates
 */
export const useBoundDocs = <T extends DocDataWithId = DocDataWithId>({ 
    paths,
    enabled = true 
}: UseBoundDocsOptions): UseBoundDocs<T> => {
    const { firestore } = useContext(DataContext);
    const [data, setData] = useState<T[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [loading, setLoading] = useState(true);

    const memoizedPaths = useMemo(() => paths, [paths.join(',')]);

    useEffect(() => {
        if (!enabled || memoizedPaths.length === 0) {
            setLoading(false);
            setData([]);
            return;
        }

        setData(new Array(memoizedPaths.length).fill(null));
        
        const docRefs = memoizedPaths.map(path => doc(firestore, path));
        const unsubscribes = docRefs.map((docRef, index) => 
            onSnapshot(
                docRef,
                (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        setData(prevData => {
                            const newData = [...prevData];
                            newData[index] = { 
                                docId: docSnapshot.id, 
                                ...docSnapshot.data() 
                            } as T;
                            return newData;
                        });
                    }
                    setData(prevData => {
                        if (prevData.every(item => item !== null)) {
                            setLoading(false);
                        }
                        return prevData;
                    });
                },
                (error) => {
                    console.error(`Failed to bind document: ${error} | path: ${memoizedPaths[index]}`);
                    setError(error);
                    setLoading(false);
                }
            )
        );

        return () => {
            unsubscribes.forEach(unsubscribe => unsubscribe());
        };
    }, [enabled, memoizedPaths, firestore]);

    return {
        data: data.filter(Boolean) as T[],
        error,
        loading
    };
}; 