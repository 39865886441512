import { TableColumnType, EventCategory } from '../../types/System.types';
import { calculateRow } from '../../components/rendering/tables/formulaCalculator';
import { logEvent, runOptimizationCalculation } from '../../services/optimizationFunctions';
import { FormulaContext } from '../useFormulaParser';
import { useCallback, useState } from 'react';

/**
 * Interface for optimization calculation request parameters
 */
export interface CalculateOptimizationRequest {
    data: Record<string, any>[];
    targetReduction: number;
}

/**
 * Interface for optimization model metadata
 */
export interface OptimizationModelMeta {
    success: boolean;
    status: string;
    message: string;
    nIterations: number;
    nFuncEvals: number;
    nGradEvals: number;
    finalObjectiveValue: number;
    targetReduction?: number;
    actualReduction?: number;
}

/**
 * Interface for optimization calculation results
 */
export interface OptimizationResult {
    markdownPercents: number[];
    modelConverged: boolean;
    modelMeta: OptimizationModelMeta;
}

/**
 * Interface representing a row in the optimization table
 */
export interface OptimizationRow {
    index: number;
    extBidPrice: number;
    oBasePrice: number;
    oBaseComm: number;
    overageComm: number;
    cutPrice: number;
    cutComm: number;
    quantity: number;
    distMarkdown: number;
}

/**
 * Interface for optimization event data to be logged
 */
export interface OptimizationEventPayload {
    originalMarkdowns: number[];
    newMarkdowns: number[];
    modelMeta: OptimizationModelMeta;
    userId?: string;
}

/**
 * Required fields for optimization with their descriptions
 */
export const optimizationRequiredFields = {
    extBidPrice: 'Used for price calculations',
    oBasePrice: 'Base price for commission calculations',
    oBaseComm: 'Base commission percentage',
    overageComm: 'Overage commission percentage',
    cutPrice: 'Cut price threshold',
    cutComm: 'Cut commission percentage',
    quantity: 'Needed for extended price calculations'
} as const;

export type OptimizationRequiredField = keyof typeof optimizationRequiredFields;

/**
 * Creates an error optimization result with the given message
 */
const createErrorResult = (message: string): OptimizationResult => ({
    markdownPercents: [],
    modelConverged: false,
    modelMeta: {
        success: false,
        status: 'error',
        message,
        nIterations: 0,
        nFuncEvals: 0,
        nGradEvals: 0,
        finalObjectiveValue: 0
    }
});

/**
 * Processes a single row with the new markdown percentage and recalculates formulas
 */
const processOptimizedRow = (
    row: Record<string, any>,
    markdown: number,
    columns: TableColumnType[],
    evaluateFormula?: (formula: string, context: FormulaContext) => number | null
): Record<string, any> => {
    const updatedRow = { ...row, distMarkdown: markdown };
    
    const formulaColumns = columns.filter(col => col.formula);
    return formulaColumns.length > 0 && evaluateFormula
        ? calculateRow(updatedRow, formulaColumns, evaluateFormula)
        : updatedRow;
};

/**
 * Validates required fields in optimization data
 */
const validateOptimizationFields = (
    data: Record<string, any>[],
    index: number
): string[] => {
    return Object.keys(optimizationRequiredFields).reduce<string[]>((missing, field) => {
        const value = data[index][field];
        if (value === undefined || value === null || isNaN(value)) {
            missing.push(`Row ${index} missing or has invalid ${field}`);
        }
        return missing;
    }, []);
};

/**
 * Hook for table optimization functionality
 */
export const useTableOptimizer = () => {
    const [isOptimizing, setIsOptimizing] = useState(false);
    const [previousState, setPreviousState] = useState<any[] | null>(null);

    const prepareOptimizationData = useCallback((
        data: Record<string, any>[],
        targetReduction: number
    ) => {
        if (!data?.length) {
            throw new Error('Data array is empty or invalid');
        }

        if (typeof targetReduction !== 'number' || targetReduction <= 0) {
            throw new Error(`Target reduction must be a positive number, received: ${targetReduction}`);
        }

        const missingFields = data.reduce<string[]>((missing, _, index) => 
            [...missing, ...validateOptimizationFields(data, index)], []);

        if (missingFields.length) {
            throw new Error(`Invalid data: ${missingFields.join(', ')}`);
        }

        const originalMarkdowns = data.map(row => row.distMarkdown || 0);
        const originalData = data.map((row, index): OptimizationRow => ({
            index,
            extBidPrice: Number(row.extBidPrice),
            oBasePrice: Number(row.oBasePrice),
            oBaseComm: Number(row.oBaseComm),
            overageComm: Number(row.overageComm),
            cutPrice: Number(row.cutPrice),
            cutComm: Number(row.cutComm),
            quantity: Number(row.quantity),
            distMarkdown: Number(row.distMarkdown) || 0
        }));

        return { originalData, originalMarkdowns };
    }, []);

    const processOptimizationResults = async (
        result: OptimizationResult,
        originalData: Record<string, any>[],
        originalMarkdowns: number[],
        userId?: string
    ): Promise<Record<string, any>[]> => {
        const { markdownPercents: newMarkdowns, modelMeta } = result;

        if (!newMarkdowns || !modelMeta?.success) {
            throw new Error('Invalid or unsuccessful optimization result');
        }

        // Process the results
        const optimizedData = originalData.map((row, index) => ({
            ...row,
            markdownPercent: newMarkdowns[index]
        }));

        // Log the event using the cloud function
        try {
            await logEvent({
                eventCategory: EventCategory.MeteredEvent,
                description: 'Commissions optimization calculation performed',
                userId,
                changedValues: {
                    distMarkdown: {
                        oldValue: `${originalMarkdowns}`,
                        newValue: `${newMarkdowns}`
                    }
                },
                meta: {
                    additionalMeta: modelMeta
                }
            });
        } catch (error) {
            console.error('Failed to log optimization event:', error);
        }

        return optimizedData;
    };

    const logOptimizationError = async (error: unknown, config: CalculateOptimizationRequest, userId?: string) => {
        const errorMessage = error instanceof Error ? error.message : 'Error occurred during optimization';
        
        try {
            await logEvent({
                eventCategory: EventCategory.Error,
                description: 'Optimization calculation error',
                userId,
                meta: {
                    error: errorMessage,
                    targetReduction: config.targetReduction,
                    actualReduction: 0
                },
                isError: true
            });
        } catch (logError) {
            console.error('Failed to log optimization error:', logError);
        }
        
        return createErrorResult(errorMessage);
    };

    const optimizeTableData = useCallback(async (
        data: Record<string, any>[],
        columns: TableColumnType[],
        config: CalculateOptimizationRequest,
        userId?: string
    ): Promise<OptimizationResult> => {
        setIsOptimizing(true);
        
        try {
            const { originalData, originalMarkdowns } = prepareOptimizationData(data, config.targetReduction);
            
            // Save current state before optimization
            setPreviousState([...data]);
            
            const response = await runOptimizationCalculation({
                data: originalData,
                targetReduction: config.targetReduction
            });
            
            if (!response?.data) {
                throw new Error('No response data from optimization calculation');
            }
            
            const result = response.data as OptimizationResult;
            
            if (!result.markdownPercents?.length || !result.modelMeta?.success) {
                throw new Error(result.modelMeta?.message || 'Invalid optimization result');
            }

            await processOptimizationResults(result, originalData, originalMarkdowns, userId);
            
            return result;
        } catch (error) {
            console.error('useTableOptimizer: Optimization error:', error);
            const errorResult = await logOptimizationError(error, config, userId);
            return errorResult;
        } finally {
            setIsOptimizing(false);
        }
    }, [prepareOptimizationData, setPreviousState, setIsOptimizing]);

    type PriceType = 'cutPrice' | 'oBasePrice';

    interface ReductionResult {
        amount: number;
        percentage: number;
    }

    const calculateMaxReductionFor = (
        data: Record<string, any>[],
        priceType: PriceType
    ): ReductionResult => {
        const total = data.reduce((acc, row) => {
            const extBidPrice = Number(row.extBidPrice) || 0;
            const comparePrice = Number(row[priceType]) || 0;
            const quantity = Number(row.quantity) || 0;
            const extComparePrice = comparePrice * quantity;
            
            return {
                totalDiff: acc.totalDiff + (extBidPrice - extComparePrice),
                totalExtBid: acc.totalExtBid + extBidPrice
            };
        }, { totalDiff: 0, totalExtBid: 0 });

        return {
            amount: Number(total.totalDiff.toFixed(2)),
            percentage: Number(((total.totalDiff / total.totalExtBid) * 100).toFixed(2))
        };
    };

    const calculateMaxReduction = (data: Record<string, any>[]): ReductionResult => 
        calculateMaxReductionFor(data, 'cutPrice');

    const calculateMaxObaseReduction = (data: Record<string, any>[]): ReductionResult => 
        calculateMaxReductionFor(data, 'oBasePrice');

    const handleUndo = useCallback((onChange?: (data: any[]) => void) => {
        if (previousState && onChange) {
            onChange(previousState);
            setPreviousState(null);
        }
    }, [previousState]);

    const clearMarkdown = useCallback((
        data: any[],
        columns: TableColumnType[],
        evaluateFormula?: (formula: string, context: FormulaContext) => number | null,
        onChange?: (data: any[]) => void
    ) => {
        if (!data || !columns || !onChange) return;

        setPreviousState([...data]);

        const clearedData = data.map(row => {
            const updatedRow = { ...row, distMarkdown: 0 };
            return evaluateFormula ? calculateRow(updatedRow, columns, evaluateFormula) : updatedRow;
        });

        onChange(clearedData);
    }, []);

    return {
        optimizeTableData,
        processOptimizedRow,
        prepareOptimizationData,
        calculateMaxReduction,
        calculateMaxObaseReduction,
        isOptimizing,
        previousState,
        handleUndo,
        clearMarkdown
    };
}; 