import { useRef, useCallback } from 'react';
import { Parser } from 'expr-eval';

export interface FormulaContext {
  row: Record<string, any>;
}

export const useFormulaParser = () => {
  const parser = useRef<Parser>();

  const initParser = useCallback(() => {
    if (!parser.current) {
      parser.current = new Parser();
      
      parser.current.functions = {
        abs: Math.abs,
        ceil: Math.ceil,
        floor: Math.floor,
        round: Math.round,
        roundToCents: (value: number) => Math.round(value * 100) / 100,
        roundToNickels: (value: number) => Math.round(value * 20) / 20,
        roundToQuarters: (value: number) => Math.round(value * 4) / 4,
        min: Math.min,
        max: Math.max,
        sqrt: Math.sqrt,
        pow: Math.pow,
        if: (condition: boolean, trueValue: number = 1, falseValue: number = 0) => condition ? trueValue : falseValue,
        // Add other safe math functions as needed
      };
    }
    return parser.current;
  }, []);

  const parseFormula = useCallback((formulaStr: string) => {
    try {
      const parserInstance = initParser();
      return parserInstance.parse(formulaStr);
    } catch (error) {
      console.error('Error parsing formula:', error);
      return null;
    }
  }, [initParser]);

  const evaluateFormula = useCallback((
    formula: string,
    context: FormulaContext
  ): number | null => {
    try {
      if (!formula) return null;

      const parsedFormula = parseFormula(formula);
      if (!parsedFormula) return null;

      const numericFields: Record<string, number> = {};
      for (const [key, value] of Object.entries(context.row)) {
        numericFields[key] = typeof value === 'string' ? 
          Number(value) || 0 : 
          (typeof value === 'number' ? value : 0);
      }

      const result = parsedFormula.evaluate(numericFields);
      return typeof result === 'number' && !isNaN(result) ? result : null;
    } catch (error) {
      console.error('Error evaluating formula:', error);
      return null;
    }
  }, [parseFormula]);

  return {
    parseFormula,
    evaluateFormula
  };
}; 