/**
 * Metrics Settings Component
 * Renders the form for editing a metric's properties.
 */
import { FC, useState, useEffect } from 'react';
import {
    Button,
    Tooltip,
    Select,
    Input,
    Typography,
    Collapse,
    Divider,
    theme,
} from 'antd';
import { Timestamp } from 'firebase/firestore';

import FlexBox from '../../atoms/FlexBox';
import FlexCol from '../../atoms/FlexCol';
import Icon from '../../atoms/Icon';
import { MAX_LIMITED_WIDTH_VIEWPORT_NARROW, SPACING, MetricsEditorUIDescription } from '../../../types/System.Parameters.types';
import { useFormTypes } from '../../../use/useFormTypes';
import { useAnalyticsEditor } from '../../../use/useAnalyticsEditor';
import {
    Metric,
    TimePeriod,
    FrequencyOfCalculation,
    StatisticType,
    FieldTypeId
} from '../../../types/System.types';
import { runFullMetricRecalculation } from '../../../services/optimizationFunctions';

const { Text } = Typography;
const { useToken } = theme;
const { TextArea } = Input;

/** Props for the MetricsSettings component */
interface MetricsSettingsProps {
    /** The metric being edited */
    metric: Metric;
    /** Callback for metric changes */
    onMetricChange: (field: keyof Metric, value: any) => void;
    /** Whether the metric is in edit mode */
    isEditMode: boolean;
    /** Callback for archiving the metric */
    onArchive: () => void;
    /** Message API for notifications */
    messageApi: any;
}

/**
 * Metrics Settings Component
 * Renders the form for editing a metric's properties.
 */
const MetricsSettings: FC<MetricsSettingsProps> = ({
    metric,
    onMetricChange,
    isEditMode,
    onArchive,
    messageApi
}) => {
    const { token } = useToken();
    const [localDescription, setLocalDescription] = useState(metric.description);
    const { data: formTypes, loading: formTypesLoading } = useFormTypes();
    const {
        entities,
        entitiesLoading,
        getFieldOptions,
        getStatusBadge
    } = useAnalyticsEditor();
    const selectedFormType = formTypes?.find(ft => ft.docId === metric.source?.parentFormType);
    const [recalculationLoading, setRecalculationLoading] = useState(false);

    const handleSingleMetricRecalculation = async () => {
        setRecalculationLoading(true);
        try {
            await runFullMetricRecalculation();
            messageApi.success(`Metric "${metric.description?.shortLabel || 'selected'}" has been recalculated successfully`);
        } catch (error) {
            console.error('Error during metric recalculation:', error);
            messageApi.error('Failed to recalculate metric');
        } finally {
            setRecalculationLoading(false);
        }
    };

    const handleFullRecalculation = async () => {
        setRecalculationLoading(true);
        try {
            await runFullMetricRecalculation();
            messageApi.success('All metrics have been recalculated successfully');
        } catch (error) {
            console.error('Error during full metric recalculation:', error);
            messageApi.error('Failed to complete full metric recalculation');
        } finally {
            setRecalculationLoading(false);
        }
    };

    // Get field options using the hook function
    const { timeFields, valueFields, stateOptions } = getFieldOptions(selectedFormType);

    const handleSourceChange = (field: string, newValue: any) => {
        const updatedSource = {
            ...metric.source,
            [field]: newValue
        };

        // Clear dependent fields when form type changes
        if (field === 'parentFormType') {
            updatedSource.formVersion = [];
            updatedSource.timeField = '' as FieldTypeId;
            updatedSource.valueField = '' as FieldTypeId;
        }

        onMetricChange('source', updatedSource);
    };

    // Update local state when metric changes
    useEffect(() => {
        setLocalDescription(metric.description);
    }, [metric.description]);

    const handleDescriptionBlur = () => {
        if (localDescription !== metric.description) {
            onMetricChange('description', localDescription);
        }
    };

    const handleTimeParamsChange = (field: keyof Metric['timeParams'], newValue: any) => {
        onMetricChange('timeParams', {
            ...metric.timeParams,
            [field]: newValue
        });
    };

    return (
        <FlexBox
            column
            alignStart
            justifyStart
            stretch
            style={{ maxWidth: MAX_LIMITED_WIDTH_VIEWPORT_NARROW }}
            gap={15}
        >
            <FlexCol gap={0} stretch>
                {/* Name and Status Row */}
                <FlexBox stretch wrap style={{ width: '100%' }}>
                    {/* Metric Name */}
                    <FlexCol gap={0} style={{ flex: 2, minWidth: 280 }}>
                        <Tooltip title="A short, descriptive name for the metric">
                            <Text>Metric Name <Text type="secondary">*</Text></Text>
                        </Tooltip>
                        <Input
                            variant='filled'
                            placeholder="Enter metric name"
                            value={localDescription?.shortLabel || ''}
                            onChange={(e) => setLocalDescription({
                                ...localDescription,
                                shortLabel: e.target.value
                            })}
                            onBlur={handleDescriptionBlur}
                            disabled={!isEditMode}
                        />
                    </FlexCol>

                    {/* Status */}
                    <FlexCol gap={0} style={{ flex: 1, minWidth: 280 }}>
                        <Tooltip title="Current status of the metric">
                            <Text>Status</Text>
                        </Tooltip>
                        <Input
                            variant='filled'
                            value={getStatusBadge(metric.status).text}
                            disabled={true}
                            style={{
                                width: '100%',
                                backgroundColor: token.colorFillTertiary,
                                borderLeft: `4px solid ${getStatusBadge(metric.status).status === 'success' ? token.colorSuccess :
                                    getStatusBadge(metric.status).status === 'warning' ? token.colorWarning :
                                        getStatusBadge(metric.status).status === 'error' ? token.colorError :
                                            token.colorPrimary}`
                            }}
                        />
                    </FlexCol>
                </FlexBox>
            </FlexCol>

            {/* Description */}
            <FlexCol gap={0} stretch>
                <Tooltip title="A detailed description of what this metric measures">
                    <Text>Description <Text type="secondary">*</Text></Text>
                </Tooltip>
                <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    variant='filled'
                    placeholder="Enter metric description"
                    value={localDescription?.longDescription || ''}
                    onChange={(e) => setLocalDescription({
                        ...localDescription,
                        longDescription: e.target.value
                    })}
                    onBlur={handleDescriptionBlur}
                    disabled={!isEditMode}
                />
            </FlexCol>

            {/* Source Configuration */}
            {/* Source Form - Full Width */}
            <FlexCol gap={0} noGrow stretch style={{width: '100%'}}>
                <Text>
                    <Tooltip title={MetricsEditorUIDescription.source.parentFormType.longDescription}>
                        {MetricsEditorUIDescription.source.parentFormType.shortLabel} <Text type="secondary">*</Text>
                    </Tooltip>
                </Text>
                <Select
                    variant='filled'
                    placeholder={formTypesLoading ? "Loading form types..." : "Select a form type"}
                    style={{ width: '100%' }}
                    value={metric.source?.parentFormType}
                    onChange={(value) => handleSourceChange('parentFormType', value)}
                    disabled={!isEditMode || formTypesLoading}
                    options={formTypes?.map(formType => ({
                        value: formType.docId,
                        label: formType.docId
                    }))}
                    loading={formTypesLoading}
                    showSearch
                    filterOption={(input, option) =>
                        (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                    }
                />
            </FlexCol>

            {/* Source Form, Time, and Value Fields */}
            <FlexBox wrap stretch style={{ width: '100%' }}>
                {/* Form Version */}
                <FlexCol gap={0} style={{ width: '200px' }}>
                    <Text>
                        <Tooltip title={MetricsEditorUIDescription.source.formVersion.longDescription}>
                            {MetricsEditorUIDescription.source.formVersion.shortLabel} <Text type="secondary">*</Text>
                        </Tooltip>
                    </Text>
                    <Select
                        mode="multiple"
                        variant='filled'
                        placeholder={selectedFormType ? "Select form states" : "Select a form type first"}
                        style={{ width: '100%' }}
                        value={metric.source?.formVersion}
                        onChange={(value) => handleSourceChange('formVersion', value)}
                        disabled={!isEditMode || !selectedFormType}
                        options={stateOptions}
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </FlexCol>

                {/* Time Field */}
                <FlexCol gap={0} style={{ width: '200px' }}>
                    <Text>
                        <Tooltip title={MetricsEditorUIDescription.source.timeField.longDescription}>
                            {MetricsEditorUIDescription.source.timeField.shortLabel} <Text type="secondary">*</Text>
                        </Tooltip>
                    </Text>
                    <Select
                        variant='filled'
                        placeholder={selectedFormType ? "Select a time field" : "Select a form type first"}
                        style={{ width: '100%' }}
                        value={metric.source?.timeField}
                        onChange={(value) => handleSourceChange('timeField', value)}
                        disabled={!isEditMode || !selectedFormType}
                        options={timeFields}
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </FlexCol>

                {/* Value Field */}
                <FlexCol gap={0} style={{ width: '200px' }}>
                    <Text>
                        <Tooltip title={MetricsEditorUIDescription.source.valueField.longDescription}>
                            {MetricsEditorUIDescription.source.valueField.shortLabel} {metric.statistic !== 'count' && <Text type="secondary">*</Text>}
                        </Tooltip>
                    </Text>
                    <Select
                        variant='filled'
                        placeholder={selectedFormType ? "Select a value field" : "Select a form type first"}
                        style={{ width: '100%' }}
                        value={metric.source?.valueField}
                        onChange={(value) => handleSourceChange('valueField', value)}
                        disabled={!isEditMode || !selectedFormType || metric.statistic === 'count'}
                        options={valueFields}
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </FlexCol>
            </FlexBox>

            {/* Metric Configuration */}
            {/* <FlexCol stretch> */}
            <FlexBox wrap stretch style={{ width: '100%' }}>
                {/* Statistic Type */}
                <FlexCol gap={0} style={{ width: '200px' }}>
                    <Text>
                        <Tooltip title="Type of statistical calculation">
                            Statistic <Text type="secondary">*</Text>
                        </Tooltip>
                    </Text>
                    <Select
                        variant='filled'
                        placeholder="Select statistic type"
                        style={{ width: '100%' }}
                        value={metric.statistic}
                        onChange={(value) => {
                            onMetricChange('statistic', value);
                            // Clear valueField if switching to count
                            if (value === 'count') {
                                handleSourceChange('valueField', '');
                            }
                        }}
                        disabled={!isEditMode}
                        options={Object.entries(StatisticType).map(([key, value]) => ({
                            label: key,
                            value: value
                        }))}
                    />
                </FlexCol>

                {/* Time Periods */}
                <FlexCol gap={0} style={{ width: '200px' }}>
                    <Text>
                        <Tooltip title={MetricsEditorUIDescription.timeSettings.timePeriods.longDescription}>
                            {MetricsEditorUIDescription.timeSettings.timePeriods.shortLabel} <Text type="secondary">*</Text>
                        </Tooltip>
                    </Text>
                    <Select
                        mode="multiple"
                        variant='filled'
                        placeholder="Select time periods"
                        style={{ width: '100%' }}
                        value={metric.timeParams?.timePeriods}
                        onChange={(value) => handleTimeParamsChange('timePeriods', value)}
                        disabled={!isEditMode}
                        options={Object.entries(TimePeriod).map(([key, value]) => ({
                            label: key,
                            value: value
                        }))}
                    />
                </FlexCol>

                {/* Group By */}
                <FlexCol gap={0} style={{ width: '200px' }}>
                    <Text>
                        <Tooltip title="Group results by these records">
                            Group By
                        </Tooltip>
                    </Text>
                    <Select
                        mode="multiple"
                        variant='filled'
                        placeholder={entitiesLoading ? "Loading entities..." : "Select records"}
                        style={{ width: '100%' }}
                        value={metric.groupBy?.map(ref => ref.id)}
                        onChange={(value) => onMetricChange('groupBy', value)}
                        disabled={!isEditMode || entitiesLoading}
                        options={entities?.map(entity => ({
                            label: entity.description?.shortLabel || entity.docId,
                            value: entity.docId,
                            description: entity.description?.shortDescription
                        })) || []}
                        loading={entitiesLoading}
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </FlexCol>
            </FlexBox>
            {/* </FlexCol> */}

            <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
            {/* Runtime Settings and Danger Zone */}
            <FlexCol stretch style={{paddingBottom: 40}}>
                <Collapse
                    size="small"
                    style={{ width: '100%' }}
                    bordered={false}
                    items={[
                        {
                            key: 'runtime',
                            label: 'Runtime Settings',
                            children: (
                                <FlexCol stretch>
                                    <FlexBox wrap style={{width: '100%'}}>
                                        <FlexCol gap={0} style={{width: '280px'}}>
                                            <Text>
                                                <Tooltip title={MetricsEditorUIDescription.timeSettings.frequencyOfCalculation.longDescription}>
                                                    {MetricsEditorUIDescription.timeSettings.frequencyOfCalculation.shortLabel} <Text type="secondary">*</Text>
                                                </Tooltip>
                                            </Text>
                                            <Select
                                                variant='filled'
                                                placeholder="Select calculation frequency"
                                                style={{ width: '100%' }}
                                                value={metric.timeParams?.frequencyOfCalculation}
                                                onChange={(value) => handleTimeParamsChange('frequencyOfCalculation', value)}
                                                disabled={!isEditMode}
                                                options={Object.entries(FrequencyOfCalculation).map(([key, value]) => ({
                                                    label: key,
                                                    value: value
                                                }))}
                                            />
                                        </FlexCol>

                                        <FlexCol gap={0} style={{width: '280px'}}>
                                            <Text>
                                                <Tooltip title="First data point to include in calculations">
                                                    First Data Point <Text type="secondary">*</Text>
                                                </Tooltip>
                                            </Text>
                                            <Input
                                                variant='filled'
                                                placeholder="YYYY-MM-DD"
                                                style={{ width: '100%', height: 32 }}
                                                value={metric.timeParams?.startTimeWindow instanceof Timestamp ?
                                                    metric.timeParams.startTimeWindow.toDate().toISOString().split('T')[0] :
                                                    undefined}
                                                onChange={(e) => onMetricChange('timeParams', {
                                                    ...metric.timeParams,
                                                    startTimeWindow: Timestamp.fromDate(new Date(e.target.value))
                                                })}
                                                disabled={!isEditMode}
                                                type="date"
                                            />
                                        </FlexCol>
                                    </FlexBox>
                                    <Tooltip title={isEditMode ? "Save or toggle out of edit mode to enable recalculate this metric" : "Recalculate this metric"}>
                                        <Button
                                            type="link"
                                            icon={<Icon.RefreshCw />}
                                            disabled={isEditMode}
                                            onClick={handleSingleMetricRecalculation}
                                            loading={recalculationLoading}
                                    >
                                            Calculate This Metric Now
                                        </Button>
                                    </Tooltip>
                                </FlexCol>
                            )
                        }
                    ]}
                />
                <Collapse
                    size="small"
                    style={{ width: '100%' }}
                    bordered={false}
                    items={[
                        {
                            key: 'danger',
                            label: 'Danger Zone',
                            children: (
                                <FlexBox wrap alignStart justifyStart>
                                    <Tooltip title={isEditMode ? "Save or toggle out of edit mode to archive this metric" : "Archive this metric"}>
                                        <Button
                                            danger
                                            type="primary"
                                            icon={<Icon.Trash />}
                                            disabled={isEditMode}
                                            onClick={onArchive}
                                    >
                                            Archive Metric
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={isEditMode ? "Save or toggle out of edit mode to recalculate all metrics" : "Recalculate all metrics"}>
                                        <Button
                                            type="link"
                                            icon={<Icon.RefreshCw />}
                                            disabled={isEditMode}
                                            onClick={handleFullRecalculation}
                                            loading={recalculationLoading}
                                    >
                                            Recalculate All Metrics
                                        </Button>
                                    </Tooltip>
                                </FlexBox>
                            )
                        }
                    ]}
                />
            </FlexCol>
        </FlexBox>
    );
};

export default MetricsSettings; 