import { useReducer, useContext, useState } from 'react';
import { Active, Over } from '@dnd-kit/core';
import { 
    EditorUIElement, 
    EditorUIContainer, 
    UILayout,
    Description
} from '../../types/System.types';
import { UserContext } from '../../providers/UserProvider';
import { layoutEditorReducer, initialLayoutEditorState } from '../../reducers/layoutEditorReducer';

/**
 * Mode for the layout editor - either editing the layout or editing details
 * @typedef {'Layout' | 'Details'} LayoutEditorMode
 */
export type LayoutEditorMode = 'Layout' | 'Details';

/**
 * Preview mode for the layout editor
 * @typedef {'off' | 'desktop'} PreviewMode
 */
export type PreviewMode = 'off' | 'desktop';

/**
 * Props for the useLayoutEditor hook
 * @interface UseLayoutEditorProps
 * @property {function} [onSave] - Optional callback function to save the layout
 * @property {UILayout} onSave.layout - The layout to save
 * @property {string} onSave.userId - The user ID
 * @property {Description} onSave.description - The layout description
 * @returns {Promise<void>}
 */
interface UseLayoutEditorProps {
    onSave?: (layout: UILayout, description: Description) => Promise<void>;
}

/**
 * Hook for managing the layout editor state and operations
 * This files is the base for the layout editor and is used by the entity and form layout editors `useLayoutEditorEntity` and `useLayoutEditorForm`
 * @param {UseLayoutEditorProps} props - The hook props
 * @returns {Object} Layout editor state and methods
 * @property {UILayout} layout - Current layout state
 * @property {EditorUIElement | null} selectedElement - Currently selected element
 * @property {boolean} isModified - Whether the layout has unsaved changes
 * @property {Description} description - Layout description
 * @property {function} selectElement - Select an element in the editor
 * @property {function} removeElement - Remove an element from a container
 * @property {function} addElement - Add an element to a container
 * @property {function} dropElement - Handle element drop during drag and drop
 * @property {function} undo - Undo last action
 * @property {function} redo - Redo last undone action
 * @property {function} save - Save the current layout, returns a Promise
 * @property {Dispatch<any>} dispatch - Reducer dispatch function
 * @property {LayoutEditorMode} mode - Current editor mode
 * @property {function} setMode - Set editor mode
 * @property {PreviewMode} previewMode - Current preview mode
 * @property {function} setPreviewMode - Set preview mode
 * @property {LayoutEditorState} layoutEditorState - Complete editor state
 */
export const useLayoutEditorBase = ({ onSave }: UseLayoutEditorProps) => {
    const [state, dispatch] = useReducer(layoutEditorReducer, initialLayoutEditorState);
    const [mode, setMode] = useState<LayoutEditorMode>('Layout');
    const [previewMode, setPreviewMode] = useState<PreviewMode>('off');
    const { user } = useContext(UserContext);

    const selectElement = (element: EditorUIElement | null) => {
        dispatch({ type: 'SELECT_ELEMENT', payload: element });
    };

    const removeElement = (
        container: EditorUIContainer, 
        elementDndId: string
    ) => {
        dispatch({ 
            type: 'REMOVE_ELEMENT', 
            payload: { container, elementDndId } 
        });
    };

    const addElement = (
        element: EditorUIElement, 
        container: EditorUIContainer
    ) => {
        dispatch({ 
            type: 'ADD_ELEMENT', 
            payload: { element, container } 
        });
    };

    const dropElement = (active: Active, over: Over) => {
        dispatch({ 
            type: 'DROP_ELEMENT', 
            payload: { active, over } 
        });
    };

    const undo = () => {
        dispatch({ type: 'UNDO' });
    };

    const redo = () => {
        dispatch({ type: 'REDO' });
    };

    const save = async () => {
        if (!user?.uid) {
            throw new Error('User not found');
        }
        await onSave?.({
            ...state.layout,
        }, state.description);
        dispatch({ type: 'SAVE_COMPLETE' });
    };

    return {
        layout: state.layout,
        selectedElement: state.selectedElement,
        isModified: state.history.past.length > 0,
        description: state.description,
        selectElement,
        removeElement,
        addElement,
        dropElement,
        undo,
        redo,
        save,
        dispatch,
        mode,
        setMode,
        previewMode,
        setPreviewMode,
        user,
        layoutEditorState: state
    };
}; 