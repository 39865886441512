import React, { createContext, useEffect, ReactNode, useContext, useState, useCallback } from 'react';
import useFirebaseAuth from '../use/useFirebaseAuth';
import { H } from 'highlight.run';
import { User } from 'firebase/auth';
import { loadSpace, InitOptions } from '@usersnap/browser';
import { collection, getDocs, doc, runTransaction, arrayRemove, arrayUnion } from 'firebase/firestore';
import { DataContext } from './DataProvider';

import { 
    usersnapSpaceId,
    highlightId,
    environmentType,
    firebaseConfig,
} from '../config';
import { useBoundDoc } from '../use/data/useBoundDoc';
import { useSet } from '../use/data/useSet';
import { UserDBRole, Notification, DocDataWithId } from '../types/System.types';
import { Group } from '../types/System.types';
import { useBoundCollection } from '../use/data/useBoundCollection';

/** Settings for user preferences and notifications */
interface Settings {
    /** System theme preference */
    preferDarkMode?: string;
    /** User role in the system */
    role?: string;
    /** Current organization context */
    currentOrganization?: string;
    /** Unread notifications for the user */
    unreadNotifications?: Notification[];
    /** Read notifications for the user */
    readNotifications?: Notification[];
}

/** Context type providing user authentication and management functionality */
export interface UserContextType {
    /** Current authenticated user */
    user: User | null;
    /** Loading state for auth operations */
    loading: boolean;
    /** Login with email and password */
    login: (email: string, password: string) => Promise<void>;
    /** Create new user account */
    signup: (email: string, password: string) => Promise<void>;
    /** Log out current user */
    logout: () => Promise<void>;
    /** Get Firebase ID token for current user */
    fetchIdToken: () => Promise<string | null>;
    /** Whether a user is currently logged in */
    isLoggedIn: boolean;
    /** User settings and preferences */
    settings: Settings | null;
    /** Update user settings */
    updateSettings: (newSettings: Settings) => Promise<void>;
    /** User's roles in different groups */
    userGroups: { [groupId: string]: UserDBRole };
    /** Available groups in the system */
    groups: Group[];
    /** Unread notifications */
    unreadNotifications?: Notification[];
    /** Read notifications */
    readNotifications?: Notification[];
    /** Toggle notification read status */
    toggleNotificationRead: (notification: Notification) => Promise<void>;
    /** Subscribe to form notifications */
    subscribeToForm: (formId: string) => Promise<void>;
    /** Unsubscribe from form notifications */
    unsubscribeFromForm: (formId: string) => Promise<void>;
    /** Subscribe to entity record notifications */
    subscribeToEntityRecord: (entityId: string, recordId: string) => Promise<void>;
    /** Unsubscribe from entity record notifications */
    unsubscribeFromEntityRecord: (entityId: string, recordId: string) => Promise<void>;
    /** List of all users in the system */
    users: DocDataWithId[];
    /** Assign a form to a user */
    assignFormToUser: (formId: string, userId?: string | null) => Promise<void>;
    /** Update form notification subscribers */
    updateFormSubscribers: (formId: string, userIds: string[]) => Promise<void>;
}

/** Props for UserProvider component */
interface UserProviderProps {
    /** Child components */
    children: ReactNode;
}

/** Default context value with empty implementations */
const defaultUserContextValue: UserContextType = {
    user: null,
    loading: false,
    login: async () => { },
    signup: async () => { },
    logout: async () => { },
    fetchIdToken: async () => null,
    isLoggedIn: false,
    settings: {
        preferDarkMode: 'system',
    },
    updateSettings: async () => { },
    userGroups: {},
    groups: [],
    unreadNotifications: undefined,
    toggleNotificationRead: async () => {},
    subscribeToForm: async () => {},
    unsubscribeFromForm: async () => {},
    subscribeToEntityRecord: async () => {},
    unsubscribeFromEntityRecord: async () => {},
    users: [],
    assignFormToUser: async () => {},
    updateFormSubscribers: async () => {},
};

/** Context for user authentication and management */
export const UserContext = createContext<UserContextType>(defaultUserContextValue);

interface UserProviderProps {
    children: ReactNode;
}

if (window.location.hostname.split(':')[0] !== 'localhost') {
    const h = H.init(highlightId, {
        serviceName: 'Syncretic System',
        tracingOrigins: true,
        manualStart: true,
        networkRecording: {
            enabled: true,
            recordHeadersAndBody: true,
        },
    });
    console.log(`H Analytics:`, h?.sessionSecureID);
} else {
    console.log('H Analytics disabled.');
}

/** Provider component for user authentication and management */
export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const { set } = useSet();
    const { firestore } = useContext(DataContext);
    const { user, loading, login, signup, logout, fetchIdToken } = useFirebaseAuth();

    const settings = useBoundDoc<Settings>({ 
        path: 'users', 
        docId: user?.uid, 
        enabled: !!user 
    });

    const [userGroups, setUserGroups] = useState<{ [groupId: string]: UserDBRole }>({});
    const [groups, setGroups] = useState<Group[]>([]);

    const users = useBoundCollection({
        path: 'users',
        enabled: true
    });

    useEffect(() => {
        const fetchGroups = async () => {
            if (!user?.uid) return;

            const groupRoles: { [groupId: string]: UserDBRole } = {};
            const groupsRef = collection(firestore, 'groups');
            const groupsSnap = await getDocs(groupsRef);
            const fetchedGroups: Group[] = [];

            for (const groupDoc of groupsSnap.docs) {
                const groupData = groupDoc.data() as Group;
                fetchedGroups.push({ ...groupData, docId: groupDoc.id });

                const membersRef = collection(firestore, `groups/${groupDoc.id}/groupMembers`);
                const membersSnap = await getDocs(membersRef);
                const userMember = membersSnap.docs.find(doc => doc.id === user.uid);
                
                if (userMember?.data()?.role) {
                    groupRoles[groupDoc.id] = userMember.data().role;
                }
            }

            setGroups(fetchedGroups);
            setUserGroups(groupRoles);
        };

        fetchGroups();
    }, [firestore, user?.uid]);

    useEffect(() => {
        if (user && user.email) {
            if (window.location.hostname.split(':')[0] !== 'localhost') {
                H.identify(user.email, {
                    id: user.uid,
                    highlightDisplayName: user.email,
                });
                H.start()
                console.log(`H Analytics Started, user:`, user.uid, user.email);
            } else {
                console.log('H Analytics disabled (user).');
            }
    
            loadSpace(usersnapSpaceId).then((api) => {
              api.init({
                user: {
                    email: user?.email,
                    userId: user?.uid,
                },
                custom: {
                    displayName: user?.displayName,
                    userId: user?.uid,
                    email: user?.email,
                    firebaseProjectId: firebaseConfig.projectId,
                    environmentType,
                },
              } as InitOptions);
              console.log('Usersnap API initialized');
            }).catch((error) => {
              console.error('Failed to initialize Usersnap API', error);
            });
        }
    }, [user]);

    const updateSettings = async (newSettings: Settings) => {
        if (user) {
            await set('users', user.uid, newSettings);
        }
    };

    /** Toggle read status of a notification */
    const toggleNotificationRead = useCallback(async (notification: Notification) => {
        if (!user?.uid) {
            throw new Error('Authentication required');
        }

        if (!notification?.notificationEvent?.docId) {
            throw new Error('Invalid notification data');
        }
        
        try {
            await runTransaction(firestore, async (transaction) => {
                const userRef = doc(firestore, 'users', user.uid);
                const userDoc = await transaction.get(userRef);
                
                if (!userDoc.exists()) {
                    throw new Error('User document not found');
                }

                const isRead = settings.data?.readNotifications?.some((n: Notification) => 
                    n.notificationEvent.docId === notification.notificationEvent.docId
                );
                
                if (isRead) {
                    transaction.update(userRef, {
                        readNotifications: arrayRemove(notification),
                        unreadNotifications: arrayUnion(notification)
                    });
                } else {
                    transaction.update(userRef, {
                        unreadNotifications: arrayRemove(notification),
                        readNotifications: arrayUnion(notification)
                    });
                }
            });
        } catch (error) {
            console.error('Failed to toggle notification:', error);
            if (error instanceof Error) {
                throw new Error(`Failed to toggle notification: ${error.message}`);
            }
            throw new Error('Failed to toggle notification: Unknown error occurred');
        }
    }, [user, firestore, settings.data]);

    /** Subscribe to notifications for a form */
    const subscribeToForm = useCallback(async (formId: string) => {
        if (!user?.uid) {
            throw new Error('Authentication required');
        }

        if (!formId) {
            throw new Error('Form ID is required');
        }
        
        try {
            await runTransaction(firestore, async (transaction) => {
                const formRef = doc(firestore, 'forms', formId);
                const formDoc = await transaction.get(formRef);
                
                if (!formDoc.exists()) {
                    throw new Error(`Form not found: ${formId}`);
                }

                transaction.update(formRef, {
                    notificationsSubscribers: arrayUnion(user.uid)
                });
            });
        } catch (error) {
            console.error('Failed to subscribe to form:', error);
            if (error instanceof Error) {
                throw new Error(`Failed to subscribe to form: ${error.message}`);
            }
            throw new Error('Failed to subscribe to form: Unknown error occurred');
        }
    }, [user, firestore]);

    /** Unsubscribe from notifications for a form */
    const unsubscribeFromForm = useCallback(async (formId: string) => {
        if (!user?.uid) {
            throw new Error('Authentication required');
        }

        if (!formId) {
            throw new Error('Form ID is required');
        }
        
        try {
            await runTransaction(firestore, async (transaction) => {
                const formRef = doc(firestore, 'forms', formId);
                const formDoc = await transaction.get(formRef);
                
                if (!formDoc.exists()) {
                    throw new Error(`Form not found: ${formId}`);
                }

                transaction.update(formRef, {
                    notificationsSubscribers: arrayRemove(user.uid)
                });
            });
        } catch (error) {
            console.error('Failed to unsubscribe from form:', error);
            if (error instanceof Error) {
                throw new Error(`Failed to unsubscribe from form: ${error.message}`);
            }
            throw new Error('Failed to unsubscribe from form: Unknown error occurred');
        }
    }, [user, firestore]);

    /** Subscribe to notifications for an entity record */
    const subscribeToEntityRecord = useCallback(async (entityId: string, recordId: string) => {
        if (!user?.uid) {
            throw new Error('Authentication required');
        }

        if (!entityId || !recordId) {
            throw new Error('Entity ID and Record ID are required');
        }
        
        try {
            await runTransaction(firestore, async (transaction) => {
                const recordRef = doc(firestore, `entities/${entityId}/records`, recordId);
                const recordDoc = await transaction.get(recordRef);
                
                if (!recordDoc.exists()) {
                    throw new Error(`Entity record not found: ${entityId}/${recordId}`);
                }

                transaction.update(recordRef, {
                    notificationsSubscribers: arrayUnion(user.uid)
                });
            });
        } catch (error) {
            console.error('Failed to subscribe to entity record:', error);
            if (error instanceof Error) {
                throw new Error(`Failed to subscribe to entity record: ${error.message}`);
            }
            throw new Error('Failed to subscribe to entity record: Unknown error occurred');
        }
    }, [user, firestore]);

    /** Unsubscribe from notifications for an entity record */
    const unsubscribeFromEntityRecord = useCallback(async (entityId: string, recordId: string) => {
        if (!user?.uid) {
            throw new Error('Authentication required');
        }

        if (!entityId || !recordId) {
            throw new Error('Entity ID and Record ID are required');
        }
        
        try {
            await runTransaction(firestore, async (transaction) => {
                const recordRef = doc(firestore, `entities/${entityId}/records`, recordId);
                const recordDoc = await transaction.get(recordRef);
                
                if (!recordDoc.exists()) {
                    throw new Error(`Entity record not found: ${entityId}/${recordId}`);
                }

                transaction.update(recordRef, {
                    notificationsSubscribers: arrayRemove(user.uid)
                });
            });
        } catch (error) {
            console.error('Failed to unsubscribe from entity record:', error);
            if (error instanceof Error) {
                throw new Error(`Failed to unsubscribe from entity record: ${error.message}`);
            }
            throw new Error('Failed to unsubscribe from entity record: Unknown error occurred');
        }
    }, [user, firestore]);

    /** Assign a form to a user or clear assignment */
    const assignFormToUser = useCallback(async (formId: string, userId?: string | null) => {
        if (!user?.uid) {
            throw new Error('Authentication required');
        }
        
        if (!formId) {
            throw new Error('Form ID is required');
        }

        try {
            await runTransaction(firestore, async (transaction) => {
                const formRef = doc(firestore, 'forms', formId);
                const formDoc = await transaction.get(formRef);
                
                if (!formDoc.exists()) {
                    throw new Error(`Form not found: ${formId}`);
                }

                const assigneeId = userId === undefined ? user.uid : userId;
                
                if (assigneeId !== null) {
                    const userRef = doc(firestore, 'users', assigneeId);
                    const userDoc = await transaction.get(userRef);
                    if (!userDoc.exists()) {
                        console.error(`Target user not found: ${assigneeId}`);
                        throw new Error(`Target user not found: ${assigneeId}`);
                    }
                }

                transaction.update(formRef, {
                    assignedTo: assigneeId,
                    meta: {
                        lastModified: new Date(),
                        userId: user.uid,
                    }
                });
            });
        } catch (error) {
            console.error('Failed to assign form:', error);
            if (error instanceof Error) {
                throw new Error(`Failed to assign form: ${error.message}`);
            }
            throw new Error('Failed to assign form: Unknown error occurred');
        }
    }, [user, firestore]);

    /** Update form notification subscribers */
    const updateFormSubscribers = useCallback(async (formId: string, userIds: string[]) => {
        if (!user?.uid) {
            throw new Error('Authentication required');
        }

        if (!formId) {
            throw new Error('Form ID is required');
        }

        try {
            await runTransaction(firestore, async (transaction) => {
                const formRef = doc(firestore, 'forms', formId);
                const formDoc = await transaction.get(formRef);
                
                if (!formDoc.exists()) {
                    throw new Error(`Form not found: ${formId}`);
                }

                // Validate all user IDs exist
                for (const userId of userIds) {
                    const userRef = doc(firestore, 'users', userId);
                    const userDoc = await transaction.get(userRef);
                    if (!userDoc.exists()) {
                        throw new Error(`User not found: ${userId}`);
                    }
                }

                transaction.update(formRef, {
                    notificationsSubscribers: userIds,
                    meta: {
                        lastModified: new Date(),
                        userId: user.uid,
                    }
                });
            });
        } catch (error) {
            console.error('Failed to update form subscribers:', error);
            if (error instanceof Error) {
                throw new Error(`Failed to update form subscribers: ${error.message}`);
            }
            throw new Error('Failed to update form subscribers: Unknown error occurred');
        }
    }, [user, firestore]);

    /** Combined context value */
    const userValue: UserContextType = {
        user,
        loading,
        login,
        signup,
        logout,
        fetchIdToken,
        isLoggedIn: user !== null,
        settings: settings.data,
        updateSettings,
        userGroups,
        groups,
        unreadNotifications: settings.data?.unreadNotifications ?? [],
        readNotifications: settings.data?.readNotifications ?? [],
        toggleNotificationRead,
        subscribeToForm,
        unsubscribeFromForm,
        subscribeToEntityRecord,
        unsubscribeFromEntityRecord,
        users: users.data ?? [],
        assignFormToUser,
        updateFormSubscribers,
    };

    return (
        <UserContext.Provider value={userValue}>
            {children}
        </UserContext.Provider>
    );
};