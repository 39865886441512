import { FC, CSSProperties } from 'react';
import { Input } from 'antd';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useSearch } from '../../use/useSearch';

interface SearchInputProps {
  placeholder?: string;
  inputStyle?: CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
  autoFocus?: boolean;
}

export const SearchInput: FC<SearchInputProps> = ({
  placeholder = 'Search...',
  inputStyle,
  value,
  onChange,
  autoFocus = false
}) => {
  const { setSearchQuery } = useSearch();

  return (
    <Input
      autoFocus={autoFocus}
      variant='filled'
      style={{
        width: '100%',
        ...inputStyle
      }}
      placeholder={placeholder}
      prefix={<SearchOutlined />}
      value={value}
      onChange={(e) => {
        onChange?.(e.target.value);
        setSearchQuery(e.target.value);
      }}
      allowClear={{ clearIcon: <CloseCircleOutlined /> }}
    />
  );
};