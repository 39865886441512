/**
 * Analytics Editor Tab Component
 * Provides a UI for creating, editing, and managing analytics metrics and reports.
 */

import { FC, useState } from 'react';
import {
  Button,
  Divider,
  Empty,
  Modal,
  message,
  Alert,
  Skeleton,
  Typography,
} from 'antd';

import FlexBox from '../atoms/FlexBox';
import FlexCol from '../atoms/FlexCol';
import { useAnalyticsEditor } from '../../use/useAnalyticsEditor';
import MetricsSettings from './analytics/MetricsSettings';
import AnalyticsEditorToolbar from './analytics/AnalyticsEditorToolbar';

const { Text } = Typography;

/** Type for editor mode selection */
type EditorMode = 'reports' | 'metrics';

/** Type for pending actions in modals */
interface PendingAction {
  type: 'switch' | 'mode' | 'discard';
  payload?: any;
}

/**
 * Analytics Editor Tab Component
 * Main component for the analytics editor interface.
 */
const AnalyticsEditorTab: FC = () => {
  // State management
  const [mode, setMode] = useState<EditorMode>('metrics');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(
    null
  );
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  // Hook usage
  const {
    metrics,
    selectedMetric,
    editedMetric,
    error,
    displayMode,
    hasUnsavedChanges,
    canUndo,
    canRedo,
    selectMetric,
    handleMetricChange,
    setDisplayMode,
    createNewMetric,
    handleDuplicateWithErrorHandling,
    handleArchiveWithErrorHandling,
    handleSaveWithErrorHandling,
    hasAccess,
    getMetricOptions,
    isMetricComplete,
    discardChanges,
    undo,
    redo,
  } = useAnalyticsEditor();

  const handleModalConfirm = async () => {
    if (!pendingAction) return;

    try {
      switch (pendingAction.type) {
        case 'switch':
          if (pendingAction.payload) {
            await selectMetric(pendingAction.payload);
            setDisplayMode('view');
          }
          break;
        case 'mode':
          setMode(pendingAction.payload);
          break;
        case 'discard':
          discardChanges();
          break;
      }
    } catch (error) {
      console.error('Error handling modal confirmation:', error);
      messageApi.error('Failed to complete action');
    } finally {
      setIsModalOpen(false);
      setPendingAction(null);
    }
  };

  const handleDuplicate = async (metricId: string) => {
    try {
      await handleDuplicateWithErrorHandling(metricId);
      messageApi.success('Metric duplicated successfully');
    } catch (error) {
      messageApi.error('Failed to duplicate metric');
    }
  };

  const handleArchive = async () => {
    if (!selectedMetric) return;

    try {
      await handleArchiveWithErrorHandling(selectedMetric);
      messageApi.success('Metric archived successfully');
      setIsArchiveModalOpen(false);
    } catch (error) {
      messageApi.error('Failed to archive metric');
    }
  };

  const handleSave = async () => {
    try {
      await handleSaveWithErrorHandling();
      messageApi.success('Metric saved successfully');
    } catch (error) {
      messageApi.error('Failed to save metric');
    }
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setPendingAction(null);
  };

  const handleModeChange = (value: EditorMode) => {
    if (hasUnsavedChanges) {
      setIsModalOpen(true);
      setPendingAction({ type: 'mode', payload: value });
      return;
    }
    setMode(value);
  };

  const handleItemSelect = (value: string | null) => {
    if (hasUnsavedChanges) {
      setIsModalOpen(true);
      setPendingAction({ type: 'switch', payload: value });
      return;
    }
    if (value) {
      selectMetric(value);
      setDisplayMode('view');
    }
  };

  const handleCreateNewMetric = () => {
    if (hasUnsavedChanges) {
      setIsModalOpen(true);
      setPendingAction({ type: 'switch', payload: null });
      return;
    }
    createNewMetric();
  };

  const metricOptions = getMetricOptions(metrics);

  const getEmptyDescription = (currentMode: EditorMode): string => {
    return `Select a ${
      currentMode === 'reports' ? 'Report' : 'Metric'
    } to begin editing.`;
  };

  if (!metrics) {
    return <Skeleton active />;
  }

  if (metrics && hasAccess === false) {
    return (
      <Alert
        type="info"
        message="Access Denied"
        description="You don't have access to the analytics editor."
      />
    );
  }

  if (error) {
    return (
      <Alert
        type="error"
        message="Error Loading Metrics"
        description={error.message}
        action={<Button onClick={() => window.location.reload()}>Retry</Button>}
      />
    );
  }

  return (
    <FlexCol>
      {contextHolder}
      <AnalyticsEditorToolbar
        mode={mode}
        displayMode={displayMode}
        selectedMetric={selectedMetric}
        editedMetric={editedMetric}
        hasUnsavedChanges={hasUnsavedChanges}
        canUndo={canUndo}
        canRedo={canRedo}
        isMetricComplete={isMetricComplete}
        metricOptions={metricOptions}
        onModeChange={handleModeChange}
        onMetricSelect={handleItemSelect}
        onCreateNewMetric={handleCreateNewMetric}
        onSave={handleSave}
        onDiscard={discardChanges}
        onUndo={undo}
        onRedo={redo}
        onDisplayModeChange={(checked) =>
          setDisplayMode(checked ? 'edit' : 'view')
        }
        onDuplicate={handleDuplicate}
      />

      <Divider style={{ margin: 0 }} />

      <FlexBox column alignStart justifyStart stretch wrap>
        {mode === 'reports' ? (
          <FlexBox stretch justifyCenter>
            <Empty description={getEmptyDescription(mode)} />
          </FlexBox>
        ) : editedMetric ? (
          <MetricsSettings
            metric={editedMetric}
            onMetricChange={handleMetricChange}
            isEditMode={displayMode === 'edit'}
            onArchive={() => setIsArchiveModalOpen(true)}
            messageApi={messageApi}
          />
        ) : (
          <FlexBox stretch justifyCenter>
            <Empty description={getEmptyDescription(mode)} />
          </FlexBox>
        )}
      </FlexBox>

      <Modal
        title="Archive Metric"
        open={isArchiveModalOpen}
        onOk={handleArchive}
        onCancel={() => setIsArchiveModalOpen(false)}
        okText="Archive"
        okButtonProps={{ danger: true }}
      >
        <Text>
          Archiving this metric will:
          <ul>
            <li>Stop all future calculations</li>
            <li>Preserve existing data</li>
            <li>Change status to "Archived"</li>
          </ul>
          Are you sure you want to archive this metric?
        </Text>
      </Modal>

      <Modal
        title="Unsaved Changes"
        open={isModalOpen}
        onOk={handleModalConfirm}
        onCancel={handleModalCancel}
        okText="Switch"
        cancelText="Cancel"
      >
        You have unsaved changes.{' '}
        {pendingAction?.type === 'discard'
          ? 'Discard changes?'
          : 'Proceeding will lose these changes. Continue?'}
      </Modal>
    </FlexCol>
  );
};

export default AnalyticsEditorTab;
