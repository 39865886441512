import { FC, useContext } from 'react';
import { Input, Typography, Empty, Tooltip } from 'antd';
import { MAX_LIMITED_WIDTH_VIEWPORT_NARROW, MaxLengths } from '../../types/System.Parameters.types';
import { WorkflowEditorUIDescription } from '../../types/System.Parameters.types';
import { LayoutEditorContext } from '../../providers/editor/LayoutEditorProvider';
import FlexCol from '../atoms/FlexCol';
import FlexBox from '../atoms/FlexBox';

const { Text } = Typography;
const { TextArea } = Input;

export const LayoutEditorDescription: FC = () => {
    const editor = useContext(LayoutEditorContext);
    if (!editor) throw new Error('Editor must be used within LayoutEditorProvider');
    const { 
        description,
        type,
        structure,
        handleDescriptionChange
    } = editor;

    if (!structure) {
        return (
            <FlexBox stretch justifyCenter>
                <Empty description={`Select a ${type === 'form' ? 'Form' : 'Record'} to begin editing.`} />
            </FlexBox>
        );
    }

    return (
        <FlexCol 
            gap={5} 
            noGrow 
            style={{ maxWidth: MAX_LIMITED_WIDTH_VIEWPORT_NARROW }}
        >
            <FlexCol gap={0} noGrow>
                <Tooltip title={WorkflowEditorUIDescription.shortLabel.longDescription}>
                    <Text>Label</Text>
                </Tooltip>
                <Input
                    variant='filled'
                    maxLength={MaxLengths.shortText}
                    value={description?.shortLabel || ''}
                    onChange={e => handleDescriptionChange('shortLabel', e.target.value)}
                />
            </FlexCol>
            <FlexCol gap={0} noGrow>
                <Tooltip title={WorkflowEditorUIDescription.shortDescription.longDescription}>
                    <Text>Description</Text>
                </Tooltip>
                <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    variant='filled'
                    maxLength={MaxLengths.longText}
                    value={description?.longDescription || ''}
                    onChange={e => handleDescriptionChange('longDescription', e.target.value)}
                />
            </FlexCol>
            <Text type="secondary">
                <small><em>
                    Labels and descriptions are used by the system to classify and identify {type === 'form' ? 'Forms' : 'Records'}.
                </em></small>
            </Text>
        </FlexCol>
    );
}; 