import { useState, useEffect, useContext, useCallback, useReducer } from 'react';
import { doc, Timestamp } from "firebase/firestore";
import { IANAZone } from 'luxon';
import { Modal } from 'antd';

import { useBoundCollection } from './data/useBoundCollection';
import { useBoundDoc } from './data/useBoundDoc';
import { useAdd } from './data/useAdd';
import { 
    Metric, 
    UserDBRole,
    DocId,
    FieldTypeId,
    RecordStatus,
    StatisticType,
    TimePeriod,
    FrequencyOfCalculation,
    AgentType,
    FormStateId,
    DocumentReference,
    Entity,
    FormType,
} from '../types/System.types';
import { 
    defaultTimeZone,
    defaultFrequencyOfCalculation,
    defaultMetricStartTimeWindow
} from '../types/System.Parameters.types';
import { DataContext } from '../providers/DataProvider';
import { UserContext } from '../providers/UserProvider';
import { metricEditorReducer } from '../reducers/metricEditorReducer';

/**
 * Display mode type for the analytics editor
 * @typedef {'view' | 'edit'} DisplayMode
 */
export type DisplayMode = 'view' | 'edit';

/**
 * Interface defining the return type of the analytics editor hook
 * @interface UseAnalyticsEditorReturn
 */
interface UseAnalyticsEditorReturn {
    // Core metric data
    metrics: Metric[] | null;
    selectedMetric: Metric | null;
    editedMetric: Metric | null;
    loading: boolean;
    error: Error | null;
    entities: Entity[] | null;
    entitiesLoading: boolean;

    // UI State
    displayMode: DisplayMode;
    hasUnsavedChanges: boolean;
    modifiedFields: Set<string>;
    isMetricComplete: boolean;

    // Actions
    selectMetric: (metricId: string) => void;
    handleMetricChange: (field: keyof Metric, value: any) => void;
    setDisplayMode: (mode: DisplayMode) => void;
    createNewMetric: () => Promise<void>;
    duplicateMetric: (metricId: string) => Promise<void>;
    saveChanges: () => Promise<void>;
    discardChanges: () => void;
    deleteMetric: () => Promise<void>;
    setMetric: (metric: Metric, options?: { merge: boolean }) => void;
    
    // History
    canUndo: boolean;
    canRedo: boolean;
    undo: () => void;
    redo: () => void;

    showNavigationWarning: () => Promise<boolean>;

    // Business Logic Functions
    getMetricOptions: (metrics: Metric[] | null) => SelectOption[];
    getStatusBadge: (status: RecordStatus) => BadgeProps;
    getFieldOptions: (formType: FormType | undefined) => {
        timeFields: SelectOption[],
        valueFields: SelectOption[],
        stateOptions: SelectOption[]
    };
    archiveMetric: (metric: Metric) => Promise<void>;
    handleMetricValidation: (metric: Metric) => ValidationResult;

    // Add these new methods before the return statement
    handleDuplicateWithErrorHandling: (metricId: string) => Promise<void>;
    handleArchiveWithErrorHandling: (metric: Metric) => Promise<void>;
    handleSaveWithErrorHandling: () => Promise<void>;

    hasAccess: boolean | null;
}

/**
 * Interface for select options used in dropdowns
 * @interface SelectOption
 * @property {string} value - The option value
 * @property {string} label - Display text for the option
 * @property {string} [description] - Optional description of the option
 */
interface SelectOption {
    value: string;
    label: string;
    description?: string;
}

/**
 * Interface for badge properties used in status displays
 * @interface BadgeProps
 * @property {'success' | 'processing' | 'error' | 'default' | 'warning'} status - Visual status of the badge
 * @property {string} text - Text to display in the badge
 */
interface BadgeProps {
    status: 'success' | 'processing' | 'error' | 'default' | 'warning';
    text: string;
}

/**
 * Interface for validation results
 * @interface ValidationResult
 * @property {boolean} isValid - Whether the validation passed
 * @property {string[]} errors - Array of validation error messages
 */
interface ValidationResult {
    isValid: boolean;
    errors: string[];
}

/**
 * Custom hook for managing analytics metric editing functionality
 * Provides state management, validation, and CRUD operations for metrics
 * @returns {UseAnalyticsEditorReturn} Object containing state and methods for metric editing
 */
export const useAnalyticsEditor = (): UseAnalyticsEditorReturn => {
    const { firestore } = useContext(DataContext);
    const { user, userGroups } = useContext(UserContext);
    const { add } = useAdd();

    // Core metric data state
    const { data: metrics, loading: metricsLoading } = useBoundCollection<Metric>({path: 'metrics'});
    const { data: entities, loading: entitiesLoading } = useBoundCollection<Entity>({path: 'entities'});
    const [selectedMetricId, setSelectedMetricId] = useState<string | undefined>();
    const { 
        data: selectedMetric,
        set: setMetric,
        loading: metricLoading,
        error 
    } = useBoundDoc<Metric>({
        path: 'metrics',
        docId: selectedMetricId || '',
        enabled: !!selectedMetricId
    });

    // UI state
    const [displayMode, setDisplayModeState] = useState<DisplayMode>('view');
    const [modifiedFields, setModifiedFields] = useState<Set<string>>(new Set());
    const [isMetricComplete, setIsMetricComplete] = useState(false);
    const [hasAccess, setHasAccess] = useState<boolean | null>(null);

    // Editor state with history
    const [state, dispatch] = useReducer(metricEditorReducer, {
        editedMetric: null,
        history: {
            past: [],
            future: []
        }
    });

    useEffect(() => {
        if (!userGroups?.metricsEditors && !userGroups?.organizationAdmin) return;

        const access = userGroups['metricsEditors'] === UserDBRole.editor || 
            userGroups['metricsEditors'] === UserDBRole.owner ||
            userGroups['organizationAdmin'] === UserDBRole.editor || 
            userGroups['organizationAdmin'] === UserDBRole.owner;
        
        setHasAccess(access);
    }, [userGroups]);

    // Validation logic
    const validateMetric = useCallback((metric: Metric): boolean => {
        if (!metric) return false;
        
        const requiredChecks = [
            metric.status,
            metric.source?.parentFormType,
            metric.source?.formVersion?.length > 0,
            metric.source?.timeField,
            metric.statistic,
            metric.timeParams?.timePeriods?.length > 0,
            metric.timeParams?.frequencyOfCalculation,
            metric.timeParams?.startTimeWindow,
            metric.description?.shortLabel,
            metric.description?.longDescription,
        ];

        // Add valueField check only if statistic is not 'count'
        if (metric.statistic !== StatisticType.Count) {
            requiredChecks.push(metric.source?.valueField);
        }

        return requiredChecks.every(Boolean);
    }, []);

    // Track field changes
    const trackFieldChange = useCallback((field: string) => {
        setModifiedFields(prev => new Set(prev).add(field));
    }, []);

    // Handle metric changes
    const handleMetricChange = (field: keyof Metric, value: any) => {
        let processedValue = value;
        
        if (field === 'groupBy') {
            processedValue = (value as string[]).map((entityId: string) => 
                doc(firestore, `entities/${entityId}`)
            );
        }
        
        const isFirstEdit = state.history.past.length === 0;
        const shouldSetPending = isFirstEdit && 
            state.editedMetric?.status === RecordStatus.Active && 
            field !== 'status';

        if (shouldSetPending) {
            // First update with status change
            dispatch({ 
                type: 'UPDATE_METRIC', 
                payload: { 
                    field: 'status',
                    value: RecordStatus.Pending,
                    modifiedFields: new Set(['status'])
                } 
            });
            trackFieldChange('status');
        }

        // Then update the actual field
        dispatch({ 
            type: 'UPDATE_METRIC', 
            payload: { 
                field,
                value: processedValue,
                modifiedFields: new Set([field])
            } 
        });
        trackFieldChange(field as string);
    };

    // Create new metric
    const createNewMetric = async () => {
        if (!user) throw new Error('User must be logged in');

        const defaultMetric: Metric = {
            docId: `temp_${Date.now()}`,
            description: {
                shortLabel: '',
                longDescription: ''
            },
            status: RecordStatus.Pending,
            source: {
                parentFormType: '' as DocId,
                formVersion: [] as FormStateId[],
                valueField: '' as FieldTypeId,
                timeField: '' as FieldTypeId
            },
            statistic: '' as StatisticType,
            timeParams: {
                timePeriods: [] as TimePeriod[],
                minimumTimeResolution: '' as TimePeriod,
                frequencyOfCalculation: defaultFrequencyOfCalculation as FrequencyOfCalculation,
                localTimeZone: defaultTimeZone as unknown as IANAZone,
                startTimeWindow: Timestamp.fromDate(new Date(defaultMetricStartTimeWindow))
            },
            userRoles: { [user.uid]: UserDBRole.owner },
            userGroup: 'everyone',
            groupBy: [] as DocumentReference[],
            meta: {
                version: '1.0',
                agentType: AgentType.User,
                created: Timestamp.now(),
                userId: user.uid,
                lastModified: Timestamp.now()
            }
        };

        dispatch({ type: 'SET_METRIC', payload: defaultMetric });
        setDisplayModeState('edit');
        setModifiedFields(new Set());
        setIsMetricComplete(false);
    };

    // Duplicate metric
    const duplicateMetric = useCallback(async (metricId: string) => {
        const metricToDuplicate = metrics?.find(m => m.docId === metricId);
        if (!metricToDuplicate || !user) return;

        // Helper function to generate copy name
        const generateCopyName = (name: string): string => {
            const copyRegex = /\s*\(Copy(?:\s*\d*)?\)\s*$/;
            const baseName = name.replace(copyRegex, '').trim();
            const existingCopies = metrics?.filter(m => 
                m.description?.shortLabel?.startsWith(baseName) && 
                copyRegex.test(m.description.shortLabel)
            ) || [];

            if (existingCopies.length === 0) return `${baseName} (Copy)`;

            const copyNumber = existingCopies.length + 1;
            return `${baseName} (Copy ${copyNumber})`;
        };

        const duplicatedMetric: Metric = {
            ...metricToDuplicate,
            docId: `temp_${Date.now()}`,
            status: RecordStatus.Pending,
            description: {
                ...metricToDuplicate.description,
                shortLabel: generateCopyName(metricToDuplicate.description.shortLabel || ''),
            },
            meta: {
                ...metricToDuplicate.meta,
                created: Timestamp.now(),
                lastModified: Timestamp.now(),
                userId: user.uid,
            }
        };

        // First set the display mode to edit
        setDisplayModeState('edit');
        // Then clear any existing modifications
        setModifiedFields(new Set());
        // Finally update the metric state
        dispatch({ type: 'SET_METRIC', payload: duplicatedMetric });
        setIsMetricComplete(true);
    }, [metrics, user, setDisplayModeState, setModifiedFields, dispatch, setIsMetricComplete]);

    // Save changes
    const saveChanges = async () => {
        if (!state.editedMetric || !isMetricComplete) {
            throw new Error('Cannot save incomplete metric');
        }

        try {
            const metricToSave = { 
                ...state.editedMetric,
                status: RecordStatus.Active,
                meta: {
                    ...state.editedMetric.meta,
                    lastModified: Timestamp.now()
                }
            };
            if (metricToSave.docId?.startsWith('temp_')) {
                // New metric
                const { docId, ...metricWithoutId } = metricToSave;
                const newId = await add('metrics', metricWithoutId);
                setSelectedMetricId(newId);
            } else {
                // Existing metric
                await setMetric(metricToSave);
            }
            setModifiedFields(new Set());
            setDisplayModeState('view');
            dispatch({ type: 'SET_METRIC', payload: metricToSave });
        } catch (error) {
            console.error('Save error:', error);
            throw error;
        }
    };

    // Discard changes
    const discardChanges = () => {
        dispatch({ type: 'SET_METRIC', payload: selectedMetric });
        setModifiedFields(new Set());
        setDisplayModeState('view');
    };

    // Delete metric
    const deleteMetric = async () => {
        if (!selectedMetric) return;

        try {
            await setMetric({
                ...selectedMetric,
                status: RecordStatus.Archived,
                meta: {
                    ...selectedMetric.meta,
                    lastModified: Timestamp.now()
                }
            });
            setSelectedMetricId(undefined);
            dispatch({ type: 'SET_METRIC', payload: null });
            setDisplayModeState('view');
            setModifiedFields(new Set());
        } catch (error) {
            console.error('Archive error:', error);
            throw error;
        }
    };

    // History actions
    const undo = () => {
        dispatch({ type: 'UNDO' });
    };

    const redo = () => {
        dispatch({ type: 'REDO' });
    };

    // Update edited metric when selected metric changes
    useEffect(() => {
        if (selectedMetric && displayMode === 'view') {
            dispatch({ type: 'SET_METRIC', payload: selectedMetric });
            setModifiedFields(new Set());
            setIsMetricComplete(validateMetric(selectedMetric));
        }
    }, [selectedMetric, displayMode, validateMetric]);

    // Update metric completeness when edited metric changes
    useEffect(() => {
        if (state.editedMetric) {
            setIsMetricComplete(validateMetric(state.editedMetric));
        }
    }, [state.editedMetric, validateMetric]);

    // Calculate hasUnsavedChanges from state
    const hasUnsavedChanges = state.history.past.length > 0;

    const showNavigationWarning = () => {
        if (!hasUnsavedChanges) return Promise.resolve(true);

        return new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: 'Unsaved Changes',
                content: 'You have unsaved changes. Are you sure you want to leave? Your changes will be lost.',
                okText: 'Leave',
                cancelText: 'Stay',
                onOk: () => {
                    discardChanges();
                    resolve(true);
                },
                onCancel: () => resolve(false),
            });
        });
    };

    // Handle beforeunload event
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (hasUnsavedChanges) {
                event.preventDefault();
                event.returnValue = '';
                return '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [hasUnsavedChanges]);

    // Business Logic Functions
    /**
     * Formats metrics into dropdown options with proper typing and filtering
     * @param metrics - Array of metrics or null
     * @returns Array of select options with value, label, and optional description
     * @throws Will not throw, returns empty array for invalid input
     */
    const getMetricOptions = useCallback((metrics: Metric[] | null): SelectOption[] => {
        if (!metrics) return [];
        
        try {
            return metrics
                .filter(metric => metric.docId && metric.description)
                .map(metric => ({
                    value: metric.docId!,
                    label: metric.description?.shortLabel || 'Unnamed Metric',
                    description: metric.description?.longDescription
                }));
        } catch (error) {
            console.error('Error formatting metric options:', error);
            return [];
        }
    }, []);

    /**
     * Maps a metric status to its corresponding badge properties
     * @param status - The RecordStatus to map
     * @returns Badge properties including status and display text
     * @throws Will not throw, returns processing status for invalid input
     */
    const getStatusBadge = useCallback((status: RecordStatus): BadgeProps => {
        if (!Object.values(RecordStatus).includes(status)) {
            console.warn(`Invalid status received: ${status}`);
            return { status: "processing", text: String(status) };
        }

        switch (status) {
            case RecordStatus.Pending:
                return { status: "warning", text: "Pending" };
            case RecordStatus.Active:
                return { status: "success", text: "Active" };
            case RecordStatus.Archived:
                return { status: "default", text: "Archived" };
            default:
                return { status: "processing", text: status };
        }
    }, []);

    /**
     * Gets field options for a form type, including time fields, value fields, and state options
     * @param formType - The form type to get options from
     * @returns Object containing arrays of options for different field types
     * @throws Will not throw, returns empty arrays for invalid input
     */
    const getFieldOptions = useCallback((formType: FormType | undefined): {
        timeFields: SelectOption[],
        valueFields: SelectOption[],
        stateOptions: SelectOption[]
    } => {
        if (!formType) {
            return {
                timeFields: [],
                valueFields: [],
                stateOptions: []
            };
        }

        try {
            const timeFields = formType.metricFields?.timeFields?.map((field: FieldTypeId) => ({
                value: field,
                label: field
            })) || [];

            const valueFields = formType.metricFields?.valueFields?.map((field: FieldTypeId) => ({
                value: field,
                label: field
            })) || [];

            const stateOptions = Object.keys(formType.possibleStates || {})
                .sort((a, b) => a.localeCompare(b))
                .map(state => ({
                    value: state,
                    label: state
                }));

            return {
                timeFields,
                valueFields,
                stateOptions
            };
        } catch (error) {
            console.error('Error getting field options:', error);
            return {
                timeFields: [],
                valueFields: [],
                stateOptions: []
            };
        }
    }, []);

    /**
     * Archives a metric by updating its status and metadata
     * @param metric - The metric to archive
     * @throws Error if metric is invalid or archive operation fails
     */
    const archiveMetric = useCallback(async (metric: Metric): Promise<void> => {
        if (!metric) {
            throw new Error('No metric provided for archiving');
        }

        if (!metric.docId) {
            throw new Error('Cannot archive metric without docId');
        }

        if (metric.status === RecordStatus.Archived) {
            throw new Error('Metric is already archived');
        }

        try {
            const archivedMetric = {
                ...metric,
                status: RecordStatus.Archived,
                meta: {
                    ...metric.meta,
                    lastModified: Timestamp.now()
                }
            };

            await setMetric(archivedMetric);
        } catch (error) {
            console.error('Failed to archive metric:', error);
            throw new Error(error instanceof Error ? error.message : 'Failed to archive metric');
        }
    }, [setMetric]);

    /**
     * Handles metric duplication with error handling
     * @param metricId - ID of the metric to duplicate
     * @returns Promise that resolves when duplication is complete
     */
    const handleDuplicateWithErrorHandling = useCallback(async (metricId: string): Promise<void> => {
        try {
            await duplicateMetric(metricId);
            return Promise.resolve();
        } catch (error) {
            console.error('Failed to duplicate metric:', error);
            throw new Error('Failed to duplicate metric');
        }
    }, [duplicateMetric]);

    /**
     * Handles metric archival with error handling
     * @param metric - The metric to archive
     * @returns Promise that resolves when archival is complete
     */
    const handleArchiveWithErrorHandling = async (metric: Metric): Promise<void> => {
        try {
            await archiveMetric(metric);
            setSelectedMetricId(undefined);
            dispatch({ type: 'SET_METRIC', payload: null });
            setDisplayModeState('view');
            setModifiedFields(new Set());
            return Promise.resolve();
        } catch (error) {
            console.error('Failed to archive metric:', error);
            throw new Error('Failed to archive metric');
        }
    };

    /**
     * Handles saving changes with error handling
     * @returns Promise that resolves when save is complete
     */
    const handleSaveWithErrorHandling = async (): Promise<void> => {
        try {
            await saveChanges();
            return Promise.resolve();
        } catch (error) {
            console.error('Failed to save changes:', error);
            throw new Error('Failed to save changes');
        }
    };

    /**
     * Validates a metric and returns validation results
     */
    const handleMetricValidation = (metric: Metric): ValidationResult => {
        if (!metric) {
            return { isValid: false, errors: ['Invalid metric provided'] };
        }

        const errors: string[] = [];
        
        // Required fields validation
        if (!metric.status) errors.push('Status is required');
        if (!metric.source?.parentFormType) errors.push('Form type is required');
        if (!metric.source?.formVersion?.length) errors.push('Form version is required');
        if (!metric.source?.timeField) errors.push('Time field is required');
        if (!metric.statistic) errors.push('Statistic type is required');
        if (!metric.timeParams?.timePeriods?.length) errors.push('Time periods are required');
        if (!metric.timeParams?.frequencyOfCalculation) errors.push('Calculation frequency is required');
        if (!metric.timeParams?.startTimeWindow) errors.push('Start time is required');
        if (!metric.description?.shortLabel) errors.push('Metric name is required');
        if (!metric.description?.longDescription) errors.push('Description is required');

        // Conditional validation
        if (metric.statistic !== StatisticType.Count && !metric.source?.valueField) {
            errors.push('Value field is required for non-count statistics');
        }

        return { isValid: errors.length === 0, errors };
    };

    return {
        // Core metric data
        metrics,
        selectedMetric,
        editedMetric: state.editedMetric,
        loading: metricsLoading || metricLoading,
        error,
        entities,
        entitiesLoading,

        // UI State
        displayMode,
        hasUnsavedChanges,
        modifiedFields,
        isMetricComplete,

        // Actions
        selectMetric: setSelectedMetricId,
        handleMetricChange,
        setDisplayMode: setDisplayModeState,
        createNewMetric,
        duplicateMetric,
        saveChanges,
        discardChanges,
        deleteMetric,
        setMetric,
        
        // History
        canUndo: state.history.past.length > 0,
        canRedo: state.history.future.length > 0,
        undo,
        redo,

        showNavigationWarning,

        // Business Logic Functions
        getMetricOptions,
        getStatusBadge,
        getFieldOptions,
        archiveMetric,
        handleMetricValidation,

        handleDuplicateWithErrorHandling,
        handleArchiveWithErrorHandling,
        handleSaveWithErrorHandling,

        hasAccess,
    };
};
