import { FC } from 'react';

import FlexPage from '../components/atoms/FlexPage';
import FlexContent from '../components/atoms/FlexContent';
import Navb from '../components/Navb';
import { ViewFormsByEntity } from '../components/views/ViewFormsByEntity';
import { MAX_LIMITED_WIDTH_VIEWPORT_NARROW } from '../types/System.Parameters.types';


const HomePage: FC = () => {

    return (
        <FlexPage>
            <Navb />
            <FlexContent style={{ maxWidth: MAX_LIMITED_WIDTH_VIEWPORT_NARROW }}>
                <ViewFormsByEntity entityType="Projects" />
            </FlexContent>
        </FlexPage>
    );
};

export default HomePage; 