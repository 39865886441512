import React, { useState, useEffect, createContext, useContext, ReactNode } from 'react';
import { ConfigProvider, theme as antdTheme } from 'antd';

import { UserContext, UserContextType } from './UserProvider'; 

export interface ThemeContextType {
  darkMode: boolean;
}

export const ThemeContext = createContext<ThemeContextType>({
  darkMode: false,
});

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { settings } = useContext<UserContextType>(UserContext);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const preferDarkMode = settings?.preferDarkMode || 'system';

  useEffect(() => {
    const matcher = window.matchMedia('(prefers-color-scheme: dark)');
    const onChange = (e: MediaQueryListEvent) => {
      if (preferDarkMode === 'system') {
        setDarkMode(e.matches);
      }
    };

    matcher.addEventListener('change', onChange);

    if (preferDarkMode === 'dark') {
      setDarkMode(true);
    } else if (preferDarkMode === 'light') {
      setDarkMode(false);
    } else if (preferDarkMode === 'system') {
      setDarkMode(matcher.matches);
    }

    return () => matcher.removeEventListener('change', onChange);
  }, [preferDarkMode]);

  const currentTheme = {
    ...(darkMode ? { algorithm: antdTheme.darkAlgorithm } : {}),
    token: {
      fontFamily: "'Work Sans', sans-serif",
      colorPrimary: darkMode ? "#D0643E" : "#D0643E",
      colorPrimaryBorder: darkMode ? '#D78635' : '#D0643E',
      colorInfo: darkMode ? "#D0643E" : "#D0643E",
      fontSize: 16,
      fontSizeHEading3: 28,
      fontSizeHeading4: 24,
      fontSizeHeading5: 20,
      lineHeightHeading5: 1,
      borderRadius: 4,
      borderRadiusLG: 4,
      fontWeightStrong: 500,
      customBgTransparent: darkMode ? 'rgba(50,50,50,0.75)' : 'rgba(252,252,252,0.75)',
      colorTextDisabled: darkMode ? 'rgba(255, 255, 255, 0.68)' : 'rgba(0, 0, 0, 0.68)',
      colorError: '#FF1010',
    },
    components: {
      Button: {
        controlTmpOutline: "#FF1010",
        controlOutlineWidth: 0,
        boxShadowTertiary: "none",
        colorBgContainer: darkMode ? '#362019' : '#FAEEE3',
        defaultColor: darkMode ? '#D78635' : '#D0643E',
        colorText: darkMode ? 'rgba(255, 255, 255, 0.85)' : 'rgba(0, 0, 0, 0.85)',
        colorBorder: darkMode ? '#D78635' : '#D0643E',
        colorTextLightSolid: darkMode ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.95)',
        colorTextDefault: darkMode ? "#FAEEE3" : "#8C4647",
        linkHoverBg: darkMode ? '#00150C' : '#D0643E',
        fontSizeSM: 12,
      },
      Radio: {
        buttonCheckedBg: darkMode ? '#00150C' : '#F4FFFA',
      },
      Flex: {
        wrap: 'flex-wrap',
      },
      Cascader: {
        dropdownHeight: 400
      },
    },
  };

  return (
    <ThemeContext.Provider value={{ darkMode }}>
      <ConfigProvider theme={ currentTheme }>
        {children}
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};


        
      