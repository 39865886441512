import { httpsCallable } from 'firebase/functions';
import { EventCategory } from '../types/System.types';
import { functions } from './firebaseFunctions';
import type { 
    CalculateOptimizationRequest,
    OptimizationResult 
} from '../use/orgs/useClwTableOptimizer';

/**
 * Interface for log event request payload
 */
export interface LogEventRequest {
    eventCategory: EventCategory;
    description: string;
    userId?: string;
    changedValues?: Record<string, { 
        oldValue: string; 
        newValue: string 
    }>;
    meta?: Record<string, any>;
    isError?: boolean;
}

export const runOptimizationCalculation = httpsCallable<CalculateOptimizationRequest, OptimizationResult>(
    functions, 
    'run_optimization_calculation'
);

export const logEvent = httpsCallable<LogEventRequest, { success: boolean }>(
    functions, 
    'logEvent'
);

export const runFullMetricRecalculation = httpsCallable<void, { success: boolean }>(
    functions,
    'run_full_metric_recalculation'
); 