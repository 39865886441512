import { FC, useContext, useState } from 'react';
import { Breadcrumb, Dropdown, Button, Typography, Tooltip, message } from 'antd';
import { DownOutlined, FilePdfOutlined, TeamOutlined, BellOutlined, BellFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { FormContext } from '../../providers/FormProvider';
import { UserContext } from '../../providers/UserProvider';
import { createFormVersion, createFormFromForm } from '../../services/artifactFunctions';
import FlexCard from '../atoms/FlexCard';
import FlexBox from '../atoms/FlexBox';
import DocumentAccess from '../atoms/DocumentAccess';
import FormAccessModal from '../modals/FormAccessModal';
import FormAssignControl from './FormAssignControl';
import { FormStateId, FormType, TimeType, formStateDescription } from '../../types/System.types';
import { pdfExport } from '../../services/pdfExport';
import type { MenuProps } from 'antd';
import FormSubscriberControl from './FormSubscriberControl';

const { Text } = Typography;

interface FormControlProps {
  formId: string;
  contentRef: React.RefObject<HTMLDivElement>;
}

const FormControl: FC<FormControlProps> = ({ formId, contentRef }: FormControlProps) => {
  const [isAccessModalOpen, setIsAccessModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const {
    selectedForm,
    selectedFormVersion,
    formVersions,
    selectFormVersion,
    formTypeOfSelectedForm,
    formVersionAccess,
    formTypes
  } = useContext(FormContext);
  const { user } = useContext(UserContext);

  const navigate = useNavigate();
  const [isCreating, setIsCreating] = useState(false);

  const handleVersionChange = (versionId: string) => {
    selectFormVersion(versionId);
  };

  const handleCreateVersion = async (state: FormStateId) => {
    if (!selectedFormVersion.data) return;

    setIsCreating(true);

    try {
      const newVersionId = await createFormVersion(formId, state, selectedFormVersion.data);
      selectFormVersion(newVersionId);
    } catch (err) {
      messageApi.error(`Error creating new version: ${err instanceof Error ? err.message : String(err)}`);
    } finally {
      setIsCreating(false);
    }
  };

  const handleCreateFormFromForm = async (newFormType: FormType["docId"]) => {
    if (!selectedFormVersion.data || !formId || !newFormType) return;

    setIsCreating(true);

    try {
      const newFormId = await createFormFromForm({
        formId,
        newFormType,
        title: `New form from ${selectedForm.data?.title ?? 'existing form'}`,
      });
      navigate(`/artifacts/forms/${newFormId}`);
    } catch (err) {
      messageApi.error(`Error creating new form: ${err instanceof Error ? err.message : String(err)}`);
    } finally {
      setIsCreating(false);
    }
  };

  const handlePrint = () => {
    pdfExport({
      contentRef,
      formData: selectedForm.data,
      versionTitle: selectedFormVersion.data?.docId,
    });
  };

  const formatDate = (time: TimeType | undefined) => {
    if (time && 'toDate' in time) {
      return time.toDate().toLocaleString();
    }
    return '';
  };

  const versionOptions = formVersions.data?.map(version => ({
    id: version.docId || '',
    title: version.docId || '',
    state: version.state,
    description: formStateDescription[version.state as FormStateId]?.shortLabel || '',
    created: formatDate(version.meta?.created)
  })) || [];

  const versionItems = versionOptions.map(option => ({
    key: option.id,
    label: (
      <FlexBox>
        <Tooltip title={option?.description || ''}>
          <Text><b>{option.state}</b> {option.title}</Text>
        </Tooltip>
        <Text type="secondary">{option.created}</Text>
      </FlexBox>
    ),
    onClick: () => handleVersionChange(option.id),
  }));

  const currentVersionState = selectedFormVersion.data?.state;
  const possibleStates = formTypeOfSelectedForm.data?.possibleStates;
  const allowedNextStates = currentVersionState 
    ? possibleStates?.[currentVersionState]?.allowedNextStates 
    : undefined;
  const allowedNextForms = formTypeOfSelectedForm.data?.allowedNextForms;

  const noStateAllowedItem = [{
    key: 'no-states',
    label: 'Not allowed',
    disabled: true
  }];

  const noFormAllowedItem = [{
    key: 'no-forms',
    label: 'Not allowed',
    disabled: true
  }];

  const createItems = [
    {
      key: 'pdf',
      label: (
        <span>
          <FilePdfOutlined /> Export PDF
        </span>
      ),
      onClick: handlePrint
    },
    {
      key: 'divider1',
      type: 'divider' as const
    },
    {
      key: 'version',
      type: 'group' as const,
      label: 'New Version',
      children: (allowedNextStates?.length ?? 0) > 0
        ? allowedNextStates?.map((state: FormStateId) => ({
          key: `version-${state}`,
          label: formStateDescription[state]?.shortLabel || state,
          onClick: () => handleCreateVersion(state)
        })) ?? []
        : noStateAllowedItem
    },
    {
      key: 'form',
      type: 'group' as const,
      label: 'New Form',
      children: (allowedNextForms?.length ?? 0) > 0
        ? allowedNextForms?.map(formType => ({
          key: `form-${formType}`,
          label: formTypes.data?.find(ft => ft.docId === formType)?.description?.shortLabel || formType,
          onClick: () => handleCreateFormFromForm(formType)
        })) ?? []
        : noFormAllowedItem
    }
  ];

  const currentState = selectedFormVersion.data?.state;
  const stateDescription = currentState ? formStateDescription[currentState] : formStateDescription['draft'];

  return (
    <FlexCard
      row
      wrap
      alignCenter
      justifyCenter
      gap={10}
      style={{
        minWidth: '100%',
        marginBottom: 10,
      }}
    >
      {contextHolder}
      <FlexBox noGrow>
        <Breadcrumb
          items={[
            {
              title: formId,
            },
            {
              title: (
                <Tooltip title={stateDescription?.longDescription || ''}>
                  <Text><b>{stateDescription?.shortLabel}</b> {selectedFormVersion.data?.docId}</Text>
                </Tooltip>
              ),
              menu: { items: versionItems },
            }
          ]}
        />
        <DocumentAccess
          canRead={formVersionAccess?.canRead}
          canWrite={formVersionAccess?.canWrite}
        />
      </FlexBox>
      <FlexBox 
        stretch 
        justifyEnd 
        gap={5}
        wrap
      >
              <Button
          type='link'
          icon={<TeamOutlined />}
          onClick={() => setIsAccessModalOpen(true)}
        >
          Access
        </Button>
        <FormAssignControl />
        <FormSubscriberControl />
        <Dropdown 
          menu={{ items: createItems }} 
          trigger={['click']} 
          disabled={isCreating}
        >
          <Button
            type='primary'
            loading={isCreating}
            disabled={isCreating}
          >
            Workflow <DownOutlined />
          </Button>
        </Dropdown>
      </FlexBox>
      <FormAccessModal 
        formId={formId}
        open={isAccessModalOpen}
        onClose={() => setIsAccessModalOpen(false)}
      />
    </FlexCard>
  );
};

export default FormControl;
