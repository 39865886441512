import { useState, useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Dayjs } from 'dayjs';
import { DocId, Metric, TimePeriod, MetricGroupedBy, MetricRecord } from '../types/System.types';
import { useBoundCollection } from './data/useBoundCollection';
import { useBoundDoc } from './data/useBoundDoc';
import { Timestamp } from 'firebase/firestore';

dayjs.extend(utc);

// Interfaces
interface SelectOption {
    value: string;
    label: string;
    description?: string;
}

interface TimeSeriesData {
    dates: string[];
    dateHover: string[];
    values: {
        [date: string]: {
            value: number;
            count: number;
        };
    };
}

interface UseAnalyticsReturn {
    // Data
    metrics: MetricRecord[] | null;
    selectedMetric: Metric | null;
    metricGroups: MetricGroupedBy[] | null;
    metricRecords: MetricRecord[] | null;
    loading: {
        metrics: boolean;
        groups: boolean;
        records: boolean;
    };
    error: Error | null;

    // UI States
    dateRange: [Dayjs | null, Dayjs | null];
    selectedTimePeriod: TimePeriod | null;
    selectedGroupBy: string | null;

    // Actions
    selectMetric: (metricId: DocId | undefined) => void;
    setDateRange: (range: [Dayjs | null, Dayjs | null]) => void;
    setTimePeriod: (period: TimePeriod | null) => void;
    setGroupBy: (group: string | null) => void;

    // Business Logic Functions
    getMetricOptions: () => SelectOption[];
    getGroupOptions: () => SelectOption[];
    getTimePeriodOptions: () => SelectOption[];
    getTimeSeriesData: () => TimeSeriesData | null;
    getFilteredRecords: () => MetricRecord[];
}

export const useAnalytics = (): UseAnalyticsReturn => {
    // State Management
    const [selectedMetricId, setSelectedMetricId] = useState<DocId | undefined>();
    const [selectedGroupId, setSelectedGroupId] = useState<DocId | undefined>();
    const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState<TimePeriod | null>(null);
    const [selectedGroupBy, setSelectedGroupBy] = useState<string | null>(null);

    // Data Fetching
    const { 
        data: metrics = [], 
        loading: metricsLoading,
        error: metricsError 
    } = useBoundCollection<Metric>({
        path: 'metrics',
        initialOrderBy: [{ field: 'meta.created', direction: 'desc' }],
    });

    const {
        data: selectedMetric,
        error: metricError
    } = useBoundDoc<Metric>({
        path: 'metrics',
        docId: selectedMetricId || '',
        enabled: !!selectedMetricId,
    });

    const {
        data: metricGroups = [],
        loading: groupsLoading,
        error: groupsError
    } = useBoundCollection<MetricGroupedBy>({
        path: selectedMetricId ? `metrics/${selectedMetricId}/metricGroupedBy` : '',
        enabled: !!selectedMetricId,
    });

    const {
        data: metricRecords = [],
        loading: recordsLoading,
        error: recordsError
    } = useBoundCollection<MetricRecord>({
        path: selectedMetricId && selectedGroupId
            ? `metrics/${selectedMetricId}/metricGroupedBy/${selectedGroupId}/metricRecords`
            : '',
        enabled: !!selectedMetricId && !!selectedGroupId,
        initialOrderBy: [{ field: 'metricTimeStamp', direction: 'asc' }],
        initialLimit: 5000
    });

    // Business Logic Functions
    const getFilteredRecords = useCallback(() => {
        return metricRecords?.filter(record => {
            if (!dateRange[0] || !dateRange[1] || !(record.metricTimeStamp instanceof Timestamp)) return true;
            
            // Convert naive dateRange dates to UTC timestamps so that we can compare them to the record timestamp in local timezone.
            const startDate = dateRange[0].utc().startOf('day');
            const endDate = dateRange[1].utc().startOf('day');
            const recordTime = record.metricTimeStamp.toMillis();

            // Compare dates in the same timezone context
            return recordTime >= startDate.valueOf() && recordTime < endDate.valueOf();
        }) || [];
    }, [metricRecords, dateRange]);

    const getTimeSeriesData = useCallback((): TimeSeriesData | null => {
        const filteredRecords = getFilteredRecords();
        if (!filteredRecords || !selectedTimePeriod) return null;

        const timePeriodsData: {
            [key: string]: TimeSeriesData
        } = {};

        filteredRecords.forEach(record => {
            // Check each possible time period
            const periods = [
                { name: 'day', dayTimeString: record.metricTimeString, dateStr: record.dayMetricString, value: record.dayMetricValue, count: record.dayMetricCount },
                { name: 'week', dayTimeString: record.metricTimeString, dateStr: record.weekMetricString, value: record.weekMetricValue, count: record.weekMetricCount },
                { name: 'month', dayTimeString: record.metricTimeString, dateStr: record.monthMetricString, value: record.monthMetricValue, count: record.monthMetricCount },
                { name: 'quarter', dayTimeString: record.metricTimeString, dateStr: record.quarterMetricString, value: record.quarterMetricValue, count: record.quarterMetricCount },
                { name: 'year', dayTimeString: record.metricTimeString, dateStr: record.yearMetricString, value: record.yearMetricValue, count: record.yearMetricCount }
            ];

            periods.forEach(period => {
                // Only process if this period exists in the record
                if (period.dateStr && period.value !== undefined && period.count !== undefined) {
                    if (!timePeriodsData[period.name]) {
                        timePeriodsData[period.name] = {
                            dates: [],
                            dateHover: [],
                            values: {}
                        };
                    }

                    // Add date if not already present
                    if (!timePeriodsData[period.name].dates.includes(period.dayTimeString)) {
                        timePeriodsData[period.name].dates.push(period.dayTimeString);
                        timePeriodsData[period.name].dateHover.push(period.dateStr);
                    }

                    // Add or update values and hover
                    timePeriodsData[period.name].values[period.dayTimeString] = {
                        value: period.value,
                        count: period.count
                    };
                }
            });
        });

        // Sort dates for each time period
        Object.keys(timePeriodsData).forEach(period => {
            timePeriodsData[period].dates.sort();
        });

        return timePeriodsData[selectedTimePeriod] || null;
    }, [getFilteredRecords, selectedTimePeriod]);

    // Update date range and groups when metric changes
    useEffect(() => {
        if (selectedMetric?.timeParams) {
            // Set default time period to first available period
            if (selectedMetric.timeParams.timePeriods?.length > 0) {
                setSelectedTimePeriod(selectedMetric.timeParams.timePeriods[0]);
            }

            // Set date range if startTimeWindow exists
            if (selectedMetric.timeParams.startTimeWindow && selectedMetric.timeParams.startTimeWindow instanceof Timestamp) {
                const startDate = dayjs(selectedMetric.timeParams.startTimeWindow.toDate());
                const endDate = dayjs();
                setDateRange([startDate, endDate]);
            } else {
                setDateRange([null, null]);
            }

            // Set default group to "Across All Entities" if it exists
            const acrossAllGroup = metricGroups?.find(group => 
                group.description?.shortLabel?.toLowerCase() === "across all entities"
            );
            if (acrossAllGroup?.docId) {
                setSelectedGroupBy(acrossAllGroup.docId);
                setSelectedGroupId(acrossAllGroup.docId);
            }
        } else {
            setDateRange([null, null]);
            setSelectedTimePeriod(null);
            setSelectedGroupBy(null);
            setSelectedGroupId(undefined);
        }
    }, [selectedMetric, metricGroups]);

    const handleSetDateRange = useCallback((range: [Dayjs | null, Dayjs | null]) => {
        setDateRange(range);
    }, []);

    const handleSetTimePeriod = useCallback((period: TimePeriod | null) => {
        setSelectedTimePeriod(period);
    }, []);

    const handleSetGroupBy = useCallback((group: string | null) => {
        setSelectedGroupBy(group);
        setSelectedGroupId(group ? group as DocId : undefined);
    }, []);

    // Utility Functions
    const getMetricOptions = useCallback(() => {
        if (!metrics) return [];
        
        return metrics.map(metric => ({
            value: metric.docId || '',
            label: metric.description?.shortLabel || 'Unnamed Metric',
            description: metric.description?.longDescription
        }));
    }, [metrics]);

    const getGroupOptions = useCallback(() => {
        if (!metricGroups) return [];
        
        return metricGroups.map(group => ({
            value: group.docId || '',
            label: group.description?.shortLabel || 'Unnamed Group',
            description: group.description?.longDescription
        }));
    }, [metricGroups]);

    const getTimePeriodOptions = useCallback(() => {
        if (!selectedMetric?.timeParams?.timePeriods) return [];

        return selectedMetric.timeParams.timePeriods.map(period => ({
            value: period,
            label: period.charAt(0).toUpperCase() + period.slice(1).toLowerCase()
        }));
    }, [selectedMetric]);

    // Combine all errors
    const error = metricsError || metricError || groupsError || recordsError || null;

    // Loading states
    const loading = {
        metrics: metricsLoading,
        groups: groupsLoading,
        records: recordsLoading
    };

    return {
        // Data
        metrics: metrics as unknown as MetricRecord[],
        selectedMetric,
        metricGroups,
        metricRecords,
        loading,
        error,
        
        // UI States
        dateRange,
        selectedTimePeriod,
        selectedGroupBy,
        
        // Actions
        selectMetric: setSelectedMetricId,
        setDateRange: handleSetDateRange,
        setTimePeriod: handleSetTimePeriod,
        setGroupBy: handleSetGroupBy,

        // Business Logic Functions
        getMetricOptions,
        getGroupOptions,
        getTimePeriodOptions,
        getTimeSeriesData,
        getFilteredRecords,
    };
};