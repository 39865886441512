import { useEffect, useState, useContext, useMemo, useCallback } from 'react';
import { UserContext } from '../../providers/UserProvider';
import { Form, FormVersion, EntityRecord } from '../../../data/types/System.types';
import { useBoundCollection } from '../data/useBoundCollection';
import { useBoundDocs } from '../data/useBoundDocs';
import { WhereFilterOp } from 'firebase/firestore';

interface FormWithNotification extends Form {
  notificationStatus?: 'read' | 'unread';
}

interface EntityGroup {
  entityName: string;
  entityId: string;
  forms: FormWithNotification[];
  versions: FormVersion[];
  error?: string;
  unreadCount: number;
}

export type FormFilterMode = 'all' | 'subscribed' | 'assigned';

interface UseViewFormsByEntity {
  groupedForms: EntityGroup[];
  error: Error | null;
  loading: boolean;
  loadMore: () => void;
  hasMore: boolean;
  formVersions: FormVersion[];
  toggleNotification: (formId: string) => Promise<void>;
  filterMode: FormFilterMode;
  setFilterMode: (mode: FormFilterMode) => void;
}

const FORMS_PER_PAGE = 1000;

/**
 * Hook to fetch and group forms by their associated entities
 * @param entityType The type of entity to group forms by
 */
export const useViewFormsByEntity = (
  entityType: string,
  defaultFilterMode: FormFilterMode = 'subscribed'
): UseViewFormsByEntity => {
  const { user, unreadNotifications, readNotifications, toggleNotificationRead } = useContext(UserContext);
  const [groupedForms, setGroupedForms] = useState<EntityGroup[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [filterMode, setFilterMode] = useState<FormFilterMode>(defaultFilterMode);

  const queryFilters = useMemo(() => {
    const filters = [];
    if (user?.uid) {
      if (filterMode === 'subscribed') {
        filters.push({ 
          field: 'notificationsSubscribers', 
          operator: 'array-contains' as WhereFilterOp,
          value: user.uid 
        });
      } else if (filterMode === 'assigned') {
        filters.push({ 
          field: 'assignedTo', 
          operator: '==' as WhereFilterOp,
          value: user.uid 
        });
      }
    }
    return filters;
  }, [filterMode, user?.uid]);

  const { 
    data: forms, 
    loading: formsLoading, 
    error: formsError,
    setStartAt,
    setFilters
  } = useBoundCollection<Form>({
    path: 'forms',
    initialLimit: FORMS_PER_PAGE,
    initialOrderBy: [{ 
      field: 'meta.lastModified', 
      direction: 'desc' 
    }],
    initialFilters: queryFilters,
    enabled: !!user
  });

  const memoizedForms = useMemo(() => forms, [forms.map(f => f.docId).join()]);

  const { 
    data: versions,
    loading: versionsLoading
  } = useBoundDocs<FormVersion>({
    paths: memoizedForms.map(form => `forms/${form.docId}/formVersions/${form.currentVersionId}`),
    enabled: memoizedForms.length > 0
  });

  const memoizedVersions = useMemo(() => versions, [versions.map(v => v.docId).join()]);

  const entityIds = useMemo(() => {
    const ids = new Set<string>();
    memoizedVersions.forEach(version => {
      if (version?.entityReferences?.[entityType]) {
        ids.add(version.entityReferences[entityType]);
      }
    });
    return Array.from(ids);
  }, [memoizedVersions, entityType]);

  const { 
    data: entities,
    loading: entitiesLoading
  } = useBoundDocs<EntityRecord>({
    paths: entityIds.map(id => `entities/${entityType}/records/${id}`),
    enabled: entityIds.length > 0
  });

  const memoizedEntities = useMemo(() => entities, [entities.map(e => e.docId).join()]);

  const notificationStatus = useMemo(() => {
    const status = new Map<string, 'read' | 'unread'>();
    unreadNotifications?.forEach(n => {
      if (n.notificationEvent.formId) {
        status.set(n.notificationEvent.formId, 'unread');
      }
    });
    readNotifications?.forEach(n => {
      if (n.notificationEvent.formId) {
        status.set(n.notificationEvent.formId, 'read');
      }
    });
    return status;
  }, [unreadNotifications, readNotifications]);

  const processGroups = useCallback(() => {
    if (!memoizedForms.length || !memoizedVersions.length || !memoizedEntities.length) return;

    const entityMap = new Map(memoizedEntities.map(entity => [entity.docId, entity]));
    const groups: Record<string, EntityGroup> = {};

    memoizedVersions.forEach((version, index) => {
      if (!version?.entityReferences?.[entityType]) return;
      
      const form = memoizedForms[index];
      const entityId = version.entityReferences[entityType];
      const entity = entityMap.get(entityId);

      if (!groups[entityId]) {
        groups[entityId] = {
          entityId,
          entityName: entity?.name || 'Unknown Entity',
          forms: [],
          versions: [],
          unreadCount: 0,
          ...(entity ? {} : { error: 'Entity not found' })
        };
      }

      const formWithNotification: FormWithNotification = {
        ...form,
        notificationStatus: notificationStatus.get(form?.docId || '')
      };

      groups[entityId].forms.push(formWithNotification);
      groups[entityId].versions.push(version);
      if (formWithNotification.notificationStatus === 'unread') {
        groups[entityId].unreadCount++;
      }
    });

    const sortedGroups = Object.values(groups);
    
    setGroupedForms(prev => {
      if (JSON.stringify(prev) === JSON.stringify(sortedGroups)) return prev;
      return sortedGroups;
    });
    
    setHasMore(memoizedForms.length === FORMS_PER_PAGE);
  }, [memoizedForms, memoizedVersions, memoizedEntities, entityType, notificationStatus]);

  useEffect(() => {
    processGroups();
  }, [processGroups]);

  useEffect(() => {
    setFilters(queryFilters);
  }, [queryFilters, setFilters]);

  const toggleNotification = useCallback(async (formId: string) => {
    const notification = [...(unreadNotifications || []), ...(readNotifications || [])]
      .find(n => n.notificationEvent.formId === formId);
    
    if (notification) {
      await toggleNotificationRead(notification);
    }
  }, [unreadNotifications, readNotifications, toggleNotificationRead]);

  const loadMore = () => {
    if (!hasMore || formsLoading) return;
    
    if (forms.length > 0) {
      const lastForm = forms[forms.length - 1];
      setStartAt(lastForm);
    }
  };

  return {
    groupedForms,
    error: formsError,
    loading: formsLoading || versionsLoading || entitiesLoading,
    loadMore,
    hasMore,
    formVersions: versions,
    toggleNotification,
    filterMode,
    setFilterMode
  };
}; 