import { useContext, useCallback } from 'react';
import { Timestamp } from 'firebase/firestore';
import { UserContext } from '../providers/UserProvider';
import { FormContext } from '../providers/FormProvider';
import { EntityContext } from '../providers/EntityProvider';
import { AgentType, UIField } from '../types/System.types';

interface UseFieldContextReturn {
  hasContext: boolean;
  isReadOnly: boolean;
  fieldData: any;
  handleDBSave: (value: any) => void;
}

/**
 * @context
 * Toggles between EntityContext and FormContext
 * so that FieldUI can use the appropriate context
 * 
 * @throws {Alert}
 * Renders an error Alert if neither EntityContext nor FormContext is available
 */
export const useFieldUIContext = (uiField: UIField): UseFieldContextReturn => {
  const { user } = useContext(UserContext);
  const formContext = useContext(FormContext);
  const entityContext = useContext(EntityContext);

  const insideFormContext = formContext?.insideFormContext ?? false;
  const formVersionAccess = formContext?.formVersionAccess;
  const setFormVersionData = formContext?.selectedFormVersion?.updateData;
  const selectedFormData = formContext?.selectedFormVersion?.data;

  const insideEntityContext = entityContext?.insideEntityContext ?? false;
  const hasEntityAccess = entityContext?.hasEntityAccess;
  const setEntityRecord = entityContext?.setRecord;
  const selectedEntityRecord = entityContext?.selectedEntityRecord;

  const entityLocked = entityContext?.entityLocked ?? false;

  const isReadOnly = insideEntityContext
    ? !hasEntityAccess || entityLocked
    : !formVersionAccess?.canWrite;

  const handleDBSave = useCallback((value: any) => {
    const updateObject = {
      fields: {
        [uiField.fieldId]: {
          value: value,
          meta: {
            lastModified: Timestamp.now(),
            userId: user?.uid,
            agentType: AgentType.User,
          }
        }
      },
      meta: {
        lastModified: Timestamp.now(),
        userId: user?.uid,
        agentType: AgentType.User,
      }
    };

    if (insideEntityContext && setEntityRecord) {
      setEntityRecord(updateObject);
    } else if (insideFormContext && formVersionAccess?.canWrite && setFormVersionData) {
      setFormVersionData(updateObject);
    }
  }, [
    insideEntityContext,
    insideFormContext,
    formVersionAccess,
    uiField.fieldId,
    user?.uid,
    setEntityRecord,
    setFormVersionData,
  ]);

  const fieldData = insideEntityContext
    ? selectedEntityRecord?.fields?.[uiField.fieldId]
    : selectedFormData?.fields?.[uiField.fieldId];

  return {
    hasContext: insideEntityContext || insideFormContext,
    isReadOnly,
    fieldData,
    handleDBSave,
  };
}; 