import React, { FC, useMemo } from 'react';
import { UIElement, EntityRecord, FormVersion, UIElementType } from '../../types/System.types';
import FieldUI from './FieldUI';
import ContainerUI from './ContainerUI';
import EntityUI from './EntityUI';
import MetaFieldUI from './MetaFieldUI';
import { EntityProvider } from '../../providers/EntityProvider';
import { SearchWrapper } from '../SearchWrapper';

interface LayoutUIProps {
  uiElement: UIElement;
  itemData: EntityRecord | FormVersion;
  parentLocked?: boolean;
}

const LayoutUI: FC<LayoutUIProps> = React.memo(({
  uiElement,
  itemData,
  parentLocked = false,
}) => {
  const content = useMemo(() => {
    switch (uiElement.uiElementType) {
      case UIElementType.Field:
        if ('fields' in itemData) {
          return <FieldUI uiField={uiElement} />;
        }
        return null;
      case UIElementType.EntityReference:
        if (uiElement.entityId) {
          return (
            <EntityProvider
              initialEntityId={uiElement.entityId}
              initialRecordId={
                'entityReferences' in itemData ? 
                  itemData.entityReferences[uiElement.entityId] : 
                  undefined
              }
            >
              <SearchWrapper
                index="entityRecord"
                filters={`docIdParent:${uiElement?.entityId}`}
              >
                <EntityUI/>
              </SearchWrapper>
            </EntityProvider>
          );
        }
        return null;
      case UIElementType.MetaField:
        if ('meta' in itemData) {
          return <MetaFieldUI 
            uiMetaField={uiElement} 
            itemData={itemData} 
          />;
        }
        return null;
      case UIElementType.Grid:
      case UIElementType.Row:
      case UIElementType.Column:
        return <ContainerUI
          container={uiElement}
          itemData={itemData}
          parentLocked={parentLocked}
        />;
      default:
        console.warn(`Unknown UI element type: ${(uiElement as any).uiElementType}`);
        return null;
    }
  }, [uiElement, itemData, parentLocked]);

  return content;
});

LayoutUI.displayName = 'LayoutUI';

export default LayoutUI;