import { FC } from 'react';
import { List, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { ListProps } from 'antd/es/list';

import { SystemFieldTypeId } from '../../../types/System.types';
import CellUI from './atoms/CellUI';
import RowActions from '../../atoms/RowActions';

interface ListUIProps<T> extends Omit<ListProps<T>, 'renderItem'> {
    onChange?: (updatedData: any[]) => void;
    readOnly?: boolean;
    fieldTypeId?: SystemFieldTypeId;
}

const ListUI: FC<ListUIProps<any>> = ({ 
    dataSource = [], 
    onChange,
    readOnly,
    fieldTypeId = 'text',
    ...props 
}) => {
    const handleCellChange = (rowIndex: number, _columnKey: string, value: any) => {
        if (onChange && !readOnly) {
            const updatedData = [...dataSource];
            updatedData[rowIndex] = value;
            onChange(updatedData);
        }
    };

    const handleAddRow = () => {
        if (onChange && !readOnly) {
            const updatedData = [...dataSource];
            updatedData.push(null);
            onChange(updatedData);
        }
    };

    const handleRemoveRow = (index: number) => {
        if (onChange && !readOnly) {
            const updatedData = [...dataSource];
            updatedData.splice(index, 1);
            onChange(updatedData);
        }
    };

    return (
        <List
            {...props}
            dataSource={dataSource}
            renderItem={(item: any, i) => (
                <List.Item
                    key={i}
                    actions={[
                        <RowActions
                            onRemoveRow={() => handleRemoveRow(i)}
                            disabled={readOnly}
                        />,
                    ]}
                >
                    <CellUI
                        value={item}
                        col={{
                            dataIndex: `item-${i}`,
                            fieldTypeId,
                            title: `Item ${i + 1}`
                        }}
                        onCellChange={handleCellChange}
                        readOnly={readOnly}
                        rowIndex={i}
                    />
                </List.Item>
            )}
            footer={
                <Button 
                    onClick={handleAddRow} 
                    type="link"
                    size='small'
                    icon={<PlusOutlined />}
                    disabled={readOnly}
                >
                    Add Item
                </Button>
            }
        />
    );
};

export default ListUI;
