/**
 * EntityContext provides entity-related data and actions to components.
 * It manages entities, entity records, selection, and CRUD operations.
 */
import React, { createContext, useState, useCallback, useMemo, FC, useContext } from 'react';
import { useBoundCollection } from '../use/data/useBoundCollection';
import { useBoundDoc } from '../use/data/useBoundDoc';
import { useAdd } from '../use/data/useAdd';
import { useRemove } from '../use/data/useRemove';
import { Entity, EntityRecord, DocId, UserDBRole } from '../types/System.types';
import { serverTimestamp } from 'firebase/firestore';
import { UserContext } from '../providers/UserProvider';

/** Context for managing entity-related state and operations */
export interface EntityContextValue {
  /** List of available entities */
  entities: Entity[];
  /** Whether user has entity edit permissions */
  hasEntityAccess: boolean;
  /** Currently selected entity */
  selectedEntity: Entity | null;
  /** Select an entity by ID */
  selectEntity: (entityId: string | undefined) => Promise<void>;
  /** Records belonging to selected entity */
  entityRecords: EntityRecord[];
  /** Currently selected record */
  selectedEntityRecord: EntityRecord | null;
  /** Select a record by ID */
  selectEntityRecord: (recordId: string | undefined) => Promise<void>;
  /** Update selected record */
  setRecord: (updates: Partial<EntityRecord>) => void;
  /** Delete selected record */
  deleteRecord: (recordId: DocId) => Promise<void>;
  /** Debounced record update */
  debouncedSetRecord: (updates: Partial<EntityRecord>) => void;
  /** Whether entity is locked for editing */
  entityLocked: boolean;
  /** Set entity locked state */
  setEntityLocked: (value: boolean) => void;
  /** Whether component is inside entity context */
  insideEntityContext: boolean;
  /** Create new entity record */
  createNewRecord: () => Promise<DocId>;
  /** Set selected entity ID */
  setSelectedEntityId: (entityId: string | undefined) => void;
  /** Parent entity context if nested within another entity */
  parentEntityContext: EntityContextValue | null;
}

export const EntityContext = createContext<EntityContextValue>({} as EntityContextValue);

/** Props for EntityProvider component */
interface EntityProviderProps {
  /** Initial entity ID to select */
  initialEntityId?: DocId;
  /** Initial record ID to select */
  initialRecordId?: DocId;
  /** Child components */
  children: React.ReactNode;
}

/**
 * Provider component for entity-related state and operations
 * @example
 * <EntityProvider initialEntityId="entity123">
 *   <YourComponent />
 * </EntityProvider>
 */
export const EntityProvider: FC<EntityProviderProps> = ({ children, initialEntityId, initialRecordId }) => {
  const [selectedEntityId, setSelectedEntityId] = useState<DocId | undefined>(initialEntityId);
  const [selectedEntityRecordId, setSelectedEntityRecordId] = useState<DocId | undefined>(initialRecordId);
  const [entityLocked, setEntityLocked] = useState(true);
  
  const { 
    user, 
    userGroups 
  } = useContext(UserContext);
  const parentEntityContext = useContext(EntityContext);

  const hasEntityAccess = useMemo(() => {
    return userGroups['entityAdmins'] === UserDBRole.editor ||
           userGroups['entityAdmins'] === UserDBRole.owner ||
           userGroups['entityEditors'] === UserDBRole.editor ||
           userGroups['entityEditors'] === UserDBRole.owner;
  }, [userGroups]);

  const { 
    data: entities = [], 
  } = useBoundCollection<Entity>({
    path: 'entities',
    initialOrderBy: [{ field: 'meta.created', direction: 'desc' }],
    initialLimit: 50,
  });

  const { 
    data: selectedEntity, 
  } = useBoundDoc<Entity>({
    path: 'entities',
    docId: selectedEntityId,
    enabled: !!selectedEntityId,
  });

  const { 
    data: entityRecords = [], 
    clear: clearEntityRecords,
  } = useBoundCollection<EntityRecord>({
    path: selectedEntityId ? `entities/${selectedEntityId}/records` : '',
    enabled: !!selectedEntityId,
    initialOrderBy: [{ field: 'name', direction: 'asc' }],
    initialLimit: 5000,  // TODO: SYD-352 replace this with algolia search
  });

  const { 
    data: selectedEntityRecord, 
    updateData: setRecord,
    debouncedSet: debouncedSetRecord,
    clear: clearRecord,
  } = useBoundDoc<EntityRecord>({
    path: selectedEntityId ? `entities/${selectedEntityId}/records` : '',
    docId: selectedEntityRecordId,
    enabled: !!selectedEntityId && !!selectedEntityRecordId,
  });

  const { add } = useAdd();
  const { remove } = useRemove();

  const selectEntity = useCallback(async (entityId: string | undefined) => {
    setSelectedEntityId(entityId);
    clearRecord();
    clearEntityRecords();
    setSelectedEntityRecordId(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectEntityRecord = useCallback(async (recordId: string | undefined) => {
    clearRecord();
    setSelectedEntityRecordId(recordId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createNewRecord = useCallback(async () => {
    if (!selectedEntityId) throw new Error('No entity selected');
    const newRecord = {
      name: 'New Record',
      fields: {}, 
      meta: {
        user: user?.uid,
        created: serverTimestamp(),
        modified: serverTimestamp(),
        status: 'active'
      }
    };
    const newRecordId = await add(`entities/${selectedEntityId}/records`, newRecord);
    selectEntityRecord(newRecordId);
    return newRecordId;
  }, [selectedEntityId, add, selectEntityRecord, user?.uid ]);

  const deleteRecord = useCallback(async (recordId: DocId) => {
    if (!selectedEntityId) throw new Error('No entity selected');
    await remove(`entities/${selectedEntityId}/records`, recordId);
    if (selectedEntityRecordId === recordId) {
      clearRecord();
      setSelectedEntityRecordId(undefined);
    }
  }, [selectedEntityId, selectedEntityRecordId, remove, clearRecord]);

  const contextValue = useMemo(
    () => ({
      entities,
      hasEntityAccess,
      selectedEntity: selectedEntity || null,
      selectEntity,
      entityRecords,
      selectedEntityRecord: selectedEntityRecord || null,
      selectEntityRecord,
      setRecord,
      deleteRecord,
      debouncedSetRecord,
      entityLocked,
      setEntityLocked,
      insideEntityContext: true,
      createNewRecord,
      setSelectedEntityId,
      parentEntityContext: parentEntityContext?.insideEntityContext ? parentEntityContext : null,
    }),
    [
      entities,
      hasEntityAccess,
      selectedEntity,
      selectEntity,
      entityRecords,
      selectedEntityRecord,
      selectEntityRecord,
      setRecord,
      deleteRecord,
      debouncedSetRecord,
      entityLocked,
      setEntityLocked,
      createNewRecord,
      setSelectedEntityId,
      parentEntityContext,
    ]
  );

  return <EntityContext.Provider value={contextValue}>{children}</EntityContext.Provider>;
};