/**
 * SYSTEM METRIC TYPES
 * Defines the data structure of the system metrics.
 */

// WARNING: If you add imports here these will also impact sub repositories (cloudRun, functions, etc), you might need to add imports there as well.
import { DocumentReference } from "firebase/firestore";
import { 
    DocId, 
    Description, 
    RecordStatus, 
    FormStateId, 
    FieldTypeId, 
    TimeType, 
    UserDBRole, 
    Meta 
} from './System.types';
import { IANAZone } from 'luxon';

/**
 * Enum for statistics types.
 * Defines the type of statistical operation to perform on metric values.
 */
export enum StatisticType {
    /** Counts the number of occurrences */
    Count = "count",
    /** Calculates the total sum of values */
    Sum = "sum",
    /** Computes the arithmetic mean of values */
    Average = "avg",
    /** Calculates the rate of change or frequency */
    Rate = "rate",
    /** Finds the smallest value in the dataset */
    Minimum = "min",
    /** Finds the largest value in the dataset */
    Maximum = "max"
}

/**
 * Enum for time periods.
 * Defines the granularity levels at which metrics can be aggregated.
 * Note: Hour and AllTime periods are removed until they can be made a metered cost.
 */
export enum TimePeriod {
    // Hour = "hour",  // removing this until we can make it a metered cost
    Day = "day",
    /** Weekly aggregation */
    Week = "week",
    /** Monthly aggregation */
    Month = "month",
    /** Quarterly aggregation */
    Quarter = "quarter",
    /** Yearly aggregation */
    Year = "year",
    // AllTime = "allTime"  // removing this until we can make it a metered cost
}

/**
 * Enum for frequency of calculation.
 * Defines how often metrics should be calculated.
 * This affects when the metric calculation job runs and what data it processes.
 */
export enum FrequencyOfCalculation {
    /** Calculate metrics daily */
    Daily = "daily",
    /** Calculate metrics weekly */
    Weekly = "weekly",
    /** Calculate metrics monthly */
    Monthly = "monthly",
    /** Calculate metrics quarterly */
    Quarterly = "quarterly",
    /** Calculate metrics yearly */
    Yearly = "yearly"
}

/**
 * Represents a type of analytics metric in the system, such as total invoice amount or customer count.
 * Is a DB document.
 * Stored in the collection `metrics/`.
 */
export type Metric = {
    /** Unique identifier for the metric 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** Description of the metric */
    description: Description;
    /** Status of the metric (e.g., active, deprecated) */
    status: RecordStatus;
    /** Source of the metric data */
    source: {
        /** The form type from which the metric data is derived */
        parentFormType: DocId;
        /** The specific version of the form to use */
        formVersion: FormStateId[];
        /** The field in the form that contains the value to be measured */
        valueField?: FieldTypeId;
        /** The field in the form that contains the timestamp for the metric */
        timeField: FieldTypeId;
    };
    
    /** Statistical operation to perform on the data */
    statistic: StatisticType;
    timeParams: {
        /** Minimum time resolution for the metric */
        minimumTimeResolution?: TimePeriod;
        /** Time periods for the metric */
        timePeriods: TimePeriod[];
        /** Start time of the time window for the metric */
        startTimeWindow?: TimeType;
        /** Local time zone for the metric */
        localTimeZone?: IANAZone;
        /** How often to calculate the metric */
        frequencyOfCalculation: FrequencyOfCalculation;
    };
    /** An array of grouping criteria for the metric */
    groupBy?: DocumentReference[];
    /** Additional settings for the metric */
    additionalSettings?: {
        /** Target value for the metric */
        metricTarget?: number;
        /** Units of the metric */
        metricUnit?: string;
        /** Alert conditions for the metric */
        alertConditions?: {
            [key: string]: {
                threshold: number;
                message: string;
            };
        };
        /** Placeholder for additional filters to apply to the metric data */
        filters?: {
            [key: string]: any;
        };
    };
    /** User roles associated with the metric */
    userRoles?: { 
        [key: string]: UserDBRole 
    };
    /** User group associated with the metric (singular) */
    userGroup?: string;
    /** Metadata for the metric */
    meta?: Meta;
    /** Subcollection `metrics/{metricId}/metricGroupedBy/` for the groupings of this metric */
};

/**
 * Represents a group within a Metric.
 * Stored in subcollection `metrics/{metricId}/metricGroupedBy/`.
 * Contains metadata about how metrics are grouped and references to the actual metric records.
 */
export type MetricGroupedBy = {
    /** 
     * Unique identifier for the metric grouped by.
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** Description of the metric grouped by */
    description: Description;
    /** Status of the metric grouped by (active/inactive) */
    status?: RecordStatus;
    /** Reference to the entity by which metrics are grouped */
    groupByReference?: DocumentReference;
    /** Metadata about the metric grouping */
    meta?: Meta;
};

/**
 * Represents a single metric record.
 * Stored in subcollection `metrics/{metricId}/metricGroupedBy/{metricGroupId}/metricRecords/`.
 * 
 * Required fields:
 * - metricTimeString: String representation of the metric time (YYYY-MM-DD)
 * - metricTimeStamp: Timestamp of the metric
 * - entityRefId: Reference ID for the entity this metric belongs to
 * - year: Year component (e.g. 2024)
 * - quarterOfYear: Quarter number (1-4)
 * - monthOfYear: Month number (1-12)
 * - weekOfYear: Week number (1-53)
 * - dayOfWeek: Day of week (0-6, Monday = 0)
 * - dayOfMonth: Day of month (1-31)
 * 
 * All other fields are optional.
 */
export type MetricRecord = {
    /** Unique identifier for the metric record (optional, system-generated) */
    docId?: DocId;
    /** String representation of the metric time (YYYY-MM-DD) */
    metricTimeString: string;
    /** Timestamp of the metric */
    metricTimeStamp: TimeType;
    /** Reference ID for the entity this metric belongs to */
    entityRefId: string;
    /** Year component (e.g. 2024) */
    year: number;
    /** Quarter number (1-4) */
    quarterOfYear: number;
    /** Month number (1-12) */
    monthOfYear: number;
    /** Week number (1-53) */
    weekOfYear: number;
    /** Day of week (0-6, Monday = 0) */
    dayOfWeek: number;
    /** Day of month (1-31) */
    dayOfMonth: number;
    /** Metadata for the metric record */
    meta?: Meta;
    /** Value of the metric record */
    metricValue?: number;
    /** Count of records in the metric */
    metricCount?: number;
    /** Units of the metric record inherited from the metric */
    metricUnits?: string;
    // Optional period metrics
    /** Day-level metric value */
    dayMetricValue?: number;
    /** Day-level record count */
    dayMetricCount?: number;
    /** Day-level time string */
    dayMetricString?: string;
    /** Day-level start time */
    dayMetricStartTime?: string;
    /** Week-level metric value */
    weekMetricValue?: number;
    /** Week-level record count */
    weekMetricCount?: number;
    /** Week-level time string */
    weekMetricString?: string;
    /** Week-level start time */
    weekMetricStartTime?: string;
    /** Month-level metric value */
    monthMetricValue?: number;
    /** Month-level record count */
    monthMetricCount?: number;
    /** Month-level time string */
    monthMetricString?: string;
    /** Month-level start time */
    monthMetricStartTime?: string;
    /** Quarter-level metric value */
    quarterMetricValue?: number;
    /** Quarter-level record count */
    quarterMetricCount?: number;
    /** Quarter-level time string */
    quarterMetricString?: string;
    /** Quarter-level start time */
    quarterMetricStartTime?: string;
    /** Year-level metric value */
    yearMetricValue?: number;
    /** Year-level record count */
    yearMetricCount?: number;
    /** Year-level time string */
    yearMetricString?: string;
    /** Year-level start time */
    yearMetricStartTime?: string;
};
