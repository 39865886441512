import { FC, ReactNode, createContext, useState, useEffect, useMemo } from 'react';
import { useBoundCollection } from '../use/data/useBoundCollection';
import { useBoundDoc } from '../use/data/useBoundDoc';
import { Form, FormType, UILayout, FormVersion } from '../types/System.types';
import { useCheckDocAccess } from '../use/data/useCheckDocAccess';

export interface FormContextType {
    selectedForm: ReturnType<typeof useBoundDoc<Form>>;
    selectedFormVersion: ReturnType<typeof useBoundDoc<FormVersion>>;
    formTypeOfSelectedForm: ReturnType<typeof useBoundDoc<FormType>>;
    selectedUILayout: ReturnType<typeof useBoundDoc<UILayout>>;
    formVersions: ReturnType<typeof useBoundCollection<FormVersion>>;
    uiLayouts: ReturnType<typeof useBoundCollection<UILayout>>;
    formTypes: ReturnType<typeof useBoundCollection<FormType>>;
    selectForm: (formId: string | undefined) => void;
    selectFormVersion: (formVersionId: string | undefined) => void;
    selectUILayout: (uiLayoutId: string | undefined) => void;
    formVersionAccess: ReturnType<typeof useCheckDocAccess>;
    insideFormContext: boolean;
}

export const FormContext = createContext<FormContextType>({} as FormContextType);

export const FormProvider: FC<{
    children: ReactNode;
}> = ({ 
    children, 
}) => {
    const [selectedFormId, selectForm] = useState<string | undefined>(undefined);
    const [selectedFormVersionId, selectFormVersion] = useState<string | undefined>(undefined);
    const [selectedUILayoutId, selectUILayout] = useState<string | undefined>(undefined);

    const selectedForm = useBoundDoc<Form>({
        path: 'forms',
        docId: selectedFormId,
        enabled: !!selectedFormId
    });

    const formVersions = useBoundCollection<FormVersion>({
        path: `forms/${selectedFormId}/formVersions`,
        enabled: !!selectedFormId
    });

    const selectedFormVersion = useBoundDoc<FormVersion>({
        path: `forms/${selectedFormId}/formVersions`,
        docId: selectedFormVersionId,
        enabled: !!selectedFormId && !!selectedFormVersionId
    });

    const formTypeOfSelectedForm = useBoundDoc<FormType>({
        path: 'formTypes',
        docId: selectedForm.data?.formType,
        enabled: !!selectedForm.data?.formType
    });

    const selectedUILayout = useBoundDoc<UILayout>({
        path: `formTypes/${selectedForm.data?.formType}/uiLayouts`,
        docId: selectedUILayoutId,
        enabled: !!selectedForm.data?.formType && !!selectedUILayoutId
    });

    const uiLayouts = useBoundCollection<UILayout>({
        path: `formTypes/${selectedForm.data?.formType}/uiLayouts`,
        enabled: !!selectedForm.data?.formType
    });

    const formVersionAccess = useCheckDocAccess(
        (!selectedForm.loading && !selectedFormVersion.loading && selectedForm.data && selectedFormVersionId)
            ? `forms/${selectedForm.data.docId}/formVersions`
            : null,
        (!selectedForm.loading && !selectedFormVersion.loading && selectedFormVersionId) || null
    );

    const formTypes = useBoundCollection<FormType>({
        path: 'formTypes',
        enabled: true
    });

    const value = {
        selectedForm,
        selectedFormVersion,
        formTypeOfSelectedForm,
        selectedUILayout,
        formVersions,
        uiLayouts,
        formTypes,
        selectForm,
        selectFormVersion,
        selectUILayout,
        formVersionAccess,
        insideFormContext: true
    };

    return (
        <FormContext.Provider value={value}>
            {children}
        </FormContext.Provider>
    );
};