import { useCallback, useContext, useState } from 'react';
import { Timestamp } from 'firebase/firestore';

import { 
    FormType, 
    AgentType, 
    Description, 
    UIElementType, 
    UILayout,
    FormStateId,
    EditorUIContainer,
    Entity
} from '../../types/System.types';
import { useLayoutEditorBase } from './useLayoutEditorBase';
import { useBoundCollection } from '../data/useBoundCollection';
import { useAdd } from '../data/useAdd';
import { useRemove } from '../data/useRemove';
import { useSet } from '../data/useSet';
import { useGet } from '../data/useGet';
import { UserContext } from '../../providers/UserProvider';

/**
 * Custom hook for managing form layout editing functionality.
 * Provides comprehensive state management and operations for form types including:
 * - Form type selection, creation, and deletion
 * - State management (Draft, Review, etc.)
 * - Layout modifications
 * - Description updates
 * - Allowed states and transitions
 * - Save operations
 * 
 * @returns {Object} Form editor state and methods
 * @property {FormType | null} structure - Currently selected form type structure
 * @property {FormType[]} structures - List of available form types
 * @property {string | undefined} selectedStructureId - ID of the currently selected structure
 * @property {Entity[]} entities - List of available entities
 */
export const useLayoutEditorForm = () => {
    const { user } = useContext(UserContext);
    const { add } = useAdd();
    const { remove } = useRemove();
    const { set } = useSet();
    const { get } = useGet();
    const [selectedStructureId, setSelectedStructureId] = useState<string | undefined>();
    const [structure, setStructure] = useState<FormType | null>(null);

    const { data: structures = [] } = useBoundCollection<FormType>({
        path: 'formTypes',
        enabled: true
    });

    const { data: entities = [] } = useBoundCollection<Entity>({
        path: 'entities',
        enabled: true
    });

    const baseEditor = useLayoutEditorBase({
        onSave: async (layout: UILayout) => {
            if (!structure || !selectedStructureId) throw new Error('No structure selected');
            
            const updates: Partial<FormType> = {
                defaultUILayout: layout,
                description: structure.description,
                possibleStates: layoutEditorState.possibleStates,
                allowedNextForms: layoutEditorState.allowedNextForms,
                meta: {
                    lastModified: Timestamp.now(),
                    userId: user?.uid,
                    agentType: AgentType.User
                }
            };

            await set('formTypes', selectedStructureId, updates);
        }
    });

    const { 
        dispatch, 
        layoutEditorState 
    } = baseEditor;

    /**
     * Clears the currently selected structure (Form Type)
     */
    const clearSelectedStructure = () => {
        setStructure(null);
    };

    /**
     * Updates the selected state and its allowed next states configuration
     * @param {FormStateId} stateId - The state to update
     * @param {FormStateId[]} allowedStates - Array of states that can follow this state
     */
    const handleStateChange = (stateId: FormStateId, allowedStates: FormStateId[]) => {
        dispatch({
            type: 'FORM_STATES_CONFIG',
            payload: {
                type: 'updateNextStates',
                stateId,
                allowedNextStates: allowedStates
            }
        });
    };

    /**
     * Updates a specific field in the structure description
     * @param {keyof Description} field - The description field to update
     * @param {string} value - New value for the field
     */
    const handleDescriptionChange = (field: keyof Description, value: string) => {
        if (!structure || !selectedStructureId) return;
        
        setStructure(prev => prev ? {
            ...prev,
            description: {
                ...prev.description,
                [field]: value
            }
        } : null);

        dispatch({
            type: 'UPDATE_DESCRIPTION',
            payload: {
                ...structure.description,
                [field]: value
            }
        });
    };

    /**
     * Updates the list of forms that can follow this form type
     * @param {FormType["docId"][]} forms - Array of form type IDs that can follow
     */
    const handleAllowedFormsChange = (forms: FormType["docId"][]) => {
        dispatch({
            type: 'FORM_STATES_CONFIG',
            payload: {
                type: 'updateNextForms',
                forms
            }
        });
    };

    /**
     * Updates the list of possible states for this form type
     * @param {FormStateId[]} states - Array of states to set as possible states
     */
    const handleStatesUpdate = (states: FormStateId[]) => {
        dispatch({
            type: 'FORM_STATES_CONFIG',
            payload: {
                type: 'update',
                states
            }
        });
    };

    /**
     * Retrieves the entry and exit rules for a specific state
     * @param {string} stateName - Name of the state to get rules for
     * @returns {Array<{id: string, type: 'Entry' | 'Exit', [key: string]: any}>} Array of rules with their type
     */
    const getStateRules = (stateName: string) => {
        if (!layoutEditorState.possibleStates) return [];
        const currentState = layoutEditorState.possibleStates[stateName];
        return [
            ...(currentState?.entryRules ? Object.entries(currentState.entryRules).map(([id, rule]) => ({
                id,
                type: 'Entry',
                ...rule
            })) : []),
            ...(currentState?.exitRules ? Object.entries(currentState.exitRules).map(([id, rule]) => ({
                id,
                type: 'Exit',
                ...rule
            })) : [])
        ];
    };

    /**
     * Checks if a state can be safely removed
     * @param {FormStateId} stateId - ID of the state to check
     * @returns {{ canDelete: boolean, reason?: string }} Object indicating if state can be removed and reason if not
     */
    const canRemoveState = (stateId: FormStateId) => {
        if (!layoutEditorState.possibleStates) {
            return { canDelete: false, reason: 'Form type not loaded' };
        }

        if (stateId === 'draft') {
            return { canDelete: false, reason: 'Cannot remove the draft state' };
        }

        const states = layoutEditorState.possibleStates;
        const isStateSelected = stateId in states;

        if (!isStateSelected) {
            return { canDelete: true };
        }

        if (Object.keys(states).length <= 1) {
            return { canDelete: false, reason: 'Cannot remove the last state' };
        }

        const isReferenced = Object.entries(states).some(([otherStateId, state]) => 
            otherStateId !== stateId && state.allowedNextStates?.includes(stateId)
        );

        if (isReferenced) {
            return { canDelete: false, reason: 'State is referenced by other states' };
        }

        return { canDelete: true };
    };

    /**
     * Selects a form type structure for editing and loads its configuration
     * @param {string} structureId - ID of the form type to select
     * @returns {Promise<boolean>} Promise that resolves with true if selection was successful, false otherwise
     * @throws {Error} If the structure cannot be loaded
     */
    const selectStructure = useCallback(async (structureId: string) => {
        try {
            const formType = await get<FormType>('formTypes', structureId);
            if (!formType) {
                return false;
            }
            
            setSelectedStructureId(structureId);
            setStructure(formType);

            dispatch({
                type: 'SELECT_STRUCTURE',
                payload: {
                    layout: formType.defaultUILayout,
                    description: formType.description,
                    possibleStates: formType.possibleStates,
                    allowedNextForms: formType.allowedNextForms
                }
            });
            return true;
        } catch (error) {
            console.error('Error selecting form type:', error);
            throw error;
        }
    }, [get, dispatch]);

    /**
     * Creates a new form type structure with default configuration
     * @returns {Promise<string>} Promise that resolves with the ID of the newly created form type
     * @throws {Error} If user is not logged in or creation fails
     */
    const createStructure = useCallback(async () => {
        if (!user) throw new Error('User must be logged in');

        try {
            const newFormType: Omit<FormType, 'docId'> = {
                defaultUILayout: {
                    structure: {
                        uiElementType: UIElementType.Column,
                        children: []
                    }
                },
                description: {
                    shortLabel: 'New Form Type',
                    shortDescription: '',
                    longLabel: '',
                    longDescription: ''
                },
                possibleStates: {
                    draft: {
                        allowedNextStates: ['draft']
                    }
                },
                allowedNextForms: []
            };

            const newFormTypeId = await add('formTypes', newFormType);
            setSelectedStructureId(newFormTypeId);
            dispatch({ 
                type: 'SELECT_LAYOUT', 
                payload: {
                    structure: {
                        uiElementType: UIElementType.Column,
                        children: [],
                        dndId: 'e0'
                    } as EditorUIContainer,
                }
            });
            return newFormTypeId;
        } catch (error) {
            console.error('Error creating form type:', error);
            throw error;
        }
    }, [user, add, dispatch]);

    /**
     * Deletes a form type structure and clears selection
     * @param {string} structureId - ID of the form type to delete
     * @returns {Promise<boolean>} Promise that resolves with true if deletion was successful, false otherwise
     */
    const deleteStructure = useCallback(async (structureId: string) => {
        try {
            await remove('formTypes', structureId);
            clearSelectedStructure();
            setSelectedStructureId(undefined);
            dispatch({ type: 'RESET_LAYOUT' });
            return true;
        } catch (error) {
            console.error('Error deleting form type:', error);
            return false;
        }
    }, [remove, dispatch]);

    /**
     * Updates the description of the current form type
     * @param {keyof Description} field - The description field to update
     * @param {string} value - New value for the field
     * @returns {Promise<boolean>} Promise that resolves with true if update was successful, false otherwise
     */
    const updateDescription = useCallback(async (field: keyof Description, value: string) => {
        if (!structure || !selectedStructureId) return false;
        
        try {
            await set('formTypes', selectedStructureId, {
                description: {
                    ...structure.description,
                    [field]: value
                },
                meta: {
                    lastModified: Timestamp.now(),
                    userId: user?.uid,
                    agentType: AgentType.User
                }
            });
            return true;
        } catch (error) {
            console.error('Error updating form type description:', error);
            throw error;
        }
    }, [structure, selectedStructureId, set]);

    /**
     * Clears the current structure selection and resets layout state
     */
    const clearSelection = () => {
        setSelectedStructureId(undefined);
        dispatch({ type: 'RESET_LAYOUT' });
    };

    return {
        ...baseEditor,
        structure,
        structures,
        clearSelectedStructure,
        selectStructure,
        selectedStructureId,
        createStructure,
        deleteStructure,
        updateDescription,
        entities,
        handleStateChange,
        handleDescriptionChange,
        handleAllowedFormsChange,
        handleStatesUpdate,
        getStateRules,
        canRemoveState,
        clearSelection
    };
}; 