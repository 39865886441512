import { Firestore, getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { firebaseConfig, environmentType } from '../config';

/**
 * Connect Firestore emulator on development environment
 * @param firestore Firestore instance
 */
export const connectFirestoreEmulatorOnDev = async (firestore: Firestore) => {
  if (environmentType === 'development') {
    try {
      const firestoreResponse = await fetch('http://localhost:8080');
      if (firestoreResponse.ok) {
        connectFirestoreEmulator(firestore, 'localhost', 8080);
        console.log('Connected to Firebase Firestore DB emulator');
      }
    } catch (error) {
      console.log('Firebase Firestore DB emulator is not running');
    }
  }
};

// Initialize Firebase app
export const app = initializeApp(firebaseConfig);

/**
 * Initialize Firestore instance
 */
export const firestore: Firestore = getFirestore(app);

// Connect to Firestore emulator if in development
connectFirestoreEmulatorOnDev(firestore);