import { useBoundCollection } from './data/useBoundCollection';
import { Group, User, UserDBRole, TimeType } from '../types/System.types';
import { useContext, useEffect, useState, useCallback } from 'react';
import { UserContext } from '../providers/UserProvider';
import { FormContext } from '../providers/FormProvider';
import { serverTimestamp } from 'firebase/firestore';

interface UseFormAccessProps {
    formId: string;
    enabled?: boolean;
}

export interface UserAccessItem {
    docId: string;
    email: string;
    displayName: string;
    role: UserDBRole;
}

export interface SearchUserItem {
    docId: string;
    email: string;
    displayName: string;
}

export const useFormAccess = ({ formId, enabled = true }: UseFormAccessProps) => {
    const { selectedForm } = useContext(FormContext);
    const { user } = useContext(UserContext);
    const [searchResults, setSearchResults] = useState<SearchUserItem[]>([]);

    const groups = useBoundCollection<Group>({
        path: 'groups',
        enabled,
    });

    const users = useBoundCollection<User>({
        path: 'users',
        enabled,
    });

    const isOwner = selectedForm.data?.userRoles?.[user?.uid || ''] === UserDBRole.owner;

    const updateAccess = useCallback(async (updates: {
        userRoles?: { [key: string]: UserDBRole };
        userGroup?: Group['docId'];
    }) => {
        if (!formId || !isOwner) return;

        try {
            const currentForm = selectedForm.data;
            if (!currentForm) throw new Error('Form not found');

            const updatedForm = {
                ...currentForm,
                ...updates,
                meta: {
                    ...currentForm.meta,
                    lastModified: serverTimestamp() as TimeType,
                    userId: user?.uid,
                }
            };

            await selectedForm.set(updatedForm, { merge: false });
            return true;
        } catch (error) {
            console.error('Error updating form access:', error);
            throw error;
        }
    }, [formId, isOwner, selectedForm, user?.uid]);

    const searchUsers = useCallback((searchTerm: string) => {
        const currentUserIds = Object.keys(selectedForm.data?.userRoles || {});
        
        const results = users.data
            .filter(user => {
                const matchesSearch = (
                    user.docId === searchTerm || 
                    (typeof user?.email === 'string' && user.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (typeof user?.name === 'string' && user.name.toLowerCase().includes(searchTerm.toLowerCase()))
                );
                const notAlreadyAdded = !currentUserIds.includes(user.docId || '');
                return (!searchTerm || matchesSearch) && notAlreadyAdded;
            })
            .map(user => ({
                docId: user.docId || '',
                email: user.email,
                displayName: user.name || '',
            }))
            .slice(0, 10); 

        setSearchResults(results);
    }, [users.data, selectedForm.data?.userRoles]);

    useEffect(() => {
        if (users.data) {
            searchUsers('');
        }
    }, [users.data, searchUsers]);

    const userAccessList = useCallback((): UserAccessItem[] => {
        if (!users.data || !selectedForm.data?.userRoles) return [];
        
        return Object.entries(selectedForm.data.userRoles)
            .map(([userId, role]) => {
                const userDetails = users.data.find(u => u.docId === userId);
                if (!userDetails) return null;
                
                return {
                    docId: userId,
                    email: userDetails.email,
                    displayName: userDetails.name || '',
                    role,
                };
            })
            .filter((user): user is UserAccessItem => user !== null);
    }, [users.data, selectedForm.data?.userRoles]);

    return {
        form: selectedForm.data,
        groups: groups.data,
        users: userAccessList(),
        searchResults,
        isOwner,
        updateAccess,
        searchUsers,
    };
}; 