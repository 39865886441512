import { memo, useCallback, useMemo } from 'react';
import {
    Input,
    InputNumber,
    Typography,
    DatePicker,
    Switch,
    TimePicker,
    Select,
} from 'antd';
import { Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';

import { 
    Field, 
    UIElementType, 
    UIField, 
    fieldType,
    EntityRecord,
} from '../../types/System.types';
import { QosValues } from '../../use/orgs/useClwQos';
import type { ValidationStatus } from './FieldUI';
import TableUI from './tables/TableUI';
import defineFieldTypeFromDataType from './defineFieldTypeFromDataType';
import { AttachmentUI } from '../atoms/AttachmentUI';
import { MaxLengths } from '../../types/System.Parameters.types';
import EntityRecordSelect from '../atoms/EntityRecordSelect';
import ListUI from './tables/ListUI';
import { QosComponent } from '../orgs/ClwQos';

/**
 * Represents the time range structure with start and end timestamps
 */
type TimeRange = {
    start: Timestamp;
    end: Timestamp;
};

/**
 * Props for the ControlUI component
 */
interface ControlUIProps {
    /** The field definition */
    field: Field | undefined;
    /** UI element type */
    uiElementType?: UIElementType;
    /** UI configuration for the field */
    uiField: UIField;
    /** Callback when field value changes */
    onChange: (value: any, entityRecord?: EntityRecord | null) => void;
    /** Optional field ID */
    id?: string;
    /** Whether the field is read-only */
    readOnly?: boolean;
    /** Callback when field gains focus */
    onFocus?: () => void;
    /** Callback when field loses focus */
    onBlur?: () => void;
    /** Validation status of the field */
    status?: ValidationStatus;
    /** Current value of the field */
    value: any;
    /** Optional entity ID for entity reference controls */
    entityId?: string;
    /** Additional props passed to the control */
    [key: string]: any;
}

/**
 * A dynamic form control component that renders different input types based on field configuration.
 * Supports text, numbers, dates, selections, tables, and custom field types.
 */
const ControlUI = memo(({
    id,
    uiElementType,
    field,
    uiField,
    onChange,
    readOnly = false,
    onFocus,
    onBlur,
    status,
    value,
    entityId,
    ...props
}: ControlUIProps) => {
    const effectiveType = useMemo(() => {
        const definedType = fieldType[uiField.fieldTypeId]?.fieldDataTypeId;
        
        if (uiField.fieldTypeId === 'undefined' || !definedType) {
            return defineFieldTypeFromDataType(field?.value ?? value);
        }

        return definedType;
    }, [uiField.fieldTypeId, field?.value, value]);

    const commonProps = useMemo(() => ({
        id: id || uiField.fieldId,
        value,
        onFocus,
        onBlur,
        status,
        variant: 'filled' as const,
        disabled: readOnly,
        ...uiField.props,
        ...props
    }), [id, uiField.fieldId, value, onFocus, onBlur, status, readOnly, uiField.props, props]);

    const renderControl = useCallback(() => {
        if (uiElementType === UIElementType.EntityReference) {
            if (!entityId) return null;

            return (
                <EntityRecordSelect 
                    entityId={entityId}
                    recordId={value}
                    onChange={onChange}
                    disabled={readOnly}
                    style={uiField.props?.style}
                    {...uiField.props}
                />
            );
        }

        switch(effectiveType) {
            case 'shortText':
            case 'text':
            case 'email':
            case 'phone':
            case 'password':
                return <Input 
                    {...commonProps} 
                    maxLength={MaxLengths.shortText}
                    onChange={(e) => onChange(e.target.value)} 
                />;

            case 'longText':
            case 'richText':
            case 'json':
            case 'xml':
            case 'html':
            case 'ipAddress':
            case 'address':
                return <Input.TextArea 
                    {...commonProps} 
                    maxLength={MaxLengths.longText}
                    onChange={(e) => onChange(e.target.value)} 
                    autoSize 
                />;

            case 'url':
                return (
                    <Input.Search
                        {...commonProps}
                        type='url'
                        enterButton="Open"
                        onChange={(e) => onChange(e.target.value)}
                        onSearch={(value) => {
                            if (value) {
                                window.open(fieldType[uiField.fieldTypeId]?.parser?.(value), '_blank', 'noopener,noreferrer');
                            }
                        }}
                    />
                );

            case 'quantity':
            case 'number':
            case 'currency':
            case 'percentage':
                const thisFieldType = fieldType[uiField.fieldTypeId];
                const formatter = thisFieldType?.formatter;
                const parser = thisFieldType?.parser;
                const prefix = thisFieldType?.prefix;
                const suffix = thisFieldType?.suffix;
                return (
                    <InputNumber
                        {...commonProps}
                        formatter={formatter}
                        parser={parser}
                        prefix={prefix && (
                            <Typography.Text type="secondary">
                                {prefix}
                            </Typography.Text>
                        )}
                        suffix={suffix && (
                            <Typography.Text type="secondary">
                                {suffix}
                            </Typography.Text>
                        )}
                        onChange={(value) => onChange(value)}
                    />
                );

            case 'yesNo':
                return (
                    <Switch
                        {...commonProps}
                        checked={value as boolean}
                        onChange={(checked) => onChange(checked)}
                    />
                );

            case 'singleSelect':
                return (
                    <Select
                        {...commonProps}
                        value={value}
                        onChange={(value) => onChange(value)}
                    />
                );

            case 'multiSelect':
                return (
                    <Select
                        {...commonProps}

                        value={value as (string | number)[]}
                        onChange={(selectedValues, options) => onChange(selectedValues)}
                    />
                );

            case 'date':
                return (
                    <DatePicker 
                        {...commonProps}
                        value={value ? dayjs(value.toDate()) : null}
                        onChange={(date) => onChange(date ? Timestamp.fromDate(date.toDate()) : null)}
                    />
                );

            case 'dateTime':
                return (
                    <DatePicker 
                        {...commonProps}
                        showTime
                        value={value ? dayjs(value.toDate()) : null}
                        onChange={(date) => onChange(date ? Timestamp.fromDate(date.toDate()) : null)}
                    />
                );

            case 'time':
                return (
                    <TimePicker 
                        {...commonProps}
                        value={value ? dayjs(value.toDate()) : null}
                        onChange={(time) => onChange(time ? Timestamp.fromDate(time.toDate()) : null)}
                    />
                );
            case 'attachments':
                return (
                    <AttachmentUI
                        {...commonProps}
                        onChange={onChange}
                        folder={uiField.props?.folder || 'attachments'}
                    />
                );
            case 'timeRange':
            case 'dateRange':
            case 'dateTimeRange':
                const range = value as TimeRange;
                return (
                    <DatePicker.RangePicker
                        {...commonProps}
                        allowEmpty={true}
                        showTime={effectiveType === 'timeRange' || effectiveType === 'dateTimeRange'}
                        value={range ? [dayjs(range.start.toDate()), dayjs(range.end.toDate())] : null}
                        onChange={(dates) => {
                            if (dates && dates[0] && dates[1]) {
                                onChange({
                                    start: Timestamp.fromDate(dates[0].toDate()),
                                    end: Timestamp.fromDate(dates[1].toDate())
                                });
                            } else {
                                onChange(null);
                            }
                        }}
                    />
                );

            case 'table':
                return (
                    <TableUI
                        id={id || uiField.fieldId}
                        dataSource={value as any[]}
                        columns={uiField.columns || []}
                        onChange={onChange}
                        readOnly={readOnly}
                        {...uiField.props}
                        isBOMTable={uiField.fieldTypeId === 'billOfMaterials'}
                    />
                );

            case 'list':
                return (
                    <ListUI
                        id={id || uiField.fieldId}
                        dataSource={value as any[]}
                        onChange={onChange}
                        readOnly={readOnly}
                        {...uiField.props}
                    />
                );

            case 'custom':
                if (uiField.fieldId === 'qos') {
                    return (
                        <QosComponent
                            qos={value as QosValues}
                            onChange={onChange}
                            {...commonProps}
                        />
                    );
                }
                return null;

            default:
                console.error(`Unknown field type: ${effectiveType}`);
                return (
                    <Typography.Text type="danger">
                        Unable to render field type: {effectiveType}
                    </Typography.Text>
                );
        }
    }, [id, effectiveType, commonProps, value, onChange, uiField.fieldId, uiField.fieldTypeId, uiField.columns, uiField.props, readOnly, entityId, uiElementType]);

    return renderControl();
});

ControlUI.displayName = 'ControlUI';

export default ControlUI; 