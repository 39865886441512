import { useBoundDoc } from './data/useBoundDoc';
import { User, UserDBRole } from '../types/System.types';

interface UseUserProps {
    /** The user ID to fetch */
    userId: string | undefined;
    /** Whether to enable the hook */
    enabled?: boolean;
}

interface UseUserReturn {
    /** The bound user document data and operations */
    user: ReturnType<typeof useBoundDoc<User>>;
    /** Update user's name */
    updateName: (newName: string) => void;
}

/**
 * Hook to fetch and bind to a user's properties
 * @param userId - The ID of the user to fetch
 * @param enabled - Whether to enable the hook (defaults to true if userId is defined)
 * @returns The user data and update functions
 */
export const useUser = ({ 
    userId, 
    enabled = !!userId 
}: UseUserProps): UseUserReturn => {
    const user = useBoundDoc<User>({
        path: 'users',
        docId: userId,
        enabled
    });

    /**
     * Update user's name
     */
    const updateName = (newName: string) => {
        if (user.data) {
            user.debouncedSet({ name: newName });
        }
    };

    return {
        user,
        updateName
    };
}; 