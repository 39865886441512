import { FC, useState } from 'react';
import { Table, Typography, Grid } from 'antd';
import { Metric, TimePeriod } from '../../types/System.types';

interface MetricsTableProps {
  timeSeriesData: {
    dates: string[];
    dateHover: string[];
    values: { [date: string]: { value: number; count: number } };
  } | null;
  selectedMetric: Metric | null;
  selectedTimePeriod: TimePeriod | null;
  loading: boolean;
}

const { Text } = Typography;
const { useBreakpoint } = Grid;

const MetricsTable: FC<MetricsTableProps> = ({
  timeSeriesData,
  selectedMetric,
  selectedTimePeriod,
  loading,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const screens = useBreakpoint();

  const formatMetricValue = (value: number, metric: Metric | null) => {
    if (!metric) return value.toString();

    const unit = metric.additionalSettings?.metricUnit;
    switch (unit) {
      case 'percentage':
        return `${(value * 100).toFixed(2)}%`;
      case 'currency':
        return `$${value.toLocaleString()}`;
      case 'time':
        return `${value.toFixed(2)} mins`;
      default:
        return value.toLocaleString();
    }
  };

  return (
    <Table
      dataSource={
        timeSeriesData
          ? timeSeriesData.dates
              .slice((currentPage - 1) * pageSize, currentPage * pageSize)
              .map((date) => ({
                key: date,
                date: timeSeriesData.dateHover[
                  timeSeriesData.dates.indexOf(date)
                ],
                value: formatMetricValue(
                  timeSeriesData.values[date].value,
                  selectedMetric
                ),
                count: timeSeriesData.values[date].count,
              }))
          : []
      }
      columns={[
        {
          title: selectedTimePeriod
            ? selectedTimePeriod.charAt(0).toUpperCase() +
              selectedTimePeriod.slice(1)
            : 'Date',
          dataIndex: 'date',
          key: 'date',
          sorter: (a, b) => a.key.localeCompare(b.key),
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: (
            <Text style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
              {screens.sm ? selectedMetric?.description.shortLabel : 'Value'}
            </Text>
          ),
          dataIndex: 'value',
          key: 'value',
          sorter: (a, b) => {
            const aVal = parseFloat(a.value.replace(/[^0-9.-]+/g, ''));
            const bVal = parseFloat(b.value.replace(/[^0-9.-]+/g, ''));
            return aVal - bVal;
          },
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'Count',
          dataIndex: 'count',
          key: 'count',
          sorter: (a, b) => a.count - b.count,
          sortDirections: ['ascend', 'descend'],
        },
      ]}
      loading={loading}
      style={{ width: '100%' }}
      pagination={{
        current: currentPage,
        pageSize: pageSize,
        total: timeSeriesData?.dates.length || 0,
        showSizeChanger: true,
        showTotal: (total) => `Total ${total} items`,
        onChange: (page, size) => {
          setCurrentPage(page);
          setPageSize(size);
        },
      }}
      scroll={{ x: 'max-content' }}
    />
  );
};

export default MetricsTable;
