/**
 * @fileoverview Renders a read-only meta field UI component for Form metadata.
 */

import React, { FC } from 'react';
import { Typography, Tooltip, Input } from 'antd';
import { 
    UIMetaField, 
    Form, 
    AgentType, 
    FormVersion,
    EntityRecord,
    Entity,
    Meta
} from '../../types/System.types';
import { MetaFieldUIDescription } from '../../types/System.Parameters.types';
import { useUser } from '../../use/useUser';
import FlexCol from '../atoms/FlexCol';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface MetaFieldUIProps {
    /** Configuration object containing meta field properties */
    uiMetaField: UIMetaField;
    /** Form data containing the meta values */
    itemData: FormVersion | EntityRecord | Entity | Form;
}

/**
 * Formats a meta value based on its type for display
 */
const formatMetaValue = (value: any, metaFieldId: keyof Meta): string => {
    if (!value) return '';
    
    if (value.toDate) {
        return value.toDate().toLocaleString();
    }
    
    if (Object.values(AgentType).includes(value)) {
        return value.toString();
    }
    
    return value.toString();
};

/**
 * A read-only component that displays a single meta value from a Form.
 * 
 * @component
 * @memo
 */
const MetaFieldUI: FC<MetaFieldUIProps> = React.memo(({
    uiMetaField,
    itemData,
}) => {
    const { metaFieldId } = uiMetaField;
    const metaValue = itemData.meta?.[metaFieldId];
    const formattedValue = formatMetaValue(metaValue, metaFieldId);

    const { 
        user 
    } = useUser({ 
        userId: metaFieldId === 'userId' ? metaValue : undefined,
        enabled: metaFieldId === 'userId' && !!metaValue
    });

    const name = user?.data?.name ?? '';
    const email = user?.data?.email ?? '';

    const displayValue = metaFieldId === 'userId' && name
        ? `${name}(${email})`
        : formattedValue;

    const label = metaFieldId in MetaFieldUIDescription 
        ? MetaFieldUIDescription[metaFieldId as keyof typeof MetaFieldUIDescription].shortLabel 
        : metaFieldId;
    const tooltip = metaFieldId in MetaFieldUIDescription
        ? MetaFieldUIDescription[metaFieldId as keyof typeof MetaFieldUIDescription].longDescription
        : `${metaFieldId} value`;
    
    return (
        <FlexCol gap={0} {...uiMetaField.props}>
            <Tooltip title={tooltip}>
                <Text type="secondary">
                    {label} <InfoCircleOutlined />
                </Text>
            </Tooltip>
            <Input 
                value={displayValue} 
                readOnly 
                variant='filled' 
                style={{
                    cursor: 'default',
                }}
            />
        </FlexCol>
    );
});

MetaFieldUI.displayName = 'MetaFieldUI';

export default MetaFieldUI; 