import { useState, useEffect } from 'react';
import { Tabs, Typography } from 'antd';
import { useNavigate, useLocation, matchPath } from 'react-router-dom';

import Navb from '../components/Navb';
import FlexPage from '../components/atoms/FlexPage';
import FlexContent from '../components/atoms/FlexContent';
import UserSettingsTab from '../components/settings/UserSettingsTab';
import GroupSettingsTab from '../components/settings/GroupSettingsTab';
import LayoutEditorTab from '../components/settings/LayoutEditorTab';
import AnalyticsEditorTab from '../components/settings/AnalyticsEditorTab';

const { Title } = Typography;

const TAB_ROUTES = [
  '/settings/user',
  '/settings/groups',
  '/settings/layout-editor/*',
  '/settings/entity-layout-editor/*',
  '/settings/workflow-editor/*',
  '/settings/analytics-editor/*',
];

const SettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [path, setPath] = useState('user');

  useEffect(() => {
    const match = TAB_ROUTES.find(route => 
      matchPath(route, location.pathname)
    );

    const basePath = match ? 
      match.split('/')[2] : 'user';
    
    setPath(basePath);
  }, [location]);

  const tabItems = [
    {
      key: 'settings',
      label: <Title 
        level={4}
        style={{
          margin: 0,
          cursor: 'default',
        }}
      >
        Settings
      </Title>,
      children: null,
      disabled: true,
    },
    {
      key: 'user',
      label: 'User',
      children: <UserSettingsTab />,
    },
    {
      key: 'groups',
      label: 'Groups',
      children: <GroupSettingsTab />,
    },
    {
      key: 'layout-editor',
      label: 'Forms',
      children: <LayoutEditorTab type="form" />,
    },
    {
      key: 'entity-layout-editor',
      label: 'Records',
      children: <LayoutEditorTab type="entity" />,
    },
    {
      key: 'analytics-editor',
      label: 'Analytics',
      children: <AnalyticsEditorTab />,
    },
  ];

  const handleTabChange = (key: string) => {
    navigate(`/settings/${key}`);
    setPath(key);
  };

  return (
    <FlexPage>
      <Navb />
      <FlexContent>
        <Tabs
          activeKey={path}
          onChange={handleTabChange}
          items={tabItems}
          style={{
            marginTop: -15,
            width: '100%',
          }}
        />
      </FlexContent>
    </FlexPage>
  );
};

export default SettingsPage; 