// WARNING: If you add imports here these will also impact sub repositories (cloudRun, functions, etc), you might need to add imports there as well.
import { FieldType } from './System.types';
import { Timestamp } from "firebase/firestore";
import { isEmail, isURL, isIP, isJSON, isStrongPassword } from 'validator';
import * as dayjs from 'dayjs';

const validateDate = (value: any): boolean => {
    if (!value) return true;
    if (value instanceof Timestamp) return true;
    if (dayjs.isDayjs(value)) return value.isValid();
    if (value instanceof Date) return !isNaN(value.getTime());
    return false;
};


/**
 * Represents the foundational field data types in the system.
 * These are used to build the built-in data types, and the org-level-config field types.
 */
export type SystemFieldTypeId =
    | 'text'
    | 'shortText'
    | 'longText'
    | 'richText'
    | 'percentage'
    | 'currency'
    | 'quantity'
    | 'number'
    | 'yesNo'
    | 'time'
    | 'date'
    | 'dateTime'
    | 'timeRange'
    | 'dateRange'
    | 'dateTimeRange'
    | 'multiSelect'
    | 'singleSelect'
    | 'address'
    | 'table'
    | 'list'
    | 'null'
    | 'attachments'
    | 'link'
    | 'email'
    | 'phone'
    | 'url'
    | 'ipAddress'
    | 'json'
    | 'xml'
    | 'html'
    | 'password'
    | 'custom'
    | 'undefined';

/**
 * Represents the foundational field data types in the system.
 * These are used to build the built-in data types, and the org-level-config field types.
 */
export const fieldDataType: Record<SystemFieldTypeId, FieldType> = {
    shortText: {
        fieldDataTypeId: 'shortText',
        description: {
            shortLabel: 'Short Text',
            shortDescription: 'A single line of text',
            longLabel: 'Short Text Field',
            longDescription: 'Stores a single line of text, suitable for names, titles, or short descriptions.'
        },
        errorMessage: 'Must be less than 25 characters',
    },
    text: {
        fieldDataTypeId: 'text',
        description: {
            shortLabel: 'Text',
            shortDescription: 'A single line of text',
            longLabel: 'Text Field',
            longDescription: 'Stores a single line of text, suitable for names, titles, or short descriptions.'
        },
        errorMessage: 'Must be less than 100 characters',
    },
    longText: {
        fieldDataTypeId: 'longText',
        description: {
            shortLabel: 'Long Text',
            shortDescription: 'Multiple lines of text',
            longLabel: 'Long Text Field',
            longDescription: 'Stores multiple lines of text, suitable for longer descriptions, comments, or notes.'
        },
        errorMessage: 'Must be less than 5000 characters',
    },
    richText: {
        fieldDataTypeId: 'richText',
        description: {
            shortLabel: 'Rich Text',
            shortDescription: 'Formatted text',
            longLabel: 'Rich Text Field',
            longDescription: 'Stores formatted text with styling and potentially embedded media.'
        },
        errorMessage: 'Must be less than 5000 characters',
    },
    percentage: {
        fieldDataTypeId: 'percentage',
        description: {
            shortLabel: 'Percentage',
            shortDescription: 'Percentage value',
            longLabel: 'Percentage Field',
            longDescription: 'Stores a percentage value.'
        },
        validation: (value: any) => !value || (typeof value === 'number' && value >= -1000 && value <= 1000),
        errorMessage: 'Input must be between -1000 and 1000',
        formatter: (value: any) => {
            if (!value) return '';
            const num = Number(value);
            const hasDecimals = num % 1 !== 0;
            const formattedValue = hasDecimals ? num.toFixed(1) : num.toString();
            return formattedValue;
        },
        parser: (value: string | undefined) => value ? value.replace(/%/g, '') : undefined,
        prefix: '%',
    },
    currency: {
        fieldDataTypeId: 'currency',
        description: {
            shortLabel: 'Currency',
            shortDescription: 'Monetary value',
            longLabel: 'Currency Field',
            longDescription: 'Stores a monetary value with currency information.'
        },
        validation: (value: any) => !value || (typeof value === 'number' && value >= -1000000000 && value <= 1000000000),
        errorMessage: 'Input must be between -1,000,000,000 and 1,000,000,000',
        formatter: (value: any) => {
            if (!value) return '';
            const num = Number(value);
            const hasDecimals = num % 1 !== 0;
            const formattedValue = hasDecimals ? num.toFixed(2) : num.toString();
            return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        parser: (value: string | undefined) => value ? parseFloat(value.replace(/[$,]/g, '')) : undefined,
        prefix: '$',
    },
    quantity: {
        fieldDataTypeId: 'quantity',
        description: {
            shortLabel: 'Quantity',
            shortDescription: 'Numeric quantity',
            longLabel: 'Quantity Field',
            longDescription: 'Stores a numeric quantity, typically used for inventory or measurements.'
        },
        validation: (value: any) => !value || (typeof value === 'number' && value >= 0),
        errorMessage: 'Must be a positive number',
        formatter: (value: any) => value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        parser: (value: string | undefined) => value ? value.replace(/,/g, '') : undefined,
    },
    number: {
        fieldDataTypeId: 'number',
        description: {
            shortLabel: 'Number',
            shortDescription: 'Numeric value',
            longLabel: 'Number Field',
            longDescription: 'Stores a numeric value, which can be an integer or a decimal number.'
        },
        validation: (value: any) => !value || typeof value === 'number',
        errorMessage: 'Must be a valid number',
        formatter: (value: any) => value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        parser: (value: string | undefined) => value ? (value.replace(/,/g, '')) : undefined
    },
    yesNo: {
        fieldDataTypeId: 'yesNo',
        description: {
            shortLabel: 'Yes/No',
            shortDescription: 'Boolean value',
            longLabel: 'Yes/No Field',
            longDescription: 'Stores a boolean value, typically represented as Yes or No.'
        },
        validation: (value: any) => typeof value === 'boolean',
        errorMessage: 'Must be true or false',
    },
    time: {
        fieldDataTypeId: 'time',
        description: {
            shortLabel: 'Time',
            shortDescription: 'Time value',
            longLabel: 'Time Field',
            longDescription: 'Stores a time value without date information.'
        },
        validation: validateDate,
        errorMessage: 'Must be a valid time',
    },
    date: {
        fieldDataTypeId: 'date',
        description: {
            shortLabel: 'Date',
            shortDescription: 'Date value',
            longLabel: 'Date Field',
            longDescription: 'Stores a date value without time information.'
        },
        validation: validateDate,
        errorMessage: 'Must be a valid date',
    },
    dateTime: {
        fieldDataTypeId: 'dateTime',
        description: {
            shortLabel: 'Date & Time',
            shortDescription: 'Date and time value',
            longLabel: 'Date and Time Field',
            longDescription: 'Stores a date and time value, including timezone information.'
        },
        validation: validateDate,
        errorMessage: 'Must be a valid date and time',
    },
    timeRange: {
        fieldDataTypeId: 'timeRange',
        description: {
            shortLabel: 'Time Range',
            shortDescription: 'Time period',
            longLabel: 'Time Range Field',
            longDescription: 'Stores a time range with start and end times.'
        },
        validation: (value: any) => {
            if (!value) return true;
            return validateDate(value.start) && validateDate(value.end);
        },
        errorMessage: 'Must have valid start and end times',
    },
    dateRange: {
        fieldDataTypeId: 'dateRange',
        description: {
            shortLabel: 'Date Range',
            shortDescription: 'Date period',
            longLabel: 'Date Range Field',
            longDescription: 'Stores a date range with start and end dates.'
        },
        validation: (value: any) => {
            if (!value) return true;
            return validateDate(value.start) && validateDate(value.end);
        },
        errorMessage: 'Must have valid start and end dates',
    },
    dateTimeRange: {
        fieldDataTypeId: 'dateTimeRange',
        description: {
            shortLabel: 'Date & Time Range',
            shortDescription: 'Date and time period',
            longLabel: 'Date & Time Range Field',
            longDescription: 'Stores a date and time range with start and end date-times.'
        },
        validation: (value: any) => {
            if (!value) return true;
            return validateDate(value.start) && validateDate(value.end);
        },
        errorMessage: 'Must have valid start and end date-times',
    },
    multiSelect: {
        fieldDataTypeId: 'multiSelect',
        description: {
            shortLabel: 'Multi-Select',
            shortDescription: 'Multiple selections from options',
            longLabel: 'Multi-Select Field',
            longDescription: 'Allows selection of multiple values from a predefined list of options.'
        },
        validation: (value: any) => Array.isArray(value),
        errorMessage: 'Must select one or more options',
    },
    singleSelect: {
        fieldDataTypeId: 'singleSelect',
        description: {
            shortLabel: 'Single Select',
            shortDescription: 'Single selection from options',
            longLabel: 'Single Select Field',
            longDescription: 'Allows selection of a single value from a predefined list of options.'
        },
        validation: (value: any) => typeof value === 'string',
        errorMessage: 'Must select an option',
    },
    address: {
        fieldDataTypeId: 'address',
        description: {
            shortLabel: 'Address',
            shortDescription: 'Physical address',
            longLabel: 'Address Field',
            longDescription: 'Stores a physical address with multiple components like street, city, state, zip, etc.'
        },
        validation: (value: any) => {
            if (!value) return true;  
            return typeof value === 'string';
        },
        errorMessage: 'Must be a valid address',
    },
    table: {
        fieldDataTypeId: 'table',
        description: {
            shortLabel: 'Table',
            shortDescription: 'Tabular data',
            longLabel: 'Table Field',
            longDescription: 'Stores structured data in a tabular format with rows and columns.'
        }
    },
    list: {
        fieldDataTypeId: 'list',
        description: {
            shortLabel: 'List',
            shortDescription: 'List of items',
            longLabel: 'List Field',
            longDescription: 'Stores a list of items, typically of the same type.'
        }
    },
    null: {
        fieldDataTypeId: 'null',
        description: {
            shortLabel: 'Null',
            shortDescription: 'Empty value',
            longLabel: 'Null Field',
            longDescription: 'Represents an intentionally empty or undefined value.'
        }
    },
    attachments: {
        fieldDataTypeId: 'attachments',
        description: {
            shortLabel: 'Attachments',
            shortDescription: 'File attachments',
            longLabel: 'Attachments Field',
            longDescription: 'Stores a list of references to an uploaded file or document.'
        }
    },
    link: {
        fieldDataTypeId: 'link',
        description: {
            shortLabel: 'Link',
            shortDescription: 'Hyperlink',
            longLabel: 'Link Field',
            longDescription: 'Stores a hyperlink to an external resource.'
        }
    },
    email: {
        fieldDataTypeId: 'email',
        description: {
            shortLabel: 'Email',
            shortDescription: 'Email address',
            longLabel: 'Email Field',
            longDescription: 'Stores an email address.'
        },
        validation: (value: any) => {
            if (!value) return true;  
            return typeof value === 'string' && isEmail(value);
        },
        errorMessage: 'Please enter a valid email address',
    },
    phone: {
        fieldDataTypeId: 'phone',
        description: {
            shortLabel: 'Phone',
            shortDescription: 'Phone number',
            longLabel: 'Phone Field',
            longDescription: 'Stores a phone number.'
        },
        validation: (value: any) => {
            if (!value) return true;
            const phoneRegex = /^[0-9\s\-\+\(\)]+$/;
            return typeof value === 'string' && phoneRegex.test(value);
        },
        formatter: (value: any) => {
            if (!value) return '';
            const digits = value.replace(/[^\d+]/g, '');
            return digits.replace(/(\+?\d{3})(\d{3})(\d+)/, '$1-$2-$3');
        },
        parser: (value: string | undefined) => {
            if (!value) return undefined;
            return value.replace(/[^\d+]/g, '');
        },
        errorMessage: 'Please enter a valid phone number',
    },
    url: {
        fieldDataTypeId: 'url',
        description: {
            shortLabel: 'URL',
            shortDescription: 'Web address',
            longLabel: 'URL Field',
            longDescription: 'Stores a web address or URL link.'
        },
        validation: (value: any) => {
            if (!value) return true;  
            return typeof value === 'string' && isURL(value);
        },
        errorMessage: 'Must be a valid URL',
        formatter: (value: any) => value || '',
        parser: (value: string | undefined) => {
            if (!value) return undefined;
            return value.startsWith('http://') || value.startsWith('https://')
                ? value
                : `https://${value}`;
        }
    },
    ipAddress: {
        fieldDataTypeId: 'ipAddress',
        description: {
            shortLabel: 'IP Address',
            shortDescription: 'Internet Protocol address',
            longLabel: 'IP Address Field',
            longDescription: 'Stores an IP address (IPv4 or IPv6).'
        },
        validation: (value: any) => {
            if (!value) return true;  
            return typeof value === 'string' && isIP(value);
        },
        errorMessage: 'Must be a valid IPv4 or IPv6 address',
    },
    json: {
        fieldDataTypeId: 'json',
        description: {
            shortLabel: 'JSON',
            shortDescription: 'JSON data',
            longLabel: 'JSON Field',
            longDescription: 'Stores structured data in JSON format.'
        },
        validation: (value: any) => {
            if (!value) return true;  
            return typeof value === 'string' && isJSON(value);
        },
        errorMessage: 'Must be valid JSON',
    },
    xml: {
        fieldDataTypeId: 'xml',
        description: {
            shortLabel: 'XML',
            shortDescription: 'XML data',
            longLabel: 'XML Field',
            longDescription: 'Stores structured data in XML format.'
        }
    },
    html: {
        fieldDataTypeId: 'html',
        description: {
            shortLabel: 'HTML',
            shortDescription: 'HTML content',
            longLabel: 'HTML Field',
            longDescription: 'Stores HTML content for rich text formatting.'
        }
    },
    password: {
        fieldDataTypeId: 'password',
        description: {
            shortLabel: 'Password',
            shortDescription: 'Secure password',
            longLabel: 'Password Field',
            longDescription: 'Stores a password securely, typically with masking and encryption.'
        },
        validation: (value: any) => {
            if (!value) return true;  
            return typeof value === 'string' && isStrongPassword(value, {
                minLength: 8,
                minLowercase: 1,
                minUppercase: 1,
            });
        },
        errorMessage: 'Password must be at least 8 characters long and include at least: 1 lowercase letter, 1 uppercase letter',
    },
    custom: {
        fieldDataTypeId: 'custom'
    },
    undefined: {
        fieldDataTypeId: 'undefined',
    }
};

