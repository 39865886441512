import React, { useState, useEffect } from 'react';
import {
    Upload,
    Button,
    message,
    Modal,
    Divider
} from 'antd';
import {
    UploadOutlined,
    DeleteOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { ref, uploadBytes, getDownloadURL, deleteObject, getBlob } from 'firebase/storage';
import { storage } from '../../services/startStorage';
import { addDoc, collection, getDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../services/startFirestore';
import FlexCard from './FlexCard';
import FlexBox from './FlexBox';

interface AttachmentUIProps {
    value?: string[];
    onChange?: (value: string[]) => void;
    disabled?: boolean;
    folder?: string;
    maxCount?: number;
}

export const AttachmentUI: React.FC<AttachmentUIProps> = ({
    value = [],
    onChange,
    disabled = false,
    folder = 'attachments',
    maxCount = 5,
}) => {
    const [fileList, setFileList] = useState<UploadFile[]>(() =>
        value.map((url, index) => ({
            uid: `-${index}`,
            name: url.split('/').pop()?.split('?')[0].split('%2F').pop() || '',
            status: 'done',
            url: url,
            type: 'file'
        }))
    );
    const [uploading, setUploading] = useState(false);
    const [isOverwriteModalOpen, setIsOverwriteModalOpen] = useState(false);
    const [pendingUpload, setPendingUpload] = useState<{
        file: File;
        onSuccess?: (response: any) => void;
        onError?: (error: Error) => void;
    } | null>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [pendingDelete, setPendingDelete] = useState<{
        fileUid: string;
        fileName: string;
    } | null>(null);

    const performUpload = async (
        originalFile: File,
        onSuccess?: (response: any) => void,
        onError?: (error: Error) => void
    ) => {
        try {
            setUploading(true);
            const attachmentDoc = await addDoc(collection(firestore, 'attachments'), {
                name: originalFile.name,
                size: originalFile.size,
                type: originalFile.type,
                storageUrl: '',
                storagePath: '',
                meta: {
                    created: new Date(),
                    lastModified: new Date(),
                }
            });

            const storageRef = ref(storage, `${folder}/${attachmentDoc.id}_${originalFile.name}`);
            await uploadBytes(storageRef, originalFile);
            const downloadURL = await getDownloadURL(storageRef);

            await updateDoc(attachmentDoc, {
                storageUrl: downloadURL,
                storagePath: storageRef.fullPath
            });

            const newUrls = [...value, attachmentDoc.id];
            onChange?.(newUrls);
            onSuccess?.(attachmentDoc.id);

            setFileList(prev => [...prev, {
                uid: attachmentDoc.id,
                name: originalFile.name,
                status: 'done',
                url: attachmentDoc.id,
                type: originalFile.type
            } as UploadFile]);

            message.success('File uploaded successfully');
        } catch (error) {
            console.error('Upload error:', error);
            onError?.(new Error('Upload failed'));
            message.error('Upload failed');
        } finally {
            setUploading(false);
        }
    };

    const handleOverwriteConfirm = async () => {
        setIsOverwriteModalOpen(false);
        if (!pendingUpload) return;

        const { file, onSuccess, onError } = pendingUpload;
        const existingFile = fileList.find(f => f.name === file.name);

        if (!existingFile) {
            await performUpload(file, onSuccess, onError);
            return;
        }

        try {
            const existingStorageRef = ref(storage, `${folder}/${existingFile.uid}_${existingFile.name}`);
            await deleteObject(existingStorageRef);

            const attachmentRef = doc(firestore, 'attachments', existingFile.uid);
            await deleteDoc(attachmentRef);

            setFileList(prev => prev.filter(f => f.uid !== existingFile.uid));
            const newUrls = value.filter(id => id !== existingFile.uid);
            onChange?.(newUrls);

            await performUpload(file, onSuccess, onError);
        } catch (error) {
            console.error('Error handling overwrite:', error);
            onError?.(new Error('Failed to overwrite file'));
            message.error('Failed to overwrite file');
        }
        setPendingUpload(null);
    };

    const handleOverwriteCancel = () => {
        setIsOverwriteModalOpen(false);
        pendingUpload?.onError?.(new Error('Upload cancelled'));
        setPendingUpload(null);
    };

    const handleUpload: UploadProps['customRequest'] = async ({ file, onSuccess, onError }) => {
        const originalFile = file as File;
        const existingFile = fileList.find(f => f.name === originalFile.name);

        if (existingFile && existingFile.status === 'done') {
            setPendingUpload({ file: originalFile, onSuccess, onError });
            setIsOverwriteModalOpen(true);
            return;
        }

        await performUpload(originalFile, onSuccess, onError);
    };

    const handleDeleteConfirm = async () => {
        if (!pendingDelete) return;
        
        try {
            const { fileUid } = pendingDelete;
            const attachmentRef = doc(firestore, 'attachments', fileUid);
            const attachmentDoc = await getDoc(attachmentRef);

            if (!attachmentDoc.exists()) {
                throw new Error('Attachment document not found');
            }

            const attachmentData = attachmentDoc.data();
            
            if (attachmentData.storagePath) {
                const fileRef = ref(storage, attachmentData.storagePath);
                await deleteObject(fileRef);
            } else {
                console.warn('No storage path found for attachment:', fileUid);
            }

            await deleteDoc(attachmentRef);

            const newUids = value.filter(uid => uid !== fileUid);
            onChange?.(newUids);
            setFileList(prevList => prevList.filter(file => file.uid !== fileUid));

            message.success('File deleted successfully');
        } catch (error) {
            console.error('Delete error:', error);
            message.error('Delete failed');
        } finally {
            setIsDeleteModalOpen(false);
            setPendingDelete(null);
        }
    };

    const handleDeleteCancel = () => {
        setIsDeleteModalOpen(false);
        setPendingDelete(null);
    };

    const handleDelete = async (fileUid: string, fileName: string) => {
        setPendingDelete({ fileUid, fileName });
        setIsDeleteModalOpen(true);
    };

    const handleDownload = async (fileUid: string, fileName: string) => {
        try {
            const attachmentRef = doc(firestore, 'attachments', fileUid);
            const attachmentDoc = await getDoc(attachmentRef);

            if (!attachmentDoc.exists()) {
                throw new Error('Attachment document not found');
            }

            const fileRef = ref(storage, attachmentDoc.data().storagePath);
            const blob = await getBlob(fileRef);
            const blobUrl = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);

            message.success('File downloaded successfully');
        } catch (error) {
            console.error('Download error:', error);
            message.error('Download failed');
        }
    };

    useEffect(() => {
        const loadAttachments = async () => {
            try {
                const attachments = await Promise.all(
                    value.map(async (attachmentId) => {
                        try {
                            const attachmentRef = doc(firestore, 'attachments', attachmentId);
                            const attachmentDoc = await getDoc(attachmentRef);
                            if (attachmentDoc.exists()) {
                                const data = attachmentDoc.data();
                                return {
                                    uid: attachmentId,
                                    name: data.name,
                                    status: 'done' as const,
                                    url: attachmentId,
                                    type: data.type
                                };
                            }
                            return null;
                        } catch (error) {
                            console.error('Error loading attachment:', error);
                            return null;
                        }
                    })
                );

                setFileList(attachments.filter(Boolean) as UploadFile[]);
            } catch (error) {
                console.error('Error loading attachments:', error);
                message.error('Failed to load attachments');
            }
        };

        if (value.length > 0) {
            loadAttachments();
        } else {
            setFileList([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    const uploadProps = {
        customRequest: handleUpload,
        fileList,
        multiple: true,
        maxCount: 5,
        listType: 'text' as const,
        onRemove: () => false,
        disabled: disabled || uploading,
    };

    return (
        <FlexCard
            bordered={false}
            stretch
            growCard
            gap={0}
            style={{
                width: '100%',
            }}
        >
            <Upload {...uploadProps}
                style={{
                    width: '100%'
                }}
                itemRender={(originNode, file, fileList, actions) => (
                    <>
                        {fileList.indexOf(file) === 0 && <Divider style={{ margin: '12px 0' }} />}
                        <FlexBox
                            style={{
                                padding: '8px 0',
                            }}
                        >
                            <FlexBox noGrow>
                                <Button
                                    icon={<DownloadOutlined />}
                                    type="link"
                                    onClick={() => file.uid && handleDownload(file.uid, file.name)}
                                    disabled={disabled || !file.uid}
                                    size="small"
                                >
                                    {file.name}
                                </Button>
                                <Button
                                    icon={<DeleteOutlined />}
                                    type="link"
                                    onClick={() => file.uid && handleDelete(file.uid, file.name)}
                                    disabled={disabled || !file.uid}
                                    danger
                                    size="small"
                                />
                            </FlexBox>
                        </FlexBox>
                    </>
                )}
            >
                <Button
                    icon={<UploadOutlined />}
                    loading={uploading}
                    type="link"
                    disabled={disabled || fileList.length >= maxCount}
                >
                    Upload File
                </Button>
            </Upload>

            <Modal
                title="File already exists"
                open={isOverwriteModalOpen}
                onOk={handleOverwriteConfirm}
                onCancel={handleOverwriteCancel}
                okText="Overwrite"
                cancelText="Cancel"
                confirmLoading={uploading}
            >
                <p>A file with this name already exists. Do you want to overwrite it?</p>
            </Modal>

            <Modal
                title="Confirm Delete"
                open={isDeleteModalOpen}
                onOk={handleDeleteConfirm}
                onCancel={handleDeleteCancel}
                okText="Delete"
                cancelText="Cancel"
            >
                <p>Are you sure you want to delete "{pendingDelete?.fileName}"?</p>
                <p>This action cannot be undone.</p>
            </Modal>
        </FlexCard>
    );
}; 