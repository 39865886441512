import { FC } from 'react';
import { 
    Table,
    Typography,
    List,
    Grid,
    Button
} from 'antd';
import { 
    DoubleLeftOutlined,
    LeftOutlined,
    RightOutlined
} from '@ant-design/icons';
import { Timestamp } from 'firebase/firestore';
import type { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';

import { Form, FormType } from '../../types/System.types';
import { useBoundCollection } from '../../use/data/useBoundCollection';
import FlexCard from '../atoms/FlexCard';
import { UseTableResult } from '../../use/useTable';
import FlexBox from '../atoms/FlexBox';

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

interface FormsTableProps {
    tableProps: UseTableResult<Form>;
    formTypes?: FormType[];
}

const FormsTable: FC<FormsTableProps> = ({ 
    tableProps, 
    formTypes = []
}) => {
    const navigate = useNavigate();
    const { md } = useBreakpoint();

    const columns: ColumnsType<Form> = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '30%',
        },
        {
            title: 'Form Number',
            dataIndex: 'formNumber',
            key: 'formNumber',
            width: '20%',
        },
        {
            title: 'Form Type',
            dataIndex: 'formType',
            key: 'formType',
            width: '20%',
            render: (formTypeId: string) => {
                const formType = formTypes?.find(ft => ft.docId === formTypeId);
                return formType?.description?.shortLabel || formTypeId;
            },
        },
        {
            title: 'Created',
            dataIndex: ['meta', 'created'],
            key: 'created',
            width: '30%',
            render: (timestamp: Timestamp) => {
                if (timestamp instanceof Timestamp) 
                    return timestamp.toDate().toLocaleString();
                return 'Invalid Date';
            },
        },
    ];

    const {
        tableData,
        modernPagination,
        tableLoading,
        tableError,
        setNextPage,
        setPreviousPage,
        goToStart
    } = tableProps;

    if (tableError) {
        return <Text type="danger">Error: {tableError.message}</Text>;
    }

    const paginationControls = (
        <FlexBox noGrow>
            <Button
                type="link"
                disabled={modernPagination.currentPage === 1 || tableLoading}
                onClick={goToStart}
                icon={<DoubleLeftOutlined />}
            />
            <Button 
                type="link"
                disabled={!modernPagination.hasPreviousPage || tableLoading}
                onClick={setPreviousPage}
                icon={<LeftOutlined />}
            />
            <Text>{modernPagination.currentPage}</Text>
            <Button 
                type="link"
                disabled={!modernPagination.hasNextPage || tableLoading}
                onClick={setNextPage}
                icon={<RightOutlined />}
            />
        </FlexBox>
    );
    
    if (!md) {
        return (
            <>
                <List
                    dataSource={tableData}
                    loading={tableLoading}
                    style={{ width: '100%' }}
                    renderItem={(item: Form) => (
                        <FlexCard 
                            key={item.docId}
                            alignStart
                            hoverable
                            onClick={() => navigate(`/forms/${item.docId}`)}
                            style={{ 
                                marginBottom: 16, 
                                cursor: 'pointer',
                                width: '100%'
                            }}
                        >
                            <Title level={5} style={{ margin: 0 }}>
                                {item.title}
                            </Title>
                            <Text>Form Number: {item.formNumber}</Text>
                            <Text>Form Type: {
                                formTypes?.find(ft => ft.docId === item.formType)?.description?.shortLabel 
                                || item.formType
                            }</Text>
                            <Text>
                                Created: {
                                    item.meta?.created instanceof Timestamp 
                                        ? item.meta.created.toDate().toLocaleString() 
                                        : 'Invalid Date'
                                }
                            </Text>
                        </FlexCard>
                    )}
                    pagination={false}
                />
                {paginationControls}
            </>
        );
    }

    return (
        <>
            <Table
                style={{ width: '100%' }}
                dataSource={tableData}
                columns={columns}
                loading={tableLoading}
                rowKey="docId"
                pagination={false}
                onRow={(record) => ({
                    onClick: () => navigate(`/forms/${record.docId}`),
                    style: { cursor: 'pointer' },
                })}
                scroll={{ x: 'max-content' }}
            />
            {paginationControls}
        </>
    );
};

export default FormsTable;