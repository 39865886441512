import { 
    FC, 
    useState, 
    useCallback 
} from 'react';
import { 
    Modal, 
    Typography, 
    Slider, 
    Space, 
    InputNumber, 
    Table 
} from 'antd';

const { Text } = Typography;

interface ReductionResult {
    amount: number;
    percentage: number;
}

interface OptimizationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (amount: number) => void;
    isOptimizing: boolean;
    maxOBaseReduction: ReductionResult;
    maxCutPriceReduction: ReductionResult;
}

const OptimizationModal: FC<OptimizationModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    isOptimizing,
    maxOBaseReduction,
    maxCutPriceReduction,
}) => {
    const [targetReduction, setTargetReduction] = useState<number | null>(null);
    const [reductionIncrement, setReductionIncrement] = useState<number>(10);
    const [targetPercentage, setTargetPercentage] = useState<number | null>(null);

    const getMaxReduction = useCallback(() => {
        return Math.floor(maxCutPriceReduction.amount / reductionIncrement) * reductionIncrement;
    }, [maxCutPriceReduction.amount, reductionIncrement]);

    const calculatePercentageStep = useCallback(() => {
        return (reductionIncrement / maxCutPriceReduction.amount) * 100;
    }, [maxCutPriceReduction.amount, reductionIncrement]);

    const handleTargetReductionChange = (amount: number | null) => {
        setTargetReduction(amount);
        if (amount) {
            const percentage = (amount / maxCutPriceReduction.amount) * 100;
            setTargetPercentage(percentage);
        } else {
            setTargetPercentage(null);
        }
    };

    const handleTargetPercentageChange = (percentage: number | null) => {
        setTargetPercentage(percentage);
        if (percentage) {
            const amount = (percentage * maxCutPriceReduction.amount) / 100;
            setTargetReduction(Math.floor(amount / reductionIncrement) * reductionIncrement);
        } else {
            setTargetReduction(null);
        }
    };

    const calculatePercentageFromAmount = (value: number) => {
        const actualValue = Math.pow(10, value);
        setReductionIncrement(actualValue);
        setTargetReduction(actualValue);
        const percentage = (actualValue / maxCutPriceReduction.amount) * 100;
        setTargetPercentage(percentage);
    };

    const handleConfirm = () => {
        if (targetReduction) {
            onConfirm(targetReduction);
        }
    };

    return (
        <Modal
            title="Optimize BOM"
            open={isOpen}
            onOk={handleConfirm}
            onCancel={onClose}
            okButtonProps={{ 
                disabled: !targetReduction,
                loading: isOptimizing 
            }}
        >
            <Text>Select the increment size for reduction amounts:</Text>
            <Slider
                style={{ marginBottom: 40, marginLeft: 10, marginRight: 20 }}
                value={Math.log10(reductionIncrement)}
                onChange={calculatePercentageFromAmount}
                min={0}
                max={4}
                step={1}
                marks={{
                    0: '$1',
                    1: '$10',
                    2: '$100',
                    3: '$1K',
                    4: '$10K'
                }}
                tooltip={{open: false}}
            />
            <Text>
                Enter the reduction amount or percentage
            </Text>
            <Space.Compact block style={{ marginBottom: 8 }}>
                <InputNumber
                    style={{ width: '50%' }}
                    prefix="$"
                    precision={0}
                    value={targetReduction}
                    onChange={handleTargetReductionChange}
                    min={reductionIncrement}
                    max={getMaxReduction()}
                    step={reductionIncrement}
                    formatter={(value) => {
                        const numValue = Number(value);
                        return `${Math.round(numValue / reductionIncrement) * reductionIncrement}`;
                    }}
                />
                <InputNumber
                    style={{ width: '50%' }}
                    suffix="%"
                    precision={3}
                    value={targetPercentage}
                    onChange={handleTargetPercentageChange}
                    min={0}
                    max={100}
                    step={calculatePercentageStep()}
                    formatter={(value) => {
                        if (value === null) return '';
                        const numValue = Number(value);
                        const steps = Math.round(numValue / calculatePercentageStep());
                        return (steps * calculatePercentageStep()).toFixed(1);
                    }}
                />
            </Space.Compact>
            <Table
                columns={[
                    {
                        title: 'Reduction Using',
                        dataIndex: 'type',
                        key: 'type',
                    },
                    {
                        title: 'Max Reduction',
                        dataIndex: 'reduction',
                        key: 'reduction',
                        align: 'right' as const,
                    },
                ]}
                dataSource={[
                    {
                        key: '1',
                        type: 'oBasePrice',
                        reduction: `$${maxOBaseReduction.amount.toFixed(2)} (${maxOBaseReduction.percentage.toFixed(1)}%)`,
                    },
                    {
                        key: '2',
                        type: 'CutPrice',
                        reduction: `$${maxCutPriceReduction.amount.toFixed(2)} (${maxCutPriceReduction.percentage.toFixed(1)}%)`,
                    },
                    {
                        key: '3',
                        type: 'Increment Based',
                        reduction: `$${getMaxReduction().toFixed(0)}`,
                    },
                ]}
                size="small"
                pagination={false}
                style={{ marginTop: 16 }}
                bordered={false}
            />
        </Modal>
    );
};

export default OptimizationModal; 