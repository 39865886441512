import React, { useContext } from 'react';
import { Typography, Button, Divider, message } from 'antd';

import FlexBox from '../atoms/FlexBox';
import Icon from '../atoms/Icon';
import { UserContext } from '../../providers/UserProvider';
import useFirebaseAuth from '../../use/useFirebaseAuth';

const { Title, Text } = Typography;

const UserSettingsTab: React.FC = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const { user } = useContext(UserContext);
    const { logout } = useFirebaseAuth();

    const handleLogout = async () => {
        try {
            await logout();
            messageApi.success('Logged out successfully');
        } catch (error) {
            console.error('Error logging out:', error);
            messageApi.error('Failed to logout');
        }
    };

    return (
        <FlexBox 
            column 
            alignStart 
            justifyStart 
            noGrow 
            gap={4}
        >
            {contextHolder}
            <Title level={3} style={{ marginBottom: 0 }}>
                {user?.displayName}
            </Title>
            <Text style={{ margin: 0 }}>
                {user?.email}
            </Text>
            <Text type='secondary' style={{ margin: 0 }}>
                <small>{user?.uid}</small>
            </Text>
            <Divider />
            <Button
                type='default'
                icon={<Icon.LogOut />}
                onClick={handleLogout}
            >
                Logout
            </Button>
        </FlexBox>
    );
};

export default UserSettingsTab; 