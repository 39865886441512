import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import FlexBox from './FlexBox';
import { SearchResultItem } from '../../services/searchConfig';

const { Text } = Typography;

/**
 * Renders a search result item as a card with title and optional subtitle.
 * If url is provided, wraps content in a router Link component.
 * 
 * @param props - Component props
 * @param props.url - URL for navigation. If empty string, Link will not navigate
 * @param props.title - Primary text to display in the card
 * @param props.subtitle - Optional secondary text to display below the title
 * 
 * @example
 * // With navigation
 * <SearchResultCard url="/item/123" title="Item Title" subtitle="Additional info" />
 * 
 * // Without navigation (for selectors)
 * <SearchResultCard url="" title="Item Title" subtitle="Additional info" />
 */
export const SearchResultCard: FC<SearchResultItem> = ({ 
  url,
  title,
  subtitle,
}) => (
  <Link to={url}>
    <FlexBox 
      column 
      noGrow 
      alignStart 
      gap={1}
    >
      <Text>
        {title}
      </Text>
      {subtitle && (
        <Text type='secondary'>
          {subtitle}
        </Text>)}
    </FlexBox>
  </Link>
);