import { useContext, useState, useCallback } from 'react';
import { FormContext } from '../providers/FormProvider';
import { EntityContext, EntityContextValue } from '../providers/EntityProvider';
import { UserContext } from '../providers/UserProvider';
import { Entity, EntityRecord, FormVersion } from '../types/System.types';
import { UseBoundDoc } from './data/useBoundDoc';

interface UseFormAndEntityContextsReturn {
  hasContext: boolean;
  formReadOnly: boolean;
  hasEntityAccess: boolean;
  selectedEntity: Entity | null;
  selectedEntityRecord: EntityRecord | null;
  insideEntityContext: boolean;
  insideFormContext: boolean;
  createNewEntityRecord: () => Promise<string>;
  selectEntityRecord: (value: string | undefined) => Promise<void>;
  deleteRecord: (recordId: string) => Promise<void>;
  saveEntityReference: (value: string | undefined) => Promise<void>;
  handleDBSave: (value: any) => void;
  selectedFormVersion?: UseBoundDoc<FormVersion>;
  setEntityLocked: (value: boolean) => void;
  debouncedSaveEntityRecord: (value: Partial<EntityRecord>) => void;
  entityLocked: boolean;
  isSaving: boolean;
  subscribeToEntityRecord: (entityId: string, recordId: string) => Promise<void>;
  unsubscribeFromEntityRecord: (entityId: string, recordId: string) => Promise<void>;
  entityLockedInContext: boolean;
  recordSelectionLocked: boolean;
}

export const useFormAndEntityContexts = (): UseFormAndEntityContextsReturn => {
  const [isSaving, setIsSaving] = useState(false);
  const formContext = useContext(FormContext);
  const entityContext = useContext(EntityContext);
  const { subscribeToEntityRecord, unsubscribeFromEntityRecord } = useContext(UserContext);

  const insideFormContext = formContext?.insideFormContext ?? false;
  const formVersionAccess = formContext?.formVersionAccess;
  const formReadOnly = !formVersionAccess?.canWrite;
  const selectedFormVersion = formContext?.selectedFormVersion;

  const insideEntityContext = entityContext?.insideEntityContext ?? false;
  const hasEntityAccess = entityContext?.hasEntityAccess;
  const selectedEntity = entityContext?.selectedEntity;
  const selectedEntityRecord = entityContext?.selectedEntityRecord;
  const createNewEntityRecord = entityContext?.createNewRecord ?? (() => Promise.resolve(''));
  const selectEntityRecord = entityContext?.selectEntityRecord ?? (() => Promise.resolve());
  const setEntityLocked = entityContext?.setEntityLocked ?? (() => {});
  const entityLocked = entityContext?.entityLocked ?? false;
  const debouncedSaveEntityRecord = entityContext?.debouncedSetRecord;
  const deleteRecord = entityContext?.deleteRecord ?? (() => Promise.resolve());
  const parentEntityContext = entityContext?.parentEntityContext;

  const entityLockedInContext = parentEntityContext?.entityLocked || formReadOnly;
  const recordSelectionLocked = insideFormContext ? entityLockedInContext : false;

  const saveEntityReference = useCallback(async (value: string | undefined) => {
    try {
      setIsSaving(true);

      if (insideEntityContext && parentEntityContext && selectedEntity?.docId) {
        parentEntityContext.debouncedSetRecord({
          entityReferences: {
            ...parentEntityContext.selectedEntityRecord?.entityReferences,
            [selectedEntity.docId]: value || ''
          }
        });
      } else if (insideFormContext && selectedFormVersion && selectedEntity?.docId) {
        selectedFormVersion.debouncedSet({
          entityReferences: {
            ...selectedFormVersion.data?.entityReferences,
            [selectedEntity.docId]: value || ''
          }
        });
      }

      setTimeout(() => setIsSaving(false), 350);
    } catch (error) {
      console.error('Save entity reference failed:', error);
      setIsSaving(false);
    }
  }, [insideEntityContext, parentEntityContext, selectedEntity?.docId, insideFormContext, selectedFormVersion]);

  const handleDBSave = (value: any) => {
    if (insideEntityContext && entityContext?.setRecord) {
      entityContext.setRecord({
        fields: value,
      });
    } else if (insideFormContext && formVersionAccess?.canWrite && selectedFormVersion?.updateData) {
      selectedFormVersion.updateData({
        fields: value,
      });
    }
  };
  
  return {
    hasContext: insideEntityContext || insideFormContext,
    formReadOnly,
    hasEntityAccess,
    selectedEntity,
    selectedEntityRecord,
    insideEntityContext,
    insideFormContext,
    createNewEntityRecord,
    selectEntityRecord,
    deleteRecord,
    saveEntityReference,
    handleDBSave,
    selectedFormVersion,
    setEntityLocked,
    debouncedSaveEntityRecord,
    entityLocked,
    isSaving,
    subscribeToEntityRecord,
    unsubscribeFromEntityRecord,
    entityLockedInContext,
    recordSelectionLocked,
  };
}; 