import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useBoundCollection } from './data/useBoundCollection';
import { ListOrderBy, ListFilters } from '../providers/DataProvider';
import { DocDataWithId } from '../types/System.types';
import { TablePaginationConfig } from 'antd/es/table';

interface ModernPagination {
    currentPage: number;
    pageSize: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
}

interface PageState {
    hasNext: boolean;
    hasPrev: boolean;
    data: DocDataWithId[];
}

export interface UseTableResult<T extends DocDataWithId> {
    tableData: T[];
    tablePagination: TablePaginationConfig;
    modernPagination: ModernPagination;
    tableLoading: boolean;
    tableError: Error | null;
    setNextPage: () => void;
    setPreviousPage: () => void;
    setPageSize: (size: number) => void;
    refreshTable: () => void;
    setFilters: (filters: ListFilters) => void;
    setOrderBy: (orderBy: ListOrderBy) => void;
    goToStart: () => void;
    setStartAt: (doc: T | undefined) => void;
}

interface UseTableParams {
    path: string;
    initialPageSize?: number;
    initialOrderBy?: ListOrderBy;
}

export function useTable<T extends DocDataWithId>({
    path,
    initialPageSize = 10,
    initialOrderBy = [{ field: 'meta.created', direction: 'desc' }],
}: UseTableParams,
    boundCollectionHook = useBoundCollection
): UseTableResult<T> {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [localData, setLocalData] = useState<T[]>([]);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);
    const [pageCache, setPageCache] = useState<Record<number, T[]>>({});
    const navigationSourceRef = useRef<'forward' | 'backward' | 'none'>('none');
    const pageStatesRef = useRef<Record<number, PageState>>({});

    const {
        data,
        error,
        loading,
        refresh,
        setLimit,
        setStartAt,
        setFilters: setBoundFilters,
        setOrderBy: setBoundOrderBy,
    } = boundCollectionHook<T>({
        path,
        initialLimit: pageSize + 1,
        initialOrderBy,
    });

    useEffect(() => {
        if (!loading && data.length > 0) {
            if (navigationSourceRef.current === 'backward') {
                const previousState = pageStatesRef.current[currentPage];
                if (previousState) {
                    setHasNextPage(previousState.hasNext);
                    setHasPreviousPage(previousState.hasPrev);
                }
                return;
            }

            const newData = data.slice(0, pageSize);
            const hasMore = data.length > pageSize;
            
            pageStatesRef.current[currentPage] = {
                hasNext: hasMore,
                hasPrev: currentPage > 1,
                data: newData
            };

            setHasNextPage(hasMore);
            setHasPreviousPage(currentPage > 1);
            setLocalData(newData);
            setPageCache(prev => ({ ...prev, [currentPage]: newData }));
        }
    }, [data, pageSize, currentPage, loading]);

    useEffect(() => {
        if (!loading && navigationSourceRef.current !== 'none') {
            navigationSourceRef.current = 'none';
        }
    }, [loading, currentPage]);

    const setNextPage = useCallback(() => {
        if (hasNextPage && navigationSourceRef.current === 'none' && localData.length > 0) {
            navigationSourceRef.current = 'forward';
            const lastDoc = localData[localData.length - 1];
            setCurrentPage(prev => prev + 1);
            setStartAt(lastDoc);
        }
    }, [localData, hasNextPage, setStartAt]);

    const setPreviousPage = useCallback(() => {
        const previousPageData = pageCache[currentPage - 1];
        const previousState = pageStatesRef.current[currentPage - 1];

        if (hasPreviousPage && navigationSourceRef.current === 'none' && previousPageData) {
            navigationSourceRef.current = 'backward';
            setCurrentPage(prev => prev - 1);
            setLocalData(previousPageData);
            
            if (previousState) {
                setHasNextPage(previousState.hasNext);
                setHasPreviousPage(previousState.hasPrev);
            }
        }
    }, [hasPreviousPage, currentPage, pageCache]);

    const handleSetPageSize = useCallback((newSize: number) => {
        if (navigationSourceRef.current !== 'none') return;
        setLimit(newSize + 1);
        setPageSize(newSize);
        setCurrentPage(1);
        setPageCache({});
        setLocalData([]);
        setStartAt(undefined);
    }, [setLimit, setStartAt, setCurrentPage, setPageCache, setLocalData]);

    const setFilters = useCallback((filters: ListFilters) => {
        if (navigationSourceRef.current !== 'none') return;
        setBoundFilters(filters);
        setCurrentPage(1);
        setPageCache({});
        setLocalData([]);
    }, [setBoundFilters]);

    const setOrderBy = useCallback((newOrderBy: ListOrderBy) => {
        if (navigationSourceRef.current !== 'none') return;
        setBoundOrderBy(newOrderBy);
        setCurrentPage(1);
        setPageCache({});
        setLocalData([]);
    }, [setBoundOrderBy]);

    const modernPagination: ModernPagination = useMemo(() => ({
        currentPage,
        pageSize,
        hasNextPage,
        hasPreviousPage,
    }), [currentPage, pageSize, hasNextPage, hasPreviousPage]);

    const goToPage = useCallback(async (targetPage: number) => {
        if (targetPage === currentPage || navigationSourceRef.current !== 'none') return;
        
        if (targetPage > currentPage) {
            let current = currentPage;
            while (current < targetPage && hasNextPage) {
                const lastDoc = localData[localData.length - 1];
                navigationSourceRef.current = 'forward';
                setCurrentPage(prev => prev + 1);
                setStartAt(lastDoc);
                current++;
            }
        } else {
            let current = currentPage;
            while (current > targetPage && hasPreviousPage) {
                const previousPageData = pageCache[current - 1];
                if (previousPageData) {
                    navigationSourceRef.current = 'backward';
                    setCurrentPage(prev => prev - 1);
                    setLocalData(previousPageData);
                    current--;
                }
            }
        }
    }, [currentPage, hasNextPage, hasPreviousPage, localData, pageCache, setStartAt]);

    const tablePagination: TablePaginationConfig = useMemo(() => ({
        current: currentPage,
        pageSize,
        total: undefined,
        showSizeChanger: true,
        showQuickJumper: true,
        onChange: (page: number, newPageSize: number) => {
            if (newPageSize !== pageSize) {
                handleSetPageSize(newPageSize);
            } else {
                goToPage(page);
            }
        }
    }), [currentPage, pageSize, handleSetPageSize, goToPage]);

    return {
        tableData: localData,
        tablePagination,
        modernPagination,
        tableLoading: loading,
        tableError: error,
        setNextPage,
        setPreviousPage,
        setPageSize: handleSetPageSize,
        refreshTable: refresh,
        setFilters,
        setOrderBy,
        goToStart: useCallback(() => {
            setStartAt(undefined);
            setCurrentPage(1);
        }, [setStartAt]),
        setStartAt
    };
}