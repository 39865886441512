import { FC } from 'react';
import { UIElementType, EntityRecord } from '../../../../types/System.types';
import ControlUI from '../../ControlUI';
import { TableColumnType } from '../../../../types/System.types';

interface CellUIProps {
    /** Current value of the cell */
    value: any;
    /** Column configuration */
    col: TableColumnType;
    /** Callback when cell value changes */
    onCellChange: (rowIndex: number, columnKey: string, value: any, entityRecord?: EntityRecord | null) => void;
    /** Whether the cell is read-only */
    readOnly?: boolean;
    /** Row index in the table */
    rowIndex: number;
    /** Optional style overrides */
    style?: React.CSSProperties;
}

/**
 * A cell component for a table.
 * @param {CellUIProps} props - The props for the cell component.
 * @returns {React.ReactNode} The cell component.
 */
const CellUI: FC<CellUIProps> = ({ 
    value, 
    col,
    onCellChange, 
    readOnly,
    rowIndex,
    style
}) => {
    return (
        <ControlUI
            value={value}
            field={{ value }}
            id={`cell-${col.dataIndex}-${rowIndex}`}
            uiElementType={col.uiElementType}
            entityId={col.entityId}
            uiField={{
                uiElementType: UIElementType.Field,
                fieldTypeId: col.fieldTypeId || 'text',
                fieldId: `cell-${rowIndex}-${col.dataIndex}`,
                props: {
                    variant: 'borderless',
                    size: 'small',
                    style: { 
                        margin: 0, 
                        padding: 0,
                        maxWidth: 350,
                        ...style
                    }
                }
            }}
            onChange={(value, entityRecord) => onCellChange(rowIndex, col.dataIndex, value, entityRecord)}
            readOnly={readOnly}
        />
    );
};

export default CellUI;
