// WARNING: If you add imports here these will also impact sub repositories (cloudRun, functions, etc), you might need to add imports there as well.
import { FieldType } from './System.types';
import { RecordStatus } from './System.Parameters.types';
import { fieldDataType } from './System.FieldTypes.types';

// Types to be sunset/deprecated
export type HistCLWTypesToSunset =
  | 'affectedItems'
  | 'autoSplitCalc'
  | 'bidDate'
  | 'bidName'
  | 'billAddress'
  | 'billName'
  | 'buySell'
  | 'changeDescription'
  | 'changeJustification'
  | 'changeOrderDate'
  | 'checkNumber'
  | 'costImpact'
  | 'csrUserId'
  | 'dateReceived'
  | 'dimensions'
  | 'doNotShipBefore'
  | 'dueDate'
  | 'estimatedCostImpact'
  | 'estimatedScheduleImpact'
  | 'expectedReleaseDate'
  | 'expirationDate'
  | 'holdDate'
  | 'holdReason'
  | 'invoiceDate'
  | 'invoiceEndDate'
  | 'invoiceStartDate'
  | 'lightingRequirements'
  | 'likelihood'
  | 'lineItemsCommission'
  | 'lineItemsSummary'
  | 'lineNote'
  | 'lineType'
  | 'location'
  | 'locationState'
  | 'lostReasonCode'
  | 'lostTo'
  | 'paymentAmount'
  | 'paymentDate'
  | 'paymentMethod'
  | 'paymentTerms'
  | 'postedDate'
  | 'quoterUserId'
  | 'releaseConditions'
  | 'requestedImplementationDate'
  | 'reviewDate'
  | 'reviewedBy'
  | 'revisionDate'
  | 'salesRepId'
  | 'salesRepName'
  | 'scheduleImpact'
  | 'shipAddress'
  | 'shipmentCost'
  | 'shipmentDate'
  | 'shipName'
  | 'shippingMethod'
  | 'specRegistration'
  | 'statementPeriodEnd'
  | 'statementPeriodStart'
  | 'submittalDate'
  | 'submittedBy'
  | 'targetCompletionDate'
  | 'totalSaleAmount'
  | 'totalWeight'
  | 'trackingNumber'
  | 'weight';


/**
 * HistoricalField types for the CLW system
 */
export const histCLWTypesToSunset: Record<HistCLWTypesToSunset, FieldType> = {
  autoSplitCalc: {
    fieldTypeId: 'autoSplitCalc',
    fieldDataTypeId: 'yesNo',
    description: {
      shortLabel: 'Auto Split Calc',
      longDescription: 'Indicates if auto split calculation is enabled',
    },
    status: RecordStatus.Active,
    defaultValue: false,
    validation: fieldDataType.yesNo.validation,
    errorMessage: fieldDataType.yesNo.errorMessage,
  },
  bidDate: {
    fieldTypeId: 'bidDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Bid Date',
      longDescription: 'Date of the bid',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  bidName: {
    fieldTypeId: 'bidName',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Bid Name',
      longDescription: 'Name of the bid',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  csrUserId: {
    fieldTypeId: 'csrUserId',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'CSR User Initials',
      longDescription: 'Customer Service Representative User Initials',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  doNotShipBefore: {
    fieldTypeId: 'doNotShipBefore',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Do Not Ship Before',
      longDescription: 'Earliest date for shipping',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  expirationDate: {
    fieldTypeId: 'expirationDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Expiration Date',
      longDescription: 'Date when the form expires',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  likelihood: {
    fieldTypeId: 'likelihood',
    fieldDataTypeId: 'percentage',
    description: {
      shortLabel: 'Likelihood',
      longDescription: 'Likelihood of winning the bid',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  location: {
    fieldTypeId: 'location',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Location',
      longDescription: 'Location for the form',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  locationState: {
    fieldTypeId: 'locationState',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Location State',
      longDescription: 'State of the location',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  lostReasonCode: {
    fieldTypeId: 'lostReasonCode',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Lost Reason Code',
      longDescription: 'Code indicating the reason for losing the bid',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  lostTo: {
    fieldTypeId: 'lostTo',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Lost To',
      longDescription: 'Competitor to whom the bid was lost',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  quoterUserId: {
    fieldTypeId: 'quoterUserId',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Quoter User Initials',
      longDescription: 'ID of the user who created the quote',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  specRegistration: {
    fieldTypeId: 'specRegistration',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Spec Registration',
      longDescription: 'Specification Registration',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  revisionDate: {
    fieldTypeId: 'revisionDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Revision Date',
      longDescription: 'Date of the current revision for the Bill of Materials',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  totalWeight: {
    fieldTypeId: 'totalWeight',
    fieldDataTypeId: 'number',
    description: {
      shortLabel: 'Total Weight',
      longDescription: 'Total weight of all items in the Bill of Materials',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.number.validation,
    errorMessage: fieldDataType.number.errorMessage,
  },
  changeOrderDate: {
    fieldTypeId: 'changeOrderDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Change Order Date',
      longDescription: 'Date of the change order',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  requestedImplementationDate: {
    fieldTypeId: 'requestedImplementationDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Requested Implementation Date',
      longDescription: 'Date requested for implementing the change order',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  changeDescription: {
    fieldTypeId: 'changeDescription',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Change Description',
      longDescription: 'Detailed description of the proposed change',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  changeJustification: {
    fieldTypeId: 'changeJustification',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Change Justification',
      longDescription: 'Justification for the proposed change',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  costImpact: {
    fieldTypeId: 'costImpact',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Cost Impact',
      longDescription: 'Financial impact of the proposed change',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  scheduleImpact: {
    fieldTypeId: 'scheduleImpact',
    fieldDataTypeId: 'number',
    description: {
      shortLabel: 'Schedule Impact',
      longDescription: 'Impact on the project schedule in days',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.number.validation,
    errorMessage: fieldDataType.number.errorMessage,
  },
  salesRepId: {
    fieldTypeId: 'salesRepId',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Sales Rep ID',
      longDescription: 'Unique identifier for the sales representative',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  salesRepName: {
    fieldTypeId: 'salesRepName',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Sales Rep Name',
      longDescription: 'Full name of the sales representative',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  statementPeriodStart: {
    fieldTypeId: 'statementPeriodStart',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Statement Period Start',
      longDescription: 'Start date of the commission statement period',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  statementPeriodEnd: {
    fieldTypeId: 'statementPeriodEnd',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Statement Period End',
      longDescription: 'End date of the commission statement period',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  lightingRequirements: {
    fieldTypeId: 'lightingRequirements',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Lighting Requirements',
      longDescription: 'Detailed description of the lighting requirements for the project',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  targetCompletionDate: {
    fieldTypeId: 'targetCompletionDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Target Completion Date',
      longDescription: 'Target date for completing the lighting project',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  holdDate: {
    fieldTypeId: 'holdDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Hold Date',
      longDescription: 'Date when the hold was initiated',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  expectedReleaseDate: {
    fieldTypeId: 'expectedReleaseDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Expected Release Date',
      longDescription: 'Anticipated date when the hold will be lifted',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  holdReason: {
    fieldTypeId: 'holdReason',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Hold Reason',
      longDescription: 'Explanation for why the hold was initiated',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  releaseConditions: {
    fieldTypeId: 'releaseConditions',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Release Conditions',
      longDescription: 'Conditions that must be met for the hold to be released',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  affectedItems: {
    fieldTypeId: 'affectedItems',
    fieldDataTypeId: 'table',
    description: {
      shortLabel: 'Affected Items',
      longDescription: 'List of items affected by the hold',
    },
    status: RecordStatus.Active,
    defaultValue: [],
    validation: fieldDataType.table.validation,
    errorMessage: fieldDataType.table.errorMessage,
  },
  estimatedCostImpact: {
    fieldTypeId: 'estimatedCostImpact',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Estimated Cost Impact',
      longDescription: 'Estimated financial impact of the hold',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  estimatedScheduleImpact: {
    fieldTypeId: 'estimatedScheduleImpact',
    fieldDataTypeId: 'number',
    description: {
      shortLabel: 'Estimated Schedule Impact',
      longDescription: 'Estimated impact on the schedule in days',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.number.validation,
    errorMessage: fieldDataType.number.errorMessage,
  },
  invoiceDate: {
    fieldTypeId: 'invoiceDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Invoice Date',
      longDescription: 'Date when the invoice was created',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  dueDate: {
    fieldTypeId: 'dueDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Due Date',
      longDescription: 'Date when the payment is due',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  billName: {
    fieldTypeId: 'billName',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Billing Name',
      longDescription: 'Name of the billing entity',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  billAddress: {
    fieldTypeId: 'billAddress',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Billing Address',
      longDescription: 'Full billing address',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  shipName: {
    fieldTypeId: 'shipName',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Shipping Name',
      longDescription: 'Name for the shipping address',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  shipAddress: {
    fieldTypeId: 'shipAddress',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Shipping Address',
      longDescription: 'Full shipping address',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  shipmentCost: {
    fieldTypeId: 'shipmentCost',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Shipment Cost',
      longDescription: 'Cost of the shipment',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  buySell: {
    fieldTypeId: 'buySell',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Buy/Sell',
      longDescription: 'Indicates whether this is a payment for buying or selling',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: (value: any) => typeof value === 'string' && ['Buy', 'Sell'].includes(value),
    errorMessage: 'Must be either Buy or Sell',
  },
  paymentDate: {
    fieldTypeId: 'paymentDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Payment Date',
      longDescription: 'Date when the payment was made',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  paymentAmount: {
    fieldTypeId: 'paymentAmount',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Payment Amount',
      longDescription: 'Amount of the payment',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  paymentMethod: {
    fieldTypeId: 'paymentMethod',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Payment Method',
      longDescription: 'Method used for the payment (e.g., check, wire transfer, credit card)',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  checkNumber: {
    fieldTypeId: 'checkNumber',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Check Number',
      longDescription: 'Check number if payment method is check',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  dateReceived: {
    fieldTypeId: 'dateReceived',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Date Received',
      longDescription: 'Date when the payment was received',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  invoiceStartDate: {
    fieldTypeId: 'invoiceStartDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Invoice Start Date',
      longDescription: 'Start date of the invoice period',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  invoiceEndDate: {
    fieldTypeId: 'invoiceEndDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Invoice End Date',
      longDescription: 'End date of the invoice period',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  postedDate: {
    fieldTypeId: 'postedDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Posted Date',
      longDescription: 'Date when the payment was posted',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  lineNote: {
    fieldTypeId: 'lineNote',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Line Note',
      longDescription: 'Additional note for the line item',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  lineType: {
    fieldTypeId: 'lineType',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Line Type',
      longDescription: 'Type of the line item',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  shipmentDate: {
    fieldTypeId: 'shipmentDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Shipment Date',
      longDescription: 'Date when the shipment was sent',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  weight: {
    fieldTypeId: 'weight',
    fieldDataTypeId: 'number',
    description: {
      shortLabel: 'Weight',
      longDescription: 'Weight of the shipment item',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.number.validation,
    errorMessage: fieldDataType.number.errorMessage,
  },
  dimensions: {
    fieldTypeId: 'dimensions',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Dimensions',
      longDescription: 'Dimensions of the shipment item',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  shippingMethod: {
    fieldTypeId: 'shippingMethod',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Shipping Method',
      longDescription: 'Method used for shipping (e.g., Ground, Express, Air)',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  trackingNumber: {
    fieldTypeId: 'trackingNumber',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Tracking Number',
      longDescription: 'Tracking number for the shipment',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  submittalDate: {
    fieldTypeId: 'submittalDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Submittal Date',
      longDescription: 'Date when the submittal was made',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  submittedBy: {
    fieldTypeId: 'submittedBy',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Submitted By',
      longDescription: 'Name or ID of the person who submitted the document',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: 'Submitted by is required',
  },
  reviewedBy: {
    fieldTypeId: 'reviewedBy',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Reviewed By',
      longDescription: 'Name or ID of the person who reviewed the submittal',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  reviewDate: {
    fieldTypeId: 'reviewDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Review Date',
      longDescription: 'Date when the submittal was reviewed',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  lineItemsSummary: {
    fieldTypeId: 'lineItemsSummary',
    fieldDataTypeId: 'table',
    description: {
      shortLabel: 'Line Items Summary',
      longDescription: 'Summary table of line items in the Quote',
    },
    status: RecordStatus.Active,
    defaultValue: [],
    validation: fieldDataType.table.validation,
    errorMessage: fieldDataType.table.errorMessage,
  },
  lineItemsCommission: {
    fieldTypeId: 'lineItemsCommission',
    fieldDataTypeId: 'table',
    description: {
      shortLabel: 'Line Items Commission',
      longDescription: 'Commission details table for line items in the Quote',
    },
    status: RecordStatus.Active,
    defaultValue: [],
    validation: fieldDataType.table.validation,
    errorMessage: fieldDataType.table.errorMessage,
  },
  totalSaleAmount: {
    fieldTypeId: 'totalSaleAmount',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Total Sale Amount',
      longDescription: 'Sum of all extended prices in the quote',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  paymentTerms: {
    fieldTypeId: 'paymentTerms',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Payment Terms',
      longDescription: 'Terms of payment for the quote',
    },
    status: RecordStatus.Active,
    defaultValue: 'Net 30 days',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  }
};
