import { Metric } from '../types/System.types';

/**
 * State interface for the metric editor reducer
 * @interface MetricEditorState
 * @property {Metric | null} editedMetric - The currently edited metric
 * @property {Object} history - Contains undo/redo history stacks
 * @property {Array} history.past - Stack of previous metric states with their modified fields
 * @property {Array} history.future - Stack of undone metric states for potential redo
 */
export interface MetricEditorState {
    editedMetric: Metric | null;
    history: {
        past: Array<{
            metric: Metric;
            modifiedFields: Set<string>;
        }>;
        future: Array<{
            metric: Metric;
            modifiedFields: Set<string>;
        }>;
    };
}

/**
 * Union type defining all possible actions for the metric editor reducer
 * @typedef {Object} MetricEditorAction
 */
export type MetricEditorAction = 
    | { type: 'UPDATE_METRIC'; payload: { field: keyof Metric; value: any; modifiedFields: Set<string> } }
    | { type: 'SET_METRIC'; payload: Metric | null }
    | { type: 'UNDO' }
    | { type: 'REDO' };

/**
 * Reducer for managing metric editor state with undo/redo functionality
 * @param {MetricEditorState} state - Current state of the metric editor
 * @param {MetricEditorAction} action - Action to be performed on the state
 * @returns {MetricEditorState} New state after applying the action
 */
export const metricEditorReducer = (state: MetricEditorState, action: MetricEditorAction): MetricEditorState => {
    switch (action.type) {
        case 'UPDATE_METRIC':
            if (!state.editedMetric) return state;
            
            const updatedMetric = {
                ...state.editedMetric,
                [action.payload.field]: action.payload.value
            };

            return {
                editedMetric: updatedMetric,
                history: {
                    past: [...state.history.past, {
                        metric: state.editedMetric,
                        modifiedFields: action.payload.modifiedFields
                    }],
                    future: []
                }
            };

        case 'SET_METRIC':
            return {
                editedMetric: action.payload,
                history: {
                    past: [],
                    future: []
                }
            };

        case 'UNDO':
            if (state.history.past.length === 0) return state;
            
            const previous = state.history.past[state.history.past.length - 1];
            const newPast = state.history.past.slice(0, -1);

            return {
                editedMetric: previous.metric,
                history: {
                    past: newPast,
                    future: state.editedMetric ? [{
                        metric: state.editedMetric,
                        modifiedFields: previous.modifiedFields
                    }, ...state.history.future] : state.history.future
                }
            };

        case 'REDO':
            if (state.history.future.length === 0) return state;
            
            const next = state.history.future[0];
            const newFuture = state.history.future.slice(1);

            return {
                editedMetric: next.metric,
                history: {
                    past: [...state.history.past, {
                        metric: state.editedMetric!,
                        modifiedFields: next.modifiedFields
                    }],
                    future: newFuture
                }
            };

        default:
            return state;
    }
}; 