import { FC, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Skeleton,
    Typography,
    Grid,
    Cascader,
} from 'antd';
import { theme } from 'antd';

import FlexPage from '../components/atoms/FlexPage';
import Navb from '../components/Navb';
import { EntityContext, EntityProvider } from '../providers/EntityProvider';
import { Entity as EntityType } from '../types/System.types';
import Entity from '../components/Entity';
import { SearchWrapper } from '../components/SearchWrapper';
import FlexBox from '../components/atoms/FlexBox';
import FlexContent from '../components/atoms/FlexContent';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const EntitiesPageContent: FC = () => {
    const navigate = useNavigate();
    const { entityId, recordId } = useParams<{ entityId?: string; recordId?: string }>();
    const screens = useBreakpoint();
    const { token } = theme.useToken();

    const {
        entities,
        selectedEntity,
        selectEntity,
        selectEntityRecord,
    } = useContext(EntityContext);

    useEffect(() => {
        if (entityId !== undefined) {
            selectEntity(entityId);
        }
    }, [entityId, selectEntity]);

    useEffect(() => {
        if (recordId !== undefined) {
            selectEntityRecord(recordId);
        }
    }, [recordId, selectEntityRecord]);

    const handleEntitySelect = async (entityId: string | undefined) => {
        if (!entityId) {
            navigate('/entities');
            return;
        }
        await selectEntity(entityId);
        navigate(`/entities/${entityId}`);
    };

    if (!entities) {
        return <Skeleton active />;
    }

    return (
        <FlexPage>
            <Navb />
            <SearchWrapper
                index="entityRecord"
                filters={`docIdParent:${selectedEntity?.docId}`}
            >
                <FlexContent>
                    <FlexBox noGrow wrap alignStart justifyStart stretch>
                        <FlexBox noGrow stretch alignStart>
                            <Cascader.Panel
                                options={entities.map((entity: EntityType) => ({
                                    label: entity?.description?.shortLabel || entity?.description?.shortDescription || entity?.docId,
                                    value: entity.docId,
                                }))}
                                onChange={([value]) => handleEntitySelect(value as string)}
                                value={selectedEntity?.docId ? [selectedEntity.docId] : []}
                                style={{

                                    maxHeight: screens.xs ? '200px' : 'auto',
                                    color: token.colorText,
                                }}
                            />
                        </FlexBox>
                        <FlexBox stretch alignStart>
                            {selectedEntity ?
                                <Entity key={selectedEntity.docId} />
                                :
                                <Text>
                                    Select a record type.
                                </Text>
                            }
                        </FlexBox>
                    </FlexBox>
                </FlexContent>
            </SearchWrapper>
        </FlexPage>
    );
};

const EntitiesPage: FC = () => (
    <EntityProvider>
        <EntitiesPageContent />
    </EntityProvider>
);

export default EntitiesPage;