import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { 
    collection, 
    query, 
    where, 
    onSnapshot, 
    QueryConstraint, 
    orderBy as firestoreOrderBy,
    limit as firestoreLimit,
    startAt as firestoreStartAt,
    QuerySnapshot,
    QueryDocumentSnapshot,
    DocumentSnapshot,
    FirestoreError,
    Unsubscribe
} from 'firebase/firestore';

import { 
    DocDataWithId, 
} from '../../types/System.types';
import { DataContext } from '../../providers/DataProvider';
import { ListFilters, ListOrderBy } from '../../providers/DataProvider';

/**
 * Interface representing the return value of the useBoundCollection hook.
 * @template T - The type of document data, extending DocDataWithId.
 */
export interface BoundCollectionDocs<T extends DocDataWithId = DocDataWithId> {
    docs: DocumentSnapshot<T>[]; 
    data: T[];
    /** Error object if an error occurred, null otherwise */
    error: Error | null;
    /** Boolean indicating if the data is currently being loaded */
    loading: boolean;
    /** Function to manually refresh the data */
    refresh: () => Promise<void>;
    /** Function to update the filters applied to the collection */
    setFilters: (filters: ListFilters) => void;
    /** Function to update the ordering of the collection */
    setOrderBy: (orderBy: ListOrderBy) => void;
    /** Function to update the limit of documents to fetch */
    setLimit: (limit: number) => void;
    /** Function to update the starting point for fetching documents */
    setStartAt: (startAt: T | DocumentSnapshot<T> | undefined) => void; 
    /** Current filters applied to the collection */
    filters: ListFilters | undefined;
    /** Current ordering applied to the collection */
    orderBy: ListOrderBy | undefined;
    /** Current limit of documents to fetch */
    limit: number | undefined;
    /** Current starting point for fetching documents */
    startAt: DocumentSnapshot | undefined;
    /** Function to clear all data and reset states to initial values */
    clear: () => void;
}

/**
 * Interface for the parameters of the useBoundCollection hook.
 */
export interface UseBoundCollectionParams {
    /** Firestore collection path */
    path?: string;
    /** Whether the collection binding is enabled */
    enabled?: boolean;
    /** Initial filters to apply to the collection */
    initialFilters?: ListFilters;
    /** Initial ordering to apply to the collection */
    initialOrderBy?: ListOrderBy;
    /** Initial limit of documents to fetch */
    initialLimit?: number;
    /** Initial starting point for fetching documents */
    initialStartAt?: DocumentSnapshot;
}

/**
 * A custom hook for binding to a Firestore collection with real-time updates.
 * @template T - The type of document data, extending DocDataWithId.
 * @param {UseBoundCollectionParams} params - The parameters for configuring the collection binding.
 * @returns {BoundCollectionDocs<T>} An object containing the bound collection data and utility functions.
 */
export const useBoundCollection = <T extends DocDataWithId = DocDataWithId>({
    path,
    enabled = true,
    initialFilters,
    initialOrderBy,
    initialLimit = 300,
    initialStartAt
}: UseBoundCollectionParams): BoundCollectionDocs<T> => {
    const { firestore } = useContext(DataContext);
    const [docs, setDocs] = useState<DocumentSnapshot<T>[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [loading, setLoading] = useState(true);
    const [filters, setFiltersState] = useState<ListFilters | undefined>(initialFilters);
    const [orderBy, setOrderByState] = useState<ListOrderBy | undefined>(initialOrderBy);
    const [limit, setLimitState] = useState<number | undefined>(initialLimit);
    const [startAt, setStartAtState] = useState<DocumentSnapshot | undefined>(initialStartAt);

    /**
     * Fetches data from Firestore and sets up a real-time listener.
     * @returns {Promise<Unsubscribe | undefined>} A function to unsubscribe from the listener, or undefined.
     */
    const fetchData = useCallback(async (): Promise<Unsubscribe | undefined> => {
        if (!enabled || !path) {
            setError(null);
            setLoading(false);
            return;
        }

        try {
            const collectionRef = collection(firestore, path);
            const queryConstraints: QueryConstraint[] = [
                ...(filters?.map((f) => where(f.field, f.operator, f.value)) ?? []),
                ...(orderBy?.map((o) => firestoreOrderBy(o.field, o.direction)) ?? []),
                ...(limit !== undefined ? [firestoreLimit(limit)] : []),
                ...(startAt !== undefined ? [firestoreStartAt(startAt)] : [])
            ];

            const q = query(collectionRef, ...queryConstraints);

            return onSnapshot(
                q,
                (querySnapshot: QuerySnapshot) => {
                    const newDocs = querySnapshot.docs as QueryDocumentSnapshot<T>[];

                    setDocs(newDocs);
                    setError(null);
                    setLoading(false);
                },
                (error: FirestoreError) => {
                    console.error(`Failed to bind documents: ${error.message} | path: ${path}`);
                    setError(error);
                    setLoading(false);
                }
            );
        } catch (error) {
            const firestoreError = error as FirestoreError;
            console.error(`Error in useBoundCollection: ${firestoreError.message}`);
            setError(firestoreError);
            setLoading(false);
        }
    }, [firestore, path, enabled, filters, orderBy, limit, startAt]);

    useEffect(() => {
        let isCancelled = false;
        let unsubscribe: Unsubscribe | undefined;
        
        fetchData().then(unsub => {
            if (!isCancelled) {
                unsubscribe = unsub;
            }
        });

        return () => {
            isCancelled = true;
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [fetchData]);

    const setFilters = useCallback((newFilters: ListFilters) => {
        setFiltersState(newFilters);
        setLoading(true);
    }, []);

    const setOrderBy = useCallback((newOrderBy: ListOrderBy) => {
        setOrderByState(newOrderBy);
        setLoading(true);
        fetchData();
    }, [fetchData]);

    const setLimit = useCallback((newLimit: number) => {
        setLimitState(newLimit);
        setLoading(true);
        fetchData();
    }, [fetchData]);

    const setStartAt = useCallback((newStartAt: T | DocumentSnapshot<T> | undefined) => {
        if (newStartAt && 'docId' in newStartAt) {
            const docSnapshot = docs.find(doc => doc.id === newStartAt.docId);
            setStartAtState(docSnapshot);
        } else {
            setStartAtState(newStartAt as DocumentSnapshot<T> | undefined);
        }
        setLoading(true);
        fetchData();
    }, [fetchData, docs]);

    /**
     * Refreshes the data by re-fetching from Firestore.
     */
    const refresh = useCallback(async () => {
        setLoading(true);
        await fetchData();
    }, [fetchData]);

    /**
     * Function to clear all data and reset states to initial values
     */
    const clear = useCallback(() => {
        setDocs([]);
        setError(null);
        setLoading(false);
    }, []);

    return useMemo(() => {
        const data = docs.map((doc) => ({ docId: doc.id, ...doc.data() } as T));
        
        return { 
            docs,
            data,
            error, 
            loading, 
            refresh,
            setFilters,
            setOrderBy,
            setLimit,
            setStartAt,
            filters,
            orderBy,
            limit,
            startAt,
            clear
        };
    }, [docs, 
        error, 
        loading, 
        refresh, 
        setFilters, 
        setOrderBy, 
        setLimit, 
        setStartAt, 
        filters, 
        orderBy, 
        limit, 
        startAt, 
        clear
    ]);
};