import { FC, useContext } from 'react';
import { Typography } from 'antd';
import { Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';

import { TimeType } from '../../types/System.types';
import { LayoutEditorContext } from '../../providers/editor/LayoutEditorProvider';

const { Text } = Typography;

const MetaInfo: FC = () => {
    const editor = useContext(LayoutEditorContext);
    const meta = editor?.structure?.meta;

    if (!meta) return null;

    const formatTime = (time: TimeType | undefined) => {
        if (time instanceof Timestamp) {
            return dayjs(time.toDate()).format('YYYY-MM-DD HH:mm');
        }
        return 'N/A';
    };

    return (
        <Text type="secondary">
            <small>
                Created {formatTime(meta.created)}
                &nbsp;
                Last Modified {formatTime(meta.lastModified)}
                &nbsp;
                User {meta.userId || 'N/A'}
            </small>
        </Text>
    );
};

export default MetaInfo; 