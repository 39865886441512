import { FC, ReactNode, useContext } from 'react';
import { FormContext, FormContextType } from '../FormProvider';
import { LayoutEditorContext } from './LayoutEditorProvider';
import { Form, FormVersion, FormType, EditorUILayout } from '../../types/System.types';

/**
 * Creates a mock form context for preview purposes.
 * This context simulates a form environment with read/write permissions
 * and basic form structure for previewing layouts in the editor.
 * 
 * @param {EditorUILayout} editorLayout - The current layout being edited
 * @returns {FormContextType} A mock form context for preview
 */
const createPreviewContext = (editorLayout: EditorUILayout): FormContextType => ({
    selectedForm: {
        data: {
            title: 'Preview Form',
            formType: 'preview',
            currentVersionId: 'preview',
            organization: 'Preview',
            userRoles: {}
        } as Form,
        loading: false,
        error: null
    },
    selectedFormVersion: {
        data: {
            state: 'draft',
            fields: {},
            entityReferences: {}
        } as FormVersion,
        loading: false,
        error: null
    },
    formTypeOfSelectedForm: {
        data: {
            defaultUILayout: editorLayout,
            docId: 'preview',
            possibleStates: {
                Draft: { allowedNextStates: ['draft'] }
            }
        } as FormType,
        loading: false,
        error: null
    },
    formVersionAccess: {
        canRead: true,
        canWrite: true,
        loading: false,
        error: null
    },
    insideFormContext: true
} as FormContextType);

/**
 * PreviewFormProvider creates a simulated form context for previewing
 * form layouts in the editor. It wraps the editor's current layout
 * in a mock form context that allows for realistic preview rendering.
 * 
 * @component
 * @param {Object} props - Component props
 * @param {ReactNode} props.children - Child components to render within the preview context
 * @returns {JSX.Element} The provider component with preview context
 * @throws {Error} If used outside of LayoutEditorProvider context
 */
export const PreviewFormProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const editor = useContext(LayoutEditorContext);
    
    if (!editor) {
        throw new Error('PreviewFormProvider must be used within a LayoutEditorProvider');
    }

    const value = createPreviewContext(editor.layout);

    return (
        <FormContext.Provider value={value}>
            {children}
        </FormContext.Provider>
    );
}; 