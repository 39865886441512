import { FC, PropsWithChildren } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch';
import { DEFAULT_SEARCH_HITS_PER_PAGE } from '../types/System.Parameters.types';
import { searchClient } from '../services/searchConfig';
import { algoliaConfig } from '../config';

/**
 * Enum for the different search indices
 */
type SearchIndex = 'all' | 'entityRecord' | 'entity' | 'entityType';

interface SearchWrapperProps extends PropsWithChildren {
  /**
   * Search index name. Defaults to searchConfig.indices.all
   */
  index?: SearchIndex;
  /**
   * Number of results to return per page
   */
  hitsPerPage?: number;
  /**
   * Filters to apply to the search
   */
  filters?: string;
}

/**
 * Provides basic search context for Algolia InstantSearch.
 * Preserves search state when components unmount.
 * 
 * @example
 * <SearchWrapper hitsPerPage={20}>
 *   <SearchComponent />
 * </SearchWrapper>
 */
export const SearchWrapper: FC<SearchWrapperProps> = ({ 
  children,
  index = 'all',
  hitsPerPage = DEFAULT_SEARCH_HITS_PER_PAGE,
  filters = ''
}) => {

  const indexName = (() => {
    switch (index) {
      case 'all':
        return algoliaConfig.allIndexName;
      case 'entity':
        return algoliaConfig.entityIndexName;
      case 'entityRecord':
        return algoliaConfig.entityRecordIndexName;
      default:
        return algoliaConfig.allIndexName;
    }
  })();

  return (
    <InstantSearch 
      searchClient={searchClient} 
      indexName={indexName}
      future={{ preserveSharedStateOnUnmount: true }}
    >
      <Configure 
        hitsPerPage={hitsPerPage} 
        filters={filters}
      />
      {children}
    </InstantSearch>
  );
}; 