import { FC, useState, useContext } from 'react';
import { Typography, Segmented, theme, Button } from 'antd';

import Navb from '../components/Navb';
import FlexPage from '../components/atoms/FlexPage';
import FlexContent from '../components/atoms/FlexContent';
import NotificationsTable from '../components/notifications/NotificationsTable';
import { MAX_LIMITED_WIDTH_VIEWPORT_NARROW } from '../types/System.Parameters.types';
import FlexBox from '../components/atoms/FlexBox';
import { UserContext } from '../providers/UserProvider';

const { Title, Text } = Typography;
const { useToken } = theme;

type ViewType = 'Unread' | 'All';

const NotificationsPage: FC = () => {
    const [view, setView] = useState<ViewType>('All');
    const { token } = useToken();
    const { readNotifications } = useContext(UserContext);

    return (
        <FlexPage>
            <Navb />
            <FlexContent style={{ maxWidth: MAX_LIMITED_WIDTH_VIEWPORT_NARROW }}>
                <FlexBox
                    noGrow
                    stretch
                    gap={20}
                >
                    <Title
                        level={4}
                        style={{ margin: 0 }}
                    >
                        Notifications
                    </Title>
                    <Segmented
                        options={['All', 'Unread']}
                        style={{ backgroundColor: token.colorFillTertiary }}
                        value={view}
                        onChange={(value) => setView(value as ViewType)}
                    />
                </FlexBox>
                <NotificationsTable view={view} />
                {view === 'Unread' && readNotifications && readNotifications.length > 0 && (
                    <Button
                        type="link"
                        size="small"
                        onClick={() => setView('All')}
                    >
                        <Text type="secondary">
                            {readNotifications.length} read notification{readNotifications.length !== 1 ? 's' : ''}
                        </Text>
                    </Button>
                )}
            </FlexContent>
        </FlexPage>
    );
};

export default NotificationsPage; 