import {
  FC,
  createContext,
  ReactNode,
} from 'react';

import { firestore } from '../services/startFirestore';
import { Firestore, OrderByDirection, WhereFilterOp } from 'firebase/firestore';

export const DataContext = createContext<DataContextType>({
  firestore: firestore,
});

/**
 * Type definition for the DataContext.
 */
export interface DataContextType {
  /** The Firestore instance. */
  firestore: Firestore;
}

/**
 * Filters for the forms list
 */
export type ListFilters =
    Array<{
        field: string;
        operator: WhereFilterOp;
        value: any;
    }>

/**
 * Order by configuration for list queries
 */
export type ListOrderBy =
    Array<{
        field: string;
        direction?: OrderByDirection;
    }>

export const DataProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <DataContext.Provider value={{ firestore }}>
      {children}
    </DataContext.Provider>
  );
};