import { QosValues, QosValidationResult } from '../../use/orgs/useClwQos'

export const validateQosValues = (
  qos: QosValues
): QosValidationResult => {
  // Ensure values are non-negative
  if (
    qos.totalLines < 0 ||
    qos.clwRepresentedLines < 0 ||
    qos.clwSolelyListedLines < 0 ||
    qos.clwQuotedLines < 0
  ) {
    return {
      isValid: false,
      message: 'Values cannot be negative',
      type: 'error',
    };
  }

  // Ensure values do not exceed totalLines
  if (
    qos.clwRepresentedLines > qos.totalLines ||
    qos.clwSolelyListedLines > qos.totalLines ||
    qos.clwQuotedLines > qos.totalLines
  ) {
    return { isValid: false, message: 'Input must be ≤ Total Lines', type: 'error' };
  }

  // Ensure clwRepresentedLines is greater than or equal to clwSolelyListedLines
  if (qos.clwRepresentedLines < qos.clwSolelyListedLines) {
    return {
      isValid: false,
      message: 'Solely Listed must be ≤ Represented Lines',
      type: 'error',
    };
  }

  // Add a warning for totalLines > 1000
  if (qos.totalLines > 1000) {
    return {
      isValid: true,
      message: 'Total Lines > 1000',
      type: 'warning',
    };
  }

  return { isValid: true, type: 'success' };
};
