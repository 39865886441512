import { FirebaseStorage, getStorage, connectStorageEmulator } from 'firebase/storage';
import { app } from './startFirestore';
import { environmentType } from '../config';

/**
 * Connect Storage emulator on development environment
 * @param storage Firebase Storage instance
 */
export const connectStorageEmulatorOnDev = (storage: FirebaseStorage) => {
    if (environmentType === 'development') {
        try {
            connectStorageEmulator(storage, 'localhost', 9199);
            console.log('Connected to Firebase Storage emulator');
        } catch (error) {
            console.log('Firebase Storage emulator is not running');
        }
    }
};

// Initialize Firebase Storage
export const storage = getStorage(app);

// Connect to Storage emulator if in development
connectStorageEmulatorOnDev(storage); 