import { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout, List, Button, Divider, Empty, Typography } from 'antd';
import Icon from '../components/atoms/Icon';
import { SearchInput } from '../components/atoms/SearchInput';
import { SearchResultCard } from '../components/atoms/SearchResultCard';
import FlexBox from '../components/atoms/FlexBox';
import { useSearch } from '../use/useSearch';
import { SearchResultItem, normalizeSearchResult } from '../services/searchConfig';

const { Content } = Layout;

export default function SearchPage() {
  const { searchResults, setSearchQuery } = useSearch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState('');

  const handleExit = useCallback(() => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const q = searchParams.get('q');
    if (q) {
      setSearchValue(q);
      setSearchQuery(q);
    }
  }, [location.search, setSearchQuery]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape')
        handleExit();
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleExit]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setSearchQuery(value);
    navigate(`/search?q=${encodeURIComponent(value)}`, { replace: true });
  };

  return (

    <Layout style={{
      minHeight: '100%',
      display: 'flex',
    }}>
      <Content style={{ padding: '20px' }}>
        <FlexBox
          stretch
          noGrow
          alignStart
          gap={5}
        >
          <Button
            type='text'
            icon={<Icon.ChevronLeft />}
            onClick={handleExit}
          />
          <FlexBox column gap={0}>
            <SearchInput
              autoFocus
              placeholder="Search anything..."
              value={searchValue}
              onChange={handleSearch}
            />
            <FlexBox stretch>
              <Typography.Text>
                <small>All search results</small>
              </Typography.Text>
              <Typography.Text type='secondary'>
                <small>ESC to exit</small>
              </Typography.Text>
            </FlexBox>
          </FlexBox>
          <Button
            type='text'
            icon={<Icon.X />}
            onClick={handleExit}
          />
        </FlexBox>

        <Divider style={{
          marginTop: 5,
          marginBottom: 15,
        }} />
        {searchValue ?
          (
            <List
              itemLayout="vertical"
              dataSource={searchResults.map(normalizeSearchResult)}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No results found"
                  />
                )
              }}
              renderItem={(item: SearchResultItem) => (
                <List.Item>
                  <SearchResultCard {...item} />
                </List.Item>
              )}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Type something to search..."
            />
          )}
      </Content>
    </Layout>
  );
};