import { useHits, useSearchBox, useInstantSearch, useConfigure } from 'react-instantsearch';
import { Hit } from 'instantsearch.js';

interface SearchResult extends Hit {
  objectID: string;
  name?: string;
  status?: string;
  searchResultURL?: string;
  searchResultTitle?: string;
  collectionName?: string;
}

interface UseSearchOptions {
  filter?: string;
}

/**
 * Hook for searching generic records with optional index and filter configuration
 * @param options Optional configuration for index and filters
 * @returns Search results and controls
 */
export const useSearch = (options?: UseSearchOptions) => {
  const { refine, query, clear } = useSearchBox();
  const { items } = useHits<SearchResult>();
  const { status, error } = useInstantSearch();

  useConfigure({
    filters: options?.filter
  });

  return {
    searchResults: items,
    searchQuery: query,
    setSearchQuery: refine,
    clearSearch: clear,
    isLoading: status === 'loading',
    error: error?.message || null,
  };
};