import { 
    useCallback,
    useContext
} from 'react';
import {
    doc,
    deleteDoc,
} from 'firebase/firestore';

import { DataContext } from '../../providers/DataProvider';
import { DocId } from '../../types/System.types';

/**
 * Interface for data add operations
 */
export interface UseRemove {
    remove: (path: string, docId: DocId) => Promise<void>;
}

/**
 * Hook for managing Firestore data add operations
 * @param firestore - Firestore instance
 * @returns Object with add function
 */
export const useRemove = (): UseRemove => {
    const { firestore } = useContext(DataContext);

    /**
     * Adds data to Firestore
     * @param path - Document path
     * @param data - Data to add
     * @returns Data or null if not found
     */
    const remove = useCallback(async (path: string, docId: DocId) => {
        const docRef = doc(firestore, path, docId);
        try {
            await deleteDoc(docRef);
        } catch (error) {
            console.error('Error adding document:', error);
            throw error;
        }
    }, [firestore]);

    return { remove };
};