import { useContext, useCallback, useState, useEffect } from 'react';
import { useBoundCollection } from './data/useBoundCollection';
import { useBoundDoc } from './data/useBoundDoc';
import { useSet } from './data/useSet';
import { useRemove } from './data/useRemove';
import { Group, User, UserDBRole, GroupMember } from '../types/System.types';
import { UserContext } from '../providers/UserProvider';

interface UseGroupAccessProps {
    groupId: string;
    enabled?: boolean;
}

export interface GroupMemberItem {
    docId: string;
    email: string;
    displayName: string;
    role: UserDBRole;
}

export interface SearchUserItem {
    docId: string;
    email: string;
    displayName: string;
}

export const useGroupAccess = ({ groupId, enabled = true }: UseGroupAccessProps) => {
    const { user, userGroups } = useContext(UserContext);
    const [searchResults, setSearchResults] = useState<SearchUserItem[]>([]);
    const { set } = useSet();
    const { remove } = useRemove();

    const group = useBoundDoc<Group>({
        path: 'groups',
        docId: groupId,
        enabled,
    });

    const members = useBoundCollection<GroupMember>({
        path: `groups/${groupId}/groupMembers`,
        enabled,
    });

    const users = useBoundCollection<User>({
        path: 'users',
        enabled,
    });

    const isAdmin = userGroups['organizationAdmin'] === UserDBRole.editor || 
                   userGroups['organizationAdmin'] === UserDBRole.owner;

    const updateMember = useCallback(async (userId: string, role: UserDBRole) => {
        if (!groupId || !isAdmin) return;

        try {
            await set(`groups/${groupId}/groupMembers`, userId, {
                role,
                meta: {
                    lastModified: new Date(),
                    userId: user?.uid,
                }
            });
            return true;
        } catch (error) {
            console.error('Error updating member:', error);
            throw error;
        }
    }, [groupId, isAdmin, set, user?.uid]);

    const removeMember = useCallback(async (userId: string) => {
        if (!groupId || !isAdmin || userId === user?.uid) return;

        try {
            await remove(`groups/${groupId}/groupMembers`, userId);
            return true;
        } catch (error) {
            console.error('Error removing member:', error);
            throw error;
        }
    }, [groupId, isAdmin, user?.uid, remove]);

    const searchUsers = useCallback((searchTerm: string) => {
        const currentMemberIds = members.data.map(member => member.docId);
        
        const results = users.data
            .filter(user => {
                const matchesSearch = (
                    user.docId === searchTerm || 
                    (typeof user?.email === 'string' && user.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (typeof user?.name === 'string' && user.name.toLowerCase().includes(searchTerm.toLowerCase()))
                );
                const notAlreadyMember = !currentMemberIds.includes(user.docId);
                return (!searchTerm || matchesSearch) && notAlreadyMember;
            })
            .map(user => ({
                docId: user.docId || '',
                email: user.email,
                displayName: user.name || '',
            }));

        setSearchResults(searchTerm ? results : results.slice(0, 5));
    }, [users.data, members.data]);

    const membersList = useCallback((): GroupMemberItem[] => {
        if (!users.data || !members.data) return [];
        
        return members.data
            .map(member => {
                const userDetails = users.data.find(u => u.docId === member.docId);
                if (!userDetails) return null;
                
                return {
                    docId: member.docId || '',
                    email: userDetails.email,
                    displayName: userDetails.name || '',
                    role: member.role,
                };
            })
            .filter((member): member is GroupMemberItem => member !== null);
    }, [users.data, members.data]);

    useEffect(() => {
        if (users.data) {
            searchUsers('');
        }
    }, [users.data, searchUsers]);

    return {
        group: group.data,
        members: membersList(),
        searchResults,
        isAdmin,
        updateMember,
        removeMember,
        searchUsers,
        loading: group.loading || members.loading || users.loading,
        error: group.error || members.error || users.error,
    };
}; 