import { FC } from 'react';
import { Typography, Empty, Select, DatePicker } from 'antd';
import type { Dayjs } from 'dayjs';
import type { RangePickerProps } from 'antd/es/date-picker';
import Navb from '../components/Navb';
import FlexPage from '../components/atoms/FlexPage';
import FlexContent from '../components/atoms/FlexContent';
import FlexBox from '../components/atoms/FlexBox';
import FlexCol from '../components/atoms/FlexCol';
import MetricsChart from '../components/analytics/MetricsChart';
import MetricsTable from '../components/analytics/MetricsTable';
import { MAX_LIMITED_WIDTH_VIEWPORT } from '../types/System.Parameters.types';
import { useAnalytics } from '../use/useAnalytics';

const { Text } = Typography;

const AnalyticsPage: FC = () => {
  const {
    selectedMetric,
    metricGroups,
    loading,
    dateRange,
    selectedTimePeriod,
    selectedGroupBy,
    selectMetric,
    setDateRange,
    setTimePeriod,
    setGroupBy,
    getMetricOptions,
    getGroupOptions,
    getTimePeriodOptions,
    getTimeSeriesData,
  } = useAnalytics();



  // Filter function for Select search
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleDateRangeChange: RangePickerProps['onChange'] = (
    dates,
    _dateStrings
  ) => {
    setDateRange((dates as [Dayjs | null, Dayjs | null]) || [null, null]);
  };

  const timeSeriesData = getTimeSeriesData();

  return (
    <FlexPage>
      <Navb />
      <FlexContent style={{maxWidth: MAX_LIMITED_WIDTH_VIEWPORT}} gap={20}>
          <FlexBox wrap style={{width: '100%'}}>
            <FlexCol gap={0} style={{width: '280px'}}>
              <Text> Select Metric </Text>
              <Select
                placeholder="Select a metric"
                style={{ width: '100%' }}
                options={getMetricOptions()}
                value={selectedMetric?.docId}
                onChange={selectMetric}
                loading={loading.metrics}
                showSearch
                filterOption={filterOption}
                allowClear
              />
            </FlexCol>
            {!selectedMetric ? (
              <FlexCol gap={0} style={{width: '280px'}}></FlexCol>
            ) : (
              <FlexCol gap={0} style={{width: '280px'}}>
                <Text> Group </Text>
                <Select
                  placeholder={loading.groups ? 'Loading groups...' : 'Select a group'}
                  style={{ width: '100%' }}
                  options={getGroupOptions()}
                  value={selectedGroupBy}
                  onChange={setGroupBy}
                  loading={loading.groups}
                  showSearch
                  filterOption={filterOption}
                  allowClear
                />
              </FlexCol>
            )}
          </FlexBox>

          {!selectedMetric ? (
            <FlexBox stretch justifyCenter wrap>
              <Empty description="Select a metric to view analytics" />
            </FlexBox>
          ) : (
            <>
              <FlexBox wrap style={{width: '100%'}}>
                <FlexCol gap={0} style={{width: '280px'}}>
                  <Text> Time Period </Text>
                  <Select
                    placeholder="Select time period"
                    style={{ width: '100%' }}
                    options={getTimePeriodOptions()}
                    value={selectedTimePeriod}
                    onChange={setTimePeriod}
                    showSearch
                    filterOption={filterOption}
                    allowClear
                  />
                </FlexCol>

                <FlexCol gap={0} style={{width: '280px'}}>
                  <Text> Date Range </Text>
                  <DatePicker.RangePicker
                    style={{ width: '100%' }}
                    value={dateRange}
                    onChange={handleDateRangeChange}
                    allowEmpty={[true, true]}
                  />
                </FlexCol>
              </FlexBox>

              <FlexBox column stretch gap={20}>
                <MetricsChart
                  selectedMetric={selectedMetric}
                  selectedGroupBy={selectedGroupBy}
                  timeSeriesData={timeSeriesData}
                  metricGroups={metricGroups}
                  loading={loading.records}
                  showMarkers={true}
                  enableZoom={true}
                />

                <MetricsTable
                  timeSeriesData={timeSeriesData}
                  selectedMetric={selectedMetric}
                  selectedTimePeriod={selectedTimePeriod}
                  loading={loading.records}
                />
              </FlexBox>
            </>
          )}
      </FlexContent>
    </FlexPage>
  );
};

export default AnalyticsPage;
