import { FC, useMemo, lazy, Suspense, useCallback, useState } from 'react';
import {
    Grid,
    Spin,
    message,
    Radio,
} from 'antd';
import type { TableProps } from 'antd/es/table';

import {
    TableColumnType,
    TableAggregationType,
    EntityRecord,
} from '../../../types/System.types';
import {
    CalculateOptimizationRequest,
    OptimizationResult,
} from '../../../use/orgs/useClwTableOptimizer';
import { calculateColumnAggregations, formatAggregation } from './columnSumaryStats';
import { calculateRow } from './formulaCalculator';
import { useFormulaParser } from '../../../use/useFormulaParser';
import { useTableOptimizer } from '../../../use/orgs/useClwTableOptimizer';
import OptimizationModal from './atoms/OptimizationModal';
import FlexCol from '../../atoms/FlexCol';

const MobileTableView = lazy(() => import('./atoms/MobileTableView'));
const DesktopTableView = lazy(() => import('./atoms/DesktopTableView'));

const { useBreakpoint } = Grid;

type TableViewMode = 'internal' | 'print' | 'contractor';

interface TableUIProps {
    dataSource?: any[];
    columns: TableColumnType[];
    onChange?: (updatedData: any[]) => void;
    readOnly?: boolean;
    id?: string;
    showColumnAggregations?: boolean;
    columnAggregations?: {
        [columnKey: string]: TableAggregationType | ColumnAggregationConfig;
    };
    pagination?: TableProps<any>['pagination'];
    scroll?: TableProps<any>['scroll'];
    style?: TableProps<any>['style'];
    className?: TableProps<any>['className'];
    optimizationConfig?: CalculateOptimizationRequest;
    onOptimize?: (result: OptimizationResult) => void;
    userId?: string;
    viewMode?: TableViewMode;
    isBOMTable?: boolean;
    onClearRow?: (index: number) => void;
}

interface ColumnAggregationConfig {
    aggregationType?: TableAggregationType;
    customCalculation?: (values: readonly any[]) => number;
}

interface ViewModeSelectProps {
    value: TableViewMode;
    onChange: (mode: TableViewMode) => void;
    disabled?: boolean;
}

const ViewModeSelect: FC<ViewModeSelectProps> = ({ value, onChange, disabled }) => {
    return (
        <Radio.Group
            value={value}
            onChange={(e) => onChange(e.target.value)}
            disabled={disabled}
            optionType="button"
            buttonStyle="solid"
            style={{ 
                width: '100%', 
                maxWidth: 400,
            }}
            options={[
                { label: 'Internal', value: 'internal' },
                { label: 'Print', value: 'print' },
                { label: 'Contractor', value: 'contractor' },
            ]}
            block
        />
    );
};

// #### TODO SYD-343
const getVisibleColumns = (columns: TableColumnType[], viewMode: TableViewMode) => {
    return columns.filter(column => {
        if (!column.visibility) return true; // Show by default if no visibility settings

        switch (viewMode) {
            case 'print':
                return column.visibility.print !== false;
            case 'internal':
                return column.visibility.internal !== false;
            case 'contractor':
                return column.visibility.contractor !== false;
            default:
                return column.visibility.internal !== false;
        }
    });
};

const hasDistMarkdownColumn = (columns: TableColumnType[]): boolean => {
    return columns.some(col => col.dataIndex === 'distMarkdown');
};

const TableUI: FC<TableUIProps> = ({
    dataSource = [],
    columns,
    onChange,
    readOnly,
    id,
    showColumnAggregations = true,
    columnAggregations = {},
    viewMode = 'internal',
    onOptimize: onOptimizeCallback,
    isBOMTable = false,
    ...props
}) => {
    const screens = useBreakpoint();
    const { evaluateFormula } = useFormulaParser();
    const [messageApi, contextHolder] = message.useMessage();
    const {
        optimizeTableData,
        processOptimizedRow,
        isOptimizing,
        clearMarkdown,
        calculateMaxReduction,
        calculateMaxObaseReduction,
    } = useTableOptimizer();

    const [isOptimizeModalOpen, setIsOptimizeModalOpen] = useState(false);
    const [currentViewMode, setCurrentViewMode] = useState<TableViewMode>(viewMode);

    const onCellChange = (rowIndex: number, columnKey: string, value: any, entityRecord?: EntityRecord | null) => {
        if (onChange && !readOnly) {
            const updatedData = [...dataSource];
            const currentRow = { ...updatedData[rowIndex] };

            currentRow[columnKey] = value;

            if (entityRecord?.fields) {
                columns.forEach(column => {
                    const field = entityRecord.fields[column.dataIndex];
                    if (field?.value !== undefined) {
                        currentRow[column.dataIndex] = field.value;
                    }
                });
            }

            updatedData[rowIndex] = calculateRow(currentRow, columns, evaluateFormula);
            onChange(updatedData);
        }
    };

    const onAddRow = () => {
        if (onChange && !readOnly) {
            const newRow = columns.reduce<Record<string, any>>((acc, col) => ({
                ...acc,
                [col.dataIndex]: null
            }), {});
            onChange([...dataSource, newRow]);
        }
    };

    const onRemoveRow = (index: number) => {
        if (onChange && !readOnly) {
            const updatedData = [...dataSource];
            updatedData.splice(index, 1);
            onChange(updatedData);
        }
    };

    const columnAggregationResults = useMemo(() =>
        calculateColumnAggregations(dataSource, columns, showColumnAggregations),
        [dataSource, columns, showColumnAggregations]
    );

    const onOptimize = useCallback(async (targetReduction: number) => {
        if (!dataSource || !columns) {
            messageApi.error('Missing required data or columns');
            return;
        }

        try {
            const result = await optimizeTableData(
                dataSource,
                columns,
                {
                    data: dataSource,
                    targetReduction
                }
            );

            if (result.modelMeta?.success && onChange) {
                const updatedData = result.markdownPercents.map((markdown, index) =>
                    processOptimizedRow(dataSource[index], markdown, columns, evaluateFormula)
                );
                onChange(updatedData);

                if (onOptimizeCallback) {
                    onOptimizeCallback(result);
                }

                messageApi.success(result.modelMeta?.message || 'Optimization complete');
            } else {
                messageApi.error(result.modelMeta?.message || 'Optimization failed');
            }
        } catch (error) {
            messageApi.error('Failed to optimize table');
        }
    }, [dataSource, columns, optimizeTableData, processOptimizedRow, evaluateFormula, onChange, onOptimizeCallback, messageApi]);


    const onClearMarkdown = () => {
        if (onChange) {
            clearMarkdown(dataSource, columns, evaluateFormula, onChange);
        }
    };

    const onOptimizeClick = () => {
        setIsOptimizeModalOpen(true);
    };

    const visibleColumns = useMemo(() =>
        getVisibleColumns(columns, currentViewMode),
        [columns, currentViewMode]
    );

    const showOptimization = isBOMTable && hasDistMarkdownColumn(columns);

    const onClearRow = useCallback((index: number) => {
        if (!onChange || !dataSource) return;
        const newData = [...dataSource];
        const clearedRow = { ...newData[index] };
        newData[index] = clearedRow.lineId ? { lineId: clearedRow.lineId } : {};
        
        onChange(newData);
        messageApi.success('Row cleared');
    }, [dataSource, onChange, messageApi]);

    const tableProps = {
        dataSource,
        columns: visibleColumns,
        onCellChange: onCellChange,
        readOnly,
        id,

        showColumnAggregations,
        columnAggregationResults,
        formatAggregation,
        onOptimize: onOptimize,
        onClearMarkdown: onClearMarkdown,
        isOptimizing,
        optimizeProps: {
            onOptimizeClick: onOptimizeClick,
            isOptimizing,
        },
        onAddRow,
        onRemoveRow,
        onClearRow,
        ...props
    };

    return (
        <FlexCol>
            {contextHolder}
            {isBOMTable && (
                <ViewModeSelect
                    value={currentViewMode}
                    onChange={setCurrentViewMode}
                    disabled={readOnly}
                />
            )}
            <Suspense fallback={<Spin size="large" />}>
                {!screens.md ? (
                    <MobileTableView {...tableProps} showOptimization={showOptimization} />
                ) : (
                    <DesktopTableView {...tableProps} showOptimization={showOptimization} />
                )}
            </Suspense>
            {showOptimization && (
                <OptimizationModal
                    isOpen={isOptimizeModalOpen}
                    onClose={() => setIsOptimizeModalOpen(false)}
                    onConfirm={async (amount) => {
                        await onOptimize(amount);
                        setIsOptimizeModalOpen(false);
                    }}
                    isOptimizing={isOptimizing}
                    maxOBaseReduction={calculateMaxObaseReduction(dataSource)}
                    maxCutPriceReduction={calculateMaxReduction(dataSource)}
                />
            )}
        </FlexCol>
    );
};

export default TableUI;
