import { FC, useContext, useState } from 'react';
import { Select, Tooltip, message, theme } from 'antd';
import { CloseOutlined, CheckOutlined, QuestionOutlined, UserAddOutlined, DownOutlined } from '@ant-design/icons';
import { UserContext } from '../../providers/UserProvider';
import { FormContext } from '../../providers/FormProvider';

const { useToken } = theme;

/** Control for assigning forms to users */
const FormAssignControl: FC = () => {
    const { token } = useToken();
    const [isAssigning, setIsAssigning] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const { selectedForm } = useContext(FormContext);
    const { user, users, assignFormToUser } = useContext(UserContext);

    const handleChange = async (value: string) => {
        if (!selectedForm.data?.docId) return;

        setIsAssigning(true);

        try {
            const userId = value === 'self' ? undefined : value === 'clear' ? null : value;

            await assignFormToUser(selectedForm.data.docId, userId);
            messageApi.success(
                value === 'clear'
                    ? 'Form unassigned'
                    : value === 'self'
                        ? 'Form assigned to you'
                        : `Form assigned to ${users.find(u => u.docId === value)?.email ?? value}`
            );
        } catch (err) {
            messageApi.error(`Failed to assign form: ${err instanceof Error ? err.message : String(err)}`);
        } finally {
            setIsAssigning(false);
        }
    };

    const currentValue = selectedForm.data?.assignedTo === user?.uid
        ? 'self'
        : selectedForm.data?.assignedTo || '';

    const quickActions = [];

    if (selectedForm.data?.assignedTo !== user?.uid) {
        quickActions.push({
            value: 'self',
            label: (
                <span style={{ color: token.colorPrimary }}>
                    <CheckOutlined size={20}  /> Assign to me
                </span>
            ),
            title: '',
            disabled: false
        });
    }

    if (selectedForm.data?.assignedTo) {
        quickActions.push({
            value: 'clear',
            label: (
                <span style={{ color: token.colorPrimary }}>
                    <CloseOutlined /> Clear assignment
                </span>
            ),
            title: '',
            disabled: false
        });
    }

    const userOptions = users
        ?.filter(u => u.docId !== user?.uid)
        .map(u => ({
            value: u.docId,
            label: (
                <>
                    <UserAddOutlined /> {u.name || u.email?.split('@')[0] || u.docId} {<span style={{ color: token.colorTextSecondary }}>{u?.email}</span>}
                </>
            ),
            title: `${u.name || ''} ${u.email}`.trim()
        })) ?? [];

    return (
        <Tooltip title="Assign form to...">
            {contextHolder}
            <Select
                value={currentValue}
                onChange={handleChange}
                loading={isAssigning}
                style={{ 
                    width: 220,
                    border: `1px solid ${token.colorPrimaryBorder}`,
                    borderRadius: token.borderRadiusLG,
                    color: `${token.colorPrimary} !important`
                }}
                prefix={currentValue === '' ? 
                    <QuestionOutlined style={{ color: token.colorPrimary }} /> : 
                    <UserAddOutlined style={{ color: token.colorPrimary }} />
                }
                suffixIcon={<DownOutlined style={{ color: token.colorPrimary, pointerEvents: 'none' }} />}
                variant='borderless'
                dropdownStyle={{ minWidth: 320 }}
                optionFilterProp='title'
                showSearch
                options={[
                    ...quickActions,
                    ...userOptions
                ]}
                labelInValue={false}
                placeholder={isDropdownOpen ? "Search users..." : undefined}
                onDropdownVisibleChange={setIsDropdownOpen}
                labelRender={(props: { value: string | number } & Record<string, any>) => {
                    if (isDropdownOpen) return <>Search users...</>;
                    if (props.value === '') {
                        return (
                            <span style={{ color: token.colorPrimary, pointerEvents: 'none' }}>
                                Unassigned
                            </span>
                        );
                    }
                    if (props.value === 'self') {
                        return (
                            <span style={{ color: token.colorPrimary, pointerEvents: 'none' }}>
                                Assigned to me
                            </span>
                        );
                    }
                    return (
                        <span style={{ color: token.colorPrimary, pointerEvents: 'none' }}>
                            Assigned to {props.title?.split(/[ @.]/)[0] || String(props.value).split(/[ @.]/)[0]}
                        </span>
                    );
                }}
                filterOption={(input, option) => 
                    option?.title?.toLowerCase().includes(input.toLowerCase()) ?? false
                }
            />
        </Tooltip>
    );
};

export default FormAssignControl; 