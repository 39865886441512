import { FC } from 'react';
import { Typography, Empty, theme, Grid } from 'antd';
import Plot from 'react-plotly.js';
import type { Data, Layout, Config, ModeBarDefaultButtons } from 'plotly.js';
import { Metric, MetricGroupedBy } from '../../types/System.types';
import FlexBox from '../atoms/FlexBox';
import FlexCard from '../atoms/FlexCard';

const { useToken } = theme;
const { useBreakpoint } = Grid;

interface MetricsChartProps {
  selectedMetric: Metric | null;
  selectedGroupBy: string | null;
  timeSeriesData: {
    dates: string[];
    dateHover: string[];
    values: { [date: string]: { value: number; count: number } };
  } | null;
  metricGroups: MetricGroupedBy[] | null;
  loading: boolean;
  chartHeight?: number;
  showMarkers?: boolean;
  lineShape?: 'linear' | 'spline';
  markerSize?: number;
  enableZoom?: boolean;
  enablePanning?: boolean;
  onDataPointClick?: (point: { date: string; value: number; count: number }) => void;
  onZoomChange?: (range: { start: string; end: string }) => void;
}

const { Title, Text } = Typography;

const MetricsChart: FC<MetricsChartProps> = ({
  selectedMetric,
  selectedGroupBy,
  timeSeriesData,
  metricGroups,
  loading,
  chartHeight = 400,
  showMarkers = true,
  lineShape = 'spline',
  markerSize = 8,
  enableZoom = false,
  enablePanning = false,
  onDataPointClick,
  onZoomChange,
}) => {
  const { token } = useToken();
  const screens = useBreakpoint();
  const plotData: Data[] = timeSeriesData ? [{
    x: timeSeriesData.dates,
    y: timeSeriesData.dates.map((date: string) => timeSeriesData.values[date].value),
    text: timeSeriesData.dateHover,
    type: 'scatter',
    mode: showMarkers ? 'lines+markers' : 'lines',
    line: {
      color: token.colorPrimary,
      width: 2,
      shape: lineShape,
    },
    marker: showMarkers ? {
      color: token.colorPrimary,
      size: markerSize,
      line: {
        color: token.colorBgContainer,
        width: 2
      }
    } : undefined,
    hovertemplate: '%{text}<br>Value: %{y}<br>N: %{customdata}<extra></extra>',
    customdata: timeSeriesData.dates.map((date: string) => timeSeriesData.values[date].count),
    hoverlabel: {
      bgcolor: token.colorBgElevated,
      bordercolor: token.colorBorder,
      font: { size: 14 }
    },
  }] : [];

  const plotLayout: Partial<Layout> = {
    showlegend: false,
    hovermode: 'x unified',
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
    margin: { l: 60, r: 20, t: 10, b: 60 },
    xaxis: {
      showgrid: false,
      zeroline: false,
      linecolor: token.colorBorder,
      tickfont: { size: 12, color: token.colorTextSecondary },
      title: {
        text: 'Date',
        font: { size: 14, color: token.colorTextSecondary },
        standoff: 30
      },
      tickangle: 0,
      ...(enableZoom && { rangeslider: {} }),
    },
    yaxis: {
      showgrid: true,
      gridcolor: token.colorBorderSecondary,
      gridwidth: 1,
      zeroline: false,
      linecolor: token.colorBorder,
      tickfont: { size: 12, color: token.colorTextSecondary },
      title: {
        text: selectedMetric ? selectedMetric.description.shortLabel : 'Value',
        font: { size: 14, color: token.colorTextSecondary }
      }
    },
    hoverlabel: {
      bgcolor: token.colorBgElevated,
      bordercolor: token.colorBorder,
      font: { size: 14 }
    },
    autosize: true,
    dragmode: enablePanning ? 'pan' : undefined,
  };

  const plotConfig: Partial<Config> = {
    displayModeBar: enableZoom || enablePanning,
    responsive: true,
    modeBarButtonsToRemove: ['select2d', 'lasso2d', 'autoScale2d'] as ModeBarDefaultButtons[],
    toImageButtonOptions: {
      format: 'png' as 'png' | 'svg' | 'jpeg' | 'webp',
      filename: 'metrics_chart',
      height: 500,
      width: 700,
      scale: 2
    },
    displaylogo: false
  };

  return (
    <FlexCard alignStart style={{ width: '100%' }}>
      <style>
        {`
          .js-plotly-plot .plotly .modebar {
            background-color: transparent !important;
            padding: 0px !important;
            margin-right: 10px !important;
            margin-top: 10px !important;
            position: absolute !important;
            top: -20px !important;
            right: 0 !important;
            z-index: 1000 !important;
          }
          .js-plotly-plot .plotly .modebar-group {
            background-color: transparent !important;
            border: none !important;
          }
          .js-plotly-plot .plotly .modebar-btn {
            background-color: transparent !important;
            opacity: 1 !important;
          }
          .js-plotly-plot .plotly .modebar-btn path {
            fill: ${token.colorTextSecondary} !important;
            transition: fill 0.2s;
          }
          .js-plotly-plot .plotly .modebar-btn:hover path,
          .js-plotly-plot .plotly .modebar-btn.active path {
            fill: ${token.colorPrimary} !important;
          }
        `}
      </style>

      <Title 
        level={4} 
        style={{ 
          margin: 0, 
          color: token.colorTextHeading,
          fontSize: 16,
          fontWeight: 600,
        }}
      >
        {selectedMetric?.description.shortLabel} Trend
        {selectedGroupBy && metricGroups && (
          <Text 
            type="secondary" 
            style={{ 
              marginLeft: '5px', 
              fontWeight: 600, 
              fontSize: 16 
            }}
          >
            {screens.sm ? metricGroups.find(g => g.docId === selectedGroupBy)?.description?.shortLabel : ''}
          </Text>
        )}
      </Title>
      
      <FlexBox style={{ width: '100%', height: chartHeight }}>
        {loading ? (
          <FlexBox style={{ 
            height: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center' 
          }}>
            <Empty description="Loading data..." />
          </FlexBox>
        ) : !timeSeriesData ? (
          <FlexBox style={{ 
            height: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center' 
          }}>
            <Empty description="No Data" />
          </FlexBox>
        ) : (
          <Plot
            data={plotData}
            layout={plotLayout}
            config={plotConfig}
            style={{ width: '100%', height: '100%' }}
            onClick={(event) => {
              if (onDataPointClick && event.points?.[0]) {
                const point = event.points[0];
                onDataPointClick({
                  date: point.x as string,
                  value: point.y as number,
                  count: point.customdata as number,
                });
              }
            }}
            onRelayout={(event) => {
              if (onZoomChange && event['xaxis.range']) {
                const start = event['xaxis.range[0]'];
                const end = event['xaxis.range[1]'];
                if (typeof start === 'string' && typeof end === 'string') {
                  onZoomChange({ start, end });
                }
              }
            }}
          />
        )}
      </FlexBox>
    </FlexCard>
  );
};

export default MetricsChart;
