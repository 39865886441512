import { FC, useState } from 'react';
import {
  Button,
  Tooltip,
  Select,
  theme,
  Typography,
  Divider,
  Switch,
  Grid,
} from 'antd';
import {
  EyeInvisibleOutlined,
  DesktopOutlined,
  ClearOutlined,
} from '@ant-design/icons';

import FlexBox from '../../atoms/FlexBox';
import Icon from '../../atoms/Icon';
import { Metric } from '../../../types/System.types';

const { Text } = Typography;
const { useToken } = theme;

/** Type for editor mode selection */
type EditorMode = 'reports' | 'metrics';
type DisplayMode = 'view' | 'edit';

interface AnalyticsEditorToolbarProps {
  mode: EditorMode;
  displayMode: DisplayMode;
  selectedMetric: Metric | null;
  editedMetric: Metric | null;
  hasUnsavedChanges: boolean;
  canUndo: boolean;
  canRedo: boolean;
  isMetricComplete: boolean;
  metricOptions: { label: string; value: string }[];
  onModeChange: (mode: EditorMode) => void;
  onMetricSelect: (metricId: string | null) => void;
  onCreateNewMetric: () => void;
  onSave: () => void;
  onDiscard: () => void;
  onUndo: () => void;
  onRedo: () => void;
  onDisplayModeChange: (checked: boolean) => void;
  onDuplicate: (metricId: string) => void;
}

const { useBreakpoint } = Grid;

const AnalyticsEditorToolbar: FC<AnalyticsEditorToolbarProps> = ({
  mode,
  displayMode,
  selectedMetric,
  editedMetric,
  hasUnsavedChanges,
  canUndo,
  canRedo,
  isMetricComplete,
  metricOptions,
  onModeChange,
  onMetricSelect,
  onCreateNewMetric,
  onSave,
  onDiscard,
  onUndo,
  onRedo,
  onDisplayModeChange,
  onDuplicate,
}) => {
  const { token } = useToken();
  const screens = useBreakpoint();
  const [previewMode, setPreviewMode] = useState<'off' | 'desktop'>('off');

  const renderToolbarControls = () => {
    if (!selectedMetric && !editedMetric && mode === 'metrics') return null;

    if (mode === 'reports') {
      return (
        <FlexBox noGrow>
          <FlexBox noGrow>
            <Text>Edit</Text>
            <Switch disabled />
          </FlexBox>

          <Tooltip title="Layout View">
            <Button
              type="text"
              icon={<EyeInvisibleOutlined />}
              style={{
                backgroundColor:
                  previewMode === 'off'
                    ? token.colorPrimary
                    : token.colorFillTertiary,
                color:
                  previewMode === 'off' ? token.colorWhite : token.colorText,
                width: 32,
                height: 32,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => setPreviewMode('off')}
            />
          </Tooltip>
          <Tooltip title="Desktop View">
            <Button
              type="text"
              icon={<DesktopOutlined />}
              style={{
                backgroundColor:
                  previewMode === 'desktop'
                    ? token.colorPrimary
                    : token.colorFillTertiary,
                color:
                  previewMode === 'desktop'
                    ? token.colorWhite
                    : token.colorText,
                width: 32,
                height: 32,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => setPreviewMode('desktop')}
            />
          </Tooltip>
        </FlexBox>
      );
    }

    if (displayMode === 'view' && selectedMetric?.docId) {
      return (
        <FlexBox alignStart justifyStart>
          <Tooltip title="Create a copy of this metric">
            <Button
              type="link"
              icon={<Icon.Copy />}
              size="small"
              onClick={() => onDuplicate(selectedMetric.docId!)}
            >
              Duplicate
            </Button>
          </Tooltip>
        </FlexBox>
      );
    }

    return (
      <FlexBox alignStart justifyStart>
        <Tooltip title="Save changes">
          <Button
            type="link"
            icon={<Icon.Save />}
            size="small"
            onClick={onSave}
            disabled={
              displayMode !== 'edit' || !isMetricComplete || !hasUnsavedChanges
            }
          >
            Save
          </Button>
        </Tooltip>
        <Tooltip title="Clear all changes made to this metric">
          <Button
            type="link"
            icon={<ClearOutlined />}
            size="small"
            onClick={onDiscard}
            disabled={!hasUnsavedChanges}
          >
            {screens.xs ? 'Clear' : 'Discard All'}
          </Button>
        </Tooltip>
        <Tooltip title="Undo last change">
          <Button
            type="link"
            icon={<Icon.RotateCcw />}
            size="small"
            onClick={onUndo}
            disabled={!canUndo}
          >
            {screens.xs ? '' : 'Undo'}
          </Button>
        </Tooltip>
        <Tooltip title="Redo last change">
          <Button
            type="link"
            icon={<Icon.RotateCw />}
            size="small"
            onClick={onRedo}
            disabled={!canRedo}
          >
            {screens.xs ? '' : 'Redo'}
          </Button>
        </Tooltip>
      </FlexBox>
    );
  };

  return (
    <FlexBox
      id="analytics-editor-toolbar"
      justifyStart
      stretch
      noGrow
      wrap
    >
      <FlexBox noGrow wrap >
        {/* TODO: Add back in when we have a way to switch between reports and metrics */}
        {/* <Segmented
                    style={{ backgroundColor: token.colorFillTertiary }}
                    options={[
                        { label: 'Reports', value: 'reports', disabled: true },
                        { label: 'Metrics', value: 'metrics' }
                    ]}
                    value={mode}
                    onChange={(value) => onModeChange(value as EditorMode)}
                /> */}
        <Select
          variant="filled"
          style={{ width: 355 }}
          placeholder={`Select ${mode === 'reports' ? 'Report' : 'Metric'}`}
          value={mode === 'metrics' ? selectedMetric?.docId || null : null}
          onChange={onMetricSelect}
          options={mode === 'metrics' ? metricOptions : []}
          allowClear
          disabled={mode === 'reports'}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '1opx' }} />
              <Button
                type="text"
                icon={<Icon.Plus />}
                onClick={onCreateNewMetric}
                disabled={mode === 'reports' || displayMode === 'edit'}
              >
                Create New {mode === 'reports' ? 'Report' : 'Metric'}...
              </Button>
            </>
          )}
        />
        {mode === 'metrics' && selectedMetric && (
          <FlexBox alignStart justifyStart >
            <Text> Edit </Text>
            <Switch
              checked={displayMode === 'edit'}
              onChange={onDisplayModeChange}
              style={{alignSelf: 'flex-start'}}
            />
          </FlexBox>
        )}

        {renderToolbarControls()}
      </FlexBox>
    </FlexBox>
  );
};

export default AnalyticsEditorToolbar;
